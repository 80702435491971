import React, { useCallback } from "react"
import styled from "styled-components"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCar,
  faCog,
  faComment,
  faCommentAlt,
  faEnvelope,
  faPhotoVideo,
  faPlusCircle,
  faQuestion,
  faQuestionCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import Button, { baseButtonStyles } from "../components/atoms/Button"
import LOGO from "../assets/images/logo.png"
import { logout } from "../redux/actions"
import { useProfile } from "../hooks/useProfile"
import NotificationsCounter from "../components/atoms/NotificationsCounter"
import { AnalyticsService } from "../services/AnalyticsService"
import { useAuth } from "../hooks/useAuth"
import { Box } from "../components/atoms/_atoms"
import { P } from "components/atoms/P"

const Navigation = () => {
  const dispatch = useDispatch()

  const { user } = useAuth()
  const { type, notifications } = useProfile()
  const { role } = user

  const logoutHandler = useCallback(() => {
    AnalyticsService.track("Log out")
    AnalyticsService.reset()
    dispatch(logout())
  }, [])

  if (role === "ROLE_ADMIN") {
    return (
      <SideBar>
        <LogoWrapper>
          <img src={LOGO} />
        </LogoWrapper>
        <NavigationWrapper>
          <Button to="/panel/leads" type="secondary" justifyContent="flex-start" margin="0 0 5px">
            <FontAwesomeIcon icon={faEnvelope} />
            Leady
          </Button>
        </NavigationWrapper>
        <Button onClick={logoutHandler} type="secondary" margin="0" justifyContent="flex-start">
          <FontAwesomeIcon icon={faSignOutAlt} />
          Wyloguj się
        </Button>
      </SideBar>
    )
  }

  return (
    <SideBar>
      <LogoWrapper>
        <img src={LOGO} />
      </LogoWrapper>
      <NavigationWrapper>
        <Button to="/panel/offers/add">
          <FontAwesomeIcon icon={faPlusCircle} />
          Dodaj ogłoszenie
        </Button>
        <NavigationContent>
          <Button to="/panel/offers" type="secondary" justifyContent="flex-start" margin="0 0 5px">
            <FontAwesomeIcon icon={faCar} />
            Wszystkie ogłoszenia
          </Button>
          {/*<Label margin="20px 0px 10px 15px">Narzędzia</Label>*/}
          {/*<Button to="/panel/drive" type="secondary" justifyContent="flex-start" margin="0 0 5px">*/}
          {/*  <FontAwesomeIcon icon={faPhotoVideo} />*/}
          {/*  Przestrzeń dyskowa*/}
          {/*</Button>*/}
          {type === "owner" && (
            <Button to="/panel/leads" type="secondary" justifyContent="flex-start" margin="0 0 5px">
              <FontAwesomeIcon icon={faEnvelope} />
              Leady
            </Button>
          )}
          <Button
            to="/panel/messages"
            type="secondary"
            justifyContent="flex-start"
            margin="0 0 5px"
          >
            {notifications > 0 && (
              <NotificationsCounter left="8px" top="8px">
                {notifications}
              </NotificationsCounter>
            )}
            <FontAwesomeIcon icon={faComment} />
            Wiadomości
          </Button>
          <Button
            to="/panel/settings/profile"
            isActive={(_, location) => location.pathname.includes("/panel/settings")}
            type="secondary"
            justifyContent="flex-start"
            margin="20px 0 5px"
          >
            <FontAwesomeIcon icon={faCog} />
            Ustawienia
          </Button>
        </NavigationContent>
      </NavigationWrapper>
      <Box>
        <StyledLink
          href="https://pomoc.motorata.pl/partners/"
          target="_blank"
          typeButton="secondary"
          margin="0"
          justifyContent="flex-start"
        >
          <FontAwesomeIcon icon={faQuestion} />
          Centrum pomocy
        </StyledLink>
        <Button onClick={logoutHandler} type="secondary" margin="0" justifyContent="flex-start">
          <FontAwesomeIcon icon={faSignOutAlt} />
          Wyloguj się
        </Button>
        <P margin="10px 0">v1.0.5</P>
      </Box>
    </SideBar>
  )
}

function PanelView(props) {
  const { children } = props

  return (
    <Wrapper>
      <Navigation />
      <Content className="overflow-y">{children}</Content>
    </Wrapper>
  )
}

export default PanelView

const StyledLink = styled.a`
  ${baseButtonStyles}
`

const LogoWrapper = styled.div`
  width: 70%;
  margin: 0 auto;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const Content = styled.div`
  background: ${({ theme }) => theme.color.background};
  min-height: 100%;
  height: 100vh;
  flex: 1 1;
`

const NavigationContent = styled.div`
  margin-top: 40px;
`

const NavigationWrapper = styled.div`
  margin: 50px 0;
  flex: 1 1;
`

const SideBar = styled.div`
  width: 100%;
  max-width: 350px;
  min-width: 250px;
  height: 100%;
  background: white;
  padding: 15px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
`
