import { useSelector } from "react-redux"

export function useProfile() {
  const profile = useSelector(store => store.profile) || {}

  return profile
}

export function useProfileOptions() {
  const options = useSelector(store => store.profile?.options) || {}

  return options
}
