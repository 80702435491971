import React from "react"
import styled from "styled-components"

const Image = ({ src, width = 720, quality = 80, objectFit = "contain" }) => {
  return <StyledImag objectFit={objectFit} src={`${src}?tx=c_scale,w_${width},q_${quality}`} />
}

export default Image

const StyledImag = styled.img`
  object-fit: ${({ objectFit }) => objectFit};
  width: 100%;
  height: 100%;
`
