import React from "react";
import styled from "styled-components";

const Textarea = ({ ...rest }) => {
  return <Wrapper {...rest} />;
};

export default Textarea;

const Wrapper = styled.textarea`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: 0;
  border-radius: 5px;
  background: ${({ theme, background = "background" }) =>
    theme.color[background] ?? background} !important;
  padding: 20px;
  min-height: 300px;
  font-size: ${({ theme }) => theme.font.M};
  font-weight: ${({ theme }) => theme.font.light};
`;
