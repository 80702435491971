export const BRAND_ARRAY = [
  ["Alfa romeo", "alfa-romeo"], //Y
  ["Audi", "audi"], //Y
  ["BMW", "bmw"], //Y
  ["Citroen", "citroen"], //Y
  ["Cupra", "cupra"], //Y
  ["Dacia", "dacia"], //Y
  ["Fiat", "fiat"], //Y
  ["Ford", "ford"], //Y
  ["Hyundai", "hyundai"],
  ["Jaguar", "jaguar"],
  ["Kia", "kia"], //Y
  ["Land Rover", "land-rover"], //Y
  ["Lexus", "lexus"], //Y
  ["Mazda", "mazda"], //Y
  ["Mercedes-benz", "mercedes-benz"],
  ["Honda", "honda"],
  ["Nissan", "nissan"],
  ["Opel", "opel"],
  ["Peugeot", "peugeot"], //Y
  ["Porsche", "porsche"],
  ["Renault", "renault"],
  ["Seat", "seat"],
  ["Skoda", "skoda"],
  ["Toyota", "toyota"],
  ["Volkswagen", "volkswagen"],
  ["Volvo", "volvo"],
]

export const translateBrand = brand => {
  const tmp = BRAND_ARRAY.find(el => el[1] === brand)
  if (tmp) {
    return tmp[0]
  }
  return brand
}

export const MODEL_ARRAY = {
  "alfa-romeo": [
    ["Giulia", "giulia"],
    ["Stelvio", "stelvio"],
  ],
  "audi": [
    ["A1", "a1"],
    ["A2", "a2"],
    ["A3", "a3"],
    ["A4", "a4"],
    ["A4 Allroad", "a4-allroad"],
    ["A5", "a5"],
    ["A6", "a6"],
    ["A6 Allroad", "a6-allroad"],
    ["A7", "a7"],
    ["A8", "a8"],
    ["Q2", "q2"],
    ["Q3", "q3"],
    ["Q4", "q4"],
    ["Q5", "q5"],
    ["Q7", "q7"],
    ["Q8", "q8"],
    ["S1", "s1"],
    ["S2", "s2"],
    ["S3", "s3"],
    ["S4", "s4"],
    ["S5", "s5"],
    ["S6", "s6"],
    ["S7", "s7"],
    ["S8", "s8"],
    ["SQ2", "sq2"],
    ["SQ5", "sq5"],
    ["SQ7", "sq7"],
    ["SQ8", "sq8"],
    ["RS2", "rs2"],
    ["RS3", "rs3"],
    ["RS4", "rs4"],
    ["RS5", "rs5"],
    ["RS6", "rs6"],
    ["RS7", "rs7"],
    ["RS Q3", "rs-q3"],
    ["RS Q8", "rs-q8"],
    ["TT", "tt"],
    ["TT RS", "tt-rs"],
    ["TT S", "tt-s"],
    ["e-tron", "e-tron"],
    ["e-tron GT", "e-tron-gt"],
  ],
  "bmw": [
    ["Seria 1", "seria-1"],
    ["Seria 2", "seria-2"],
    ["Seria 3", "seria-3"],
    ["Seria 4", "seria-4"],
    ["Seria 5", "seria-5"],
    ["Seria 6", "seria-6"],
    ["Seria 7", "seria-7"],
    ["Seria 8", "seria-8"],
    ["X1", "x1"],
    ["X2", "x2"],
    ["X3", "x3"],
    ["X3 M", "x3-m"],
    ["X4", "x4"],
    ["X4 M", "x4-m"],
    ["X5", "x5"],
    ["X5 M", "x5-m"],
    ["X6", "x6"],
    ["X6 M", "x6-m"],
    ["X7", "x7"],
    ["Z4", "z4"],
    ["Z4 M", "z4-m"],
    ["i3", "i3"],
    ["i8", "i8"],
    ["iX3", "ix3"],
    ["iX", "ix"],
    ["i4", "i4"],
    ["M2", "m2"],
    ["M3", "m3"],
    ["M4", "m4"],
    ["M5", "m5"],
    ["M6", "m6"],
    ["M8", "m8"],
  ],
  "citroen": [
    ["C3", "c3"],
    ["C3 Aircross", "c3-aircross"],
    ["C4", "c4"],
    ["C4 Aircross", "c4-aircross"],
    ["C5", "c5"],
    ["C5 Aircross", "c5-aircross"],
    ["SpaceTourer", "spacetourer"],
    ["Berlingo", "berlingo"],
  ],
  "dacia": [
    ["Dokker", "dokker"],
    ["Dokker Van", "dokker-van"],
    ["Duster", "duster"],
    ["Lodgy", "lodgy"],
    ["Logan", "logan"],
    ["Logan Van", "logan-van"],
    ["Sandero", "sandero"],
    ["Sandero Stepway", "sandero-stepway"],
    ["Spring", "spring"],
  ],
  "fiat": [
    ["500", "500"],
    ["500L", "500l"],
    ["500X", "500x"],
    ["Panda", "panda"],
    ["Tipo", "tipo"],
  ],
  "kia": [
    ["Ceed", "ceed"],
    ["Picanto", "picanto"],
    ["Pro ceed", "pro-ceed"],
    ["Retona", "retona"],
    ["Rio", "rio"],
    ["Sorento", "sorento"],
    ["Soul", "soul"],
    ["Sportage", "sportage"],
    ["Niro", "niro"],
    ["Stinger", "stinger"],
    ["Stonic", "stonic"],
    ["XCeed", "xceed"],
    ["EV6", "ev6"],
  ],
  "cupra": [
    ["Ateca", "ateca"],
    ["Leon", "cupra-leon"],
    ["Leon Sportstourer", "cupra-leon-st"],
    ["Formentor", "cupra-formentor"],
  ],
  "porsche": [
    ["356", "356"],
    ["911", "911"],
    ["912", "912"],
    ["914", "914"],
    ["924", "924"],
    ["928", "928"],
    ["944", "944"],
    ["959", "959"],
    ["962", "962"],
    ["968", "968"],
    ["Boxster", "boxster"],
    ["Carrera GT", "carrera-gt"],
    ["Cayenne", "cayenne"],
    ["Cayman", "cayman"],
    ["Macan", "macan"],
    ["Panamera", "panamera"],
    ["Inny", "other"],
    ["718 Boxster", "718-boxster"],
    ["718 Cayman", "718-cayman"],
    ["Taycan", "taycan"],
    ["718 Spyder", "718-spyder"],
  ],
  "hyundai": [
    ["Accent", "accent"],
    ["Atos", "atos"],
    ["Avante", "avante"],
    ["Azera", "azera"],
    ["Coupe", "coupe"],
    ["Elantra", "elantra"],
    ["Equus", "equus"],
    ["Excel", "excel"],
    ["Galloper", "galloper"],
    ["Genesis", "genesis"],
    ["Genesis Coupe", "genesis-coupe"],
    ["Getz", "getz"],
    ["Grand Santa Fe", "grand-santa-fe"],
    ["Grandeur", "grandeur"],
    ["H-1", "h-1"],
    ["H-1 Starex", "h-1-starex"],
    ["H200", "h200"],
    ["Lantra", "lantra"],
    ["Matrix", "matrix"],
    ["Pony", "pony"],
    ["S-Coupe", "s-coupe"],
    ["Santa Fe", "santa-fe"],
    ["Santamo", "santamo"],
    ["Sonata", "sonata"],
    ["Sonica", "sonica"],
    ["Terracan", "terracan"],
    ["Trajet", "trajet"],
    ["Tucson", "tucson"],
    ["Veloster", "veloster"],
    ["Veracruz", "veracruz"],
    ["XG 30", "xg-30"],
    ["XG 350", "xg-350"],
    ["i10", "i10"],
    ["i20", "i20"],
    ["i25", "i25"],
    ["i30", "i30"],
    ["i40", "i40"],
    ["ix20", "ix20"],
    ["ix35", "ix35"],
    ["ix55", "ix55"],
    ["Inny", "other"],
    ["IONIQ", "ioniq"],
    ["H350", "h350"],
    ["Kona", "kona"],
    ["i30 N", "i30-n"],
    ["Bayon", "bayon"],
  ],
  "mercedes-benz": [
    ["W201 (190)", "w201-190"],
    ["280", "280"],
    ["Klasa A", "klasa-a"],
    ["Klasa B", "klasa-b"],
    ["Klasa C", "klasa-c"],
    ["CL", "cl"],
    ["CLC", "clc"],
    ["CLK", "clk"],
    ["CLS", "cls"],
    ["Citan", "citan"],
    ["CLA", "cla"],
    ["Klasa E", "klasa-e"],
    ["Klasa G", "klasa-g"],
    ["GL", "gl"],
    ["GLA", "gla"],
    ["GLK", "glk"],
    ["ML", "ml"],
    ["MB 100", "mb-100"],
    ["Monarch", "monarch"],
    ["Klasa R", "klasa-r"],
    ["Klasa S", "klasa-s"],
    ["SL", "sl"],
    ["SLK", "slk"],
    ["SLR", "slr"],
    ["Sprinter", "sprinter"],
    ["Klasa V", "klasa-v"],
    ["Vaneo", "vaneo"],
    ["Vario", "vario"],
    ["Viano", "viano"],
    ["Vito", "vito"],
    ["W123", "w123"],
    ["W124 (1984-1993)", "w124-1984-1993"],
    ["Inny", "other"],
    ["AMG GT", "amg-gt"],
    ["GLC", "glc"],
    ["GLE", "gle"],
    ["GLS", "gls"],
    ["SLC", "slc"],
    ["Klasa X", "x-klasa"],
    ["SLS", "sls"],
    ["EQC", "eqc"],
    ["GLB", "glb"],
    ["EQV", "eqv"],
    ["EQA", "eqa"],
    ["EQS", "eqs"],
    ["EQB", "eqb"],
    ["EQE", "eqe"],
    ["Klasa T", "t-klasa"],
  ],
  "ford": [
    ["Aerostar", "aerostar"],
    ["Aspire", "aspire"],
    ["B-MAX", "b-max"],
    ["Bronco", "bronco"],
    ["C-MAX", "c-max"],
    ["Capri", "capri"],
    ["Contour", "contour"],
    ["Cougar", "cougar"],
    ["Courier", "courier"],
    ["Crown", "crown"],
    ["EDGE", "edge"],
    ["Econoline", "econoline"],
    ["Econovan", "econovan"],
    ["Escape", "escape"],
    ["Escort", "escort"],
    ["Excursion", "excursion"],
    ["Expedition", "expedition"],
    ["Explorer", "explorer"],
    ["F150", "f150"],
    ["F250", "f250"],
    ["F350", "f350"],
    ["FIVE HUNDRED", "five-hundred"],
    ["Fairlane", "fairlane"],
    ["Falcon", "falcon"],
    ["Festiva", "festiva"],
    ["Fiesta", "fiesta"],
    ["Focus", "focus"],
    ["Focus C-Max", "focus-c-max"],
    ["Freestar", "freestar"],
    ["Freestyle", "freestyle"],
    ["Fusion", "fusion"],
    ["GT", "gt"],
    ["Galaxy", "galaxy"],
    ["Granada", "granada"],
    ["KA", "ka"],
    ["Kuga", "kuga"],
    ["Maverick", "maverick"],
    ["Mercury", "mercury"],
    ["Mondeo", "mondeo"],
    ["Mustang", "mustang"],
    ["Orion", "orion"],
    ["Probe", "probe"],
    ["Puma", "puma"],
    ["Ranchero", "ranchero"],
    ["Ranger", "ranger"],
    ["S-Max", "s-max"],
    ["Scorpio", "scorpio"],
    ["Sierra", "sierra"],
    ["Streetka", "streetka"],
    ["Taunus", "taunus"],
    ["Taurus", "taurus"],
    ["Tempo", "tempo"],
    ["Thunderbird", "thunderbird"],
    ["Tourneo Connect", "tourneo-connect"],
    ["Tourneo Courier", "tourneo-courier"],
    ["Tourneo Custom", "tourneo-custom"],
    ["Transit", "transit"],
    ["Transit Connect", "transit-connect"],
    ["Transit Courier", "transit-courier"],
    ["Transit Custom", "transit-custom"],
    ["Windstar", "windstar"],
    ["Inny", "other"],
    ["EcoSport", "ecosport"],
    ["Grand C-MAX", "grand-c-max"],
    ["Ka+", "ka_plus"],
    ["Ranger Raptor", "ranger-raptor"],
    ["Mustang Mach-E", "mustang-mach-e"],
    ["Flex", "flex"],
  ],
  "mazda": [
    ["121", "121"],
    ["2", "2"],
    ["3", "3"],
    ["323", "323"],
    ["323F", "323f"],
    ["5", "5"],
    ["6", "6"],
    ["626", "626"],
    ["929", "929"],
    ["BT-50", "bt-50"],
    ["Bongo", "bongo"],
    ["CX-5", "cx-5"],
    ["CX-7", "cx-7"],
    ["CX-9", "cx-9"],
    ["Demio", "demio"],
    ["MPV", "mpv"],
    ["MX-3", "mx-3"],
    ["MX-5", "mx-5"],
    ["MX-6", "mx-6"],
    ["Millenia", "millenia"],
    ["Premacy", "premacy"],
    ["Protege", "protege"],
    ["RX-6", "rx-6"],
    ["RX-7", "rx-7"],
    ["RX-8", "rx-8"],
    ["Seria B", "seria-b"],
    ["Seria E", "seria-e"],
    ["Tribute", "tribute"],
    ["Xedos", "xedos"],
    ["Inny", "other"],
    ["CX-3", "cx-3"],
    ["CX-30", "cx-30"],
    ["MX-30", "mx-30"],
    ["CX-60", "cx-60"],
  ],
  "seat": [
    ["Alhambra", "alhambra"],
    ["Altea", "altea"],
    ["Altea XL", "altea-xl"],
    ["Arosa", "arosa"],
    ["Cordoba", "cordoba"],
    ["Exeo", "exeo"],
    ["Ibiza", "ibiza"],
    ["Inca", "inca"],
    ["Leon", "leon"],
    ["Malaga", "malaga"],
    ["Marbella", "marbella"],
    ["Mii", "mii"],
    ["Ronda", "ronda"],
    ["Terra", "terra"],
    ["Toledo", "toledo"],
    ["Inny", "other"],
    ["Ateca", "ateca"],
    ["Arona", "arona"],
    ["Tarraco", "tarraco"],
  ],
  "skoda": [
    ["100", "100"],
    ["105", "105"],
    ["120", "120"],
    ["130", "130"],
    ["135", "135"],
    ["Citigo", "citigo"],
    ["Fabia", "fabia"],
    ["Favorit", "favorit"],
    ["Felicia", "felicia"],
    ["Forman", "forman"],
    ["Octavia", "octavia"],
    ["Praktik", "praktik"],
    ["RAPID", "rapid"],
    ["Roomster", "roomster"],
    ["Superb", "superb"],
    ["Yeti", "yeti"],
    ["Inny", "other"],
    ["Kodiaq", "kodiaq"],
    ["Karoq", "karoq"],
    ["Scala", "scala"],
    ["Kamiq", "kamiq"],
    ["Enyaq", "enyaq"],
  ],
  "peugeot": [
    ["1007", "1007"],
    ["104", "104"],
    ["106", "106"],
    ["107", "107"],
    ["108", "108"],
    ["2008", "2008"],
    ["204", "204"],
    ["205", "205"],
    ["206", "206"],
    ["206 CC", "206-cc"],
    ["206 plus", "206-plus"],
    ["207", "207"],
    ["207 CC", "207-cc"],
    ["208", "208"],
    ["3008", "3008"],
    ["301", "301"],
    ["304", "304"],
    ["305", "305"],
    ["306", "306"],
    ["307", "307"],
    ["307 CC", "307-cc"],
    ["308", "308"],
    ["308 CC", "308-cc"],
    ["309", "309"],
    ["395", "395"],
    ["4007", "4007"],
    ["4008", "4008"],
    ["404", "404"],
    ["405", "405"],
    ["406", "406"],
    ["407", "407"],
    ["5008", "5008"],
    ["504", "504"],
    ["505", "505"],
    ["508", "508"],
    ["604", "604"],
    ["605", "605"],
    ["607", "607"],
    ["806", "806"],
    ["807", "807"],
    ["Bipper", "bipper"],
    ["Expert", "expert"],
    ["Partner", "partner"],
    ["RCZ", "rcz"],
    ["Inny", "other"],
    ["Boxer", "boxer"],
    ["Traveller", "traveller"],
    ["Rifter", "rifter"],
    ["iOn", "ion"],
  ],
  "toyota": [
    ["4-Runner", "4-runner"],
    ["Auris", "auris"],
    ["Avalon", "avalon"],
    ["Avensis", "avensis"],
    ["Avensis Verso", "avensis-verso"],
    ["Aygo", "aygo"],
    ["Camry", "camry"],
    ["Camry Solara", "camry-solara"],
    ["Carina", "carina"],
    ["Celica", "celica"],
    ["Corolla", "corolla"],
    ["Corolla Verso", "corolla-verso"],
    ["Cressida", "cressida"],
    ["Crown", "crown"],
    ["Dyna", "dyna"],
    ["FJ", "fj"],
    ["GT86", "gt86"],
    ["Hiace", "hiace"],
    ["Highlander", "highlander"],
    ["Hilux", "hilux"],
    ["Land Cruiser", "land-cruiser"],
    ["Lite-Ace", "lite-ace"],
    ["MR2", "mr2"],
    ["Matrix", "matrix"],
    ["Paseo", "paseo"],
    ["Picnic", "picnic"],
    ["Previa", "previa"],
    ["Prius", "prius"],
    ["RAV4", "rav4"],
    ["Sequoia", "sequoia"],
    ["Sienna", "sienna"],
    ["Starlet", "starlet"],
    ["Supra", "supra"],
    ["Tacoma", "tacoma"],
    ["Tercel", "tercel"],
    ["Tundra", "tundra"],
    ["Urban Cruiser", "urban-cruiser"],
    ["Venza", "venza"],
    ["Verso", "verso"],
    ["Verso S", "verso-s"],
    ["Yaris", "yaris"],
    ["Yaris Verso", "yaris-verso"],
    ["iQ", "iq"],
    ["Inny", "other"],
    ["C-HR", "c-hr"],
    ["ProAce", "proace"],
    ["Mirai", "mirai"],
    ["Proace Verso", "proace-verso"],
    ["Proace City Verso", "proace-city-verso"],
    ["Prius+", "prius_plus"],
    ["Proace City", "proace-city"],
    ["Yaris Cross", "yaris-cross"],
    ["bZ4X", "bz4x"],
  ],
  "land-rover": [
    ["Defender", "defender"],
    ["Discovery", "discovery"],
    ["Discovery Sport", "discovery-sport"],
    ["Freelander", "freelander"],
    ["Range Rover", "range-rover"],
    ["Range Rover Evoque", "range-rover-evoque"],
    ["Range Rover Sport", "range-rover-sport"],
    ["Inny", "other"],
    ["Range Rover Velar", "range-rover-velar"],
  ],
  "nissan": [
    ["100 NX", "100-nx"],
    ["200 SX", "200-sx"],
    ["240 SX", "240-sx"],
    ["280 ZX", "280-zx"],
    ["300 ZX", "300-zx"],
    ["350 Z", "350-z"],
    ["370 Z", "370-z"],
    ["Almera", "almera"],
    ["Almera Tino", "almera-tino"],
    ["Altima", "altima"],
    ["Armada", "armada"],
    ["Bluebird", "bluebird"],
    ["Cherry", "cherry"],
    ["Cube", "cube"],
    ["Frontier", "frontier"],
    ["GT-R", "gt-r"],
    ["Interstar", "interstar"],
    ["Juke", "juke"],
    ["King Cab", "king-cab"],
    ["Kubistar", "kubistar"],
    ["Laurel", "laurel"],
    ["Maxima", "maxima"],
    ["Micra", "micra"],
    ["Murano", "murano"],
    ["NP300 Pickup", "np300-pickup"],
    ["NV200", "nv200"],
    ["Navara", "navara"],
    ["Note", "note"],
    ["Pathfinder", "pathfinder"],
    ["Patrol", "patrol"],
    ["Pickup", "pickup"],
    ["Pixo", "pixo"],
    ["Prairie", "prairie"],
    ["Primastar", "primastar"],
    ["Primera", "primera"],
    ["Pulsar", "pulsar"],
    ["Qashqai", "qashqai"],
    ["Qashqai+2", "qashqai-2"],
    ["Quest", "quest"],
    ["Rogue", "rogue"],
    ["Sentra", "sentra"],
    ["Serena", "serena"],
    ["Silvia", "silvia"],
    ["Skyline", "skyline"],
    ["Stanza", "stanza"],
    ["Sunny", "sunny"],
    ["Terrano", "terrano"],
    ["Tiida", "tiida"],
    ["Titan", "titan"],
    ["Trade", "trade"],
    ["Urvan", "urvan"],
    ["Vanette", "vanette"],
    ["X-Trail", "x-trail"],
    ["Xterra", "xterra"],
    ["Inny", "other"],
    ["Leaf", "leaf"],
    ["NV300", "nv300"],
    ["NV400", "nv400"],
    ["Townstar", "townstar"],
    ["Ariya", "ariya"],
  ],
  "lexus": [
    ["CT", "ct"],
    ["ES", "es"],
    ["GS", "gs"],
    ["GX", "gx"],
    ["HS 250h", "hs-250h"],
    ["IS", "is"],
    ["LFA", "lfa"],
    ["LS", "ls"],
    ["LX", "lx"],
    ["NX", "nx"],
    ["RX", "rx"],
    ["SC", "sc"],
    ["Inny", "other"],
    ["RC", "rc"],
    ["LC", "lc"],
    ["UX", "ux"],
  ],
  "volkswagen": [
    ["181", "181"],
    ["Amarok", "amarok"],
    ["Beetle", "beetle"],
    ["Bora", "bora"],
    ["Buggy", "buggy"],
    ["CC", "cc"],
    ["Caddy", "caddy"],
    ["California", "california"],
    ["Caravelle", "caravelle"],
    ["Corrado", "corrado"],
    ["Crafter", "crafter"],
    ["Eos", "eos"],
    ["Fox", "fox"],
    ["Garbus", "garbus"],
    ["Golf", "golf"],
    ["Golf Plus", "golf-plus"],
    ["Golf Sportsvan", "golf-sportsvan"],
    ["Iltis", "iltis"],
    ["Jetta", "jetta"],
    ["Kafer", "kafer"],
    ["Karmann Ghia", "karmann-ghia"],
    ["Lupo", "lupo"],
    ["Multivan", "multivan"],
    ["New Beetle", "new-beetle"],
    ["Passat", "passat"],
    ["Passat CC", "passat-cc"],
    ["Phaeton", "phaeton"],
    ["Polo", "polo"],
    ["Santana", "santana"],
    ["Scirocco", "scirocco"],
    ["Sharan", "sharan"],
    ["Tiguan", "tiguan"],
    ["Touareg", "touareg"],
    ["Touran", "touran"],
    ["Transporter", "transporter"],
    ["Vento", "vento"],
    ["Inny", "other"],
    ["up!", "up"],
    ["Arteon", "arteon"],
    ["T-Roc", "t-roc"],
    ["Tiguan Allspace", "tiguan-allspace"],
    ["Routan", "routan"],
    ["T-Cross", "t-cross"],
    ["Teramont", "teramont"],
    ["ID.3", "id3"],
    ["Atlas", "atlas"],
    ["LT", "lt"],
    ["ID.4", "id4"],
    ["Taigo", "taigo"],
    ["ID.5", "id5"],
    ["ID.Buzz", "id-buzz"],
  ],
  "opel": [
    ["Adam", "adam"],
    ["Agila", "agila"],
    ["Ampera", "ampera"],
    ["Antara", "antara"],
    ["Arena", "arena"],
    ["Ascona", "ascona"],
    ["Astra", "astra"],
    ["Calibra", "calibra"],
    ["Campo", "campo"],
    ["Cascada", "cascada"],
    ["Combo", "combo"],
    ["Commodore", "commodore"],
    ["Corsa", "corsa"],
    ["Diplomat", "diplomat"],
    ["Frontera", "frontera"],
    ["GT", "gt"],
    ["Insignia", "insignia"],
    ["Kadett", "kadett"],
    ["Manta", "manta"],
    ["Meriva", "meriva"],
    ["Mokka", "mokka"],
    ["Monterey", "monterey"],
    ["Monza", "monza"],
    ["Movano", "movano"],
    ["Nova", "nova"],
    ["Omega", "omega"],
    ["Pick up Sportcap", "pick-up-sportcap"],
    ["Rekord", "rekord"],
    ["Senator", "senator"],
    ["Signum", "signum"],
    ["Sintra", "sintra"],
    ["Speedster", "speedster"],
    ["Tigra", "tigra"],
    ["Vectra", "vectra"],
    ["Vivaro", "vivaro"],
    ["Zafira", "zafira"],
    ["Inny", "other"],
    ["Karl", "karl"],
    ["Crossland X", "crossland-x"],
    ["Grandland X", "grandland-x"],
    ["Grandland", "grandland"],
    ["Crossland", "crossland"],
  ],
  "volvo": [
    ["Seria 200", "seria-200"],
    ["245", "245"],
    ["262", "262"],
    ["340", "340"],
    ["Seria 300", "seria-300"],
    ["Seria 400", "seria-400"],
    ["Seria 700", "seria-700"],
    ["744", "744"],
    ["745", "745"],
    ["780", "780"],
    ["850", "850"],
    ["855", "855"],
    ["Seria 900", "seria-900"],
    ["945", "945"],
    ["965", "965"],
    ["C30", "c30"],
    ["C70", "c70"],
    ["Polar", "polar"],
    ["S40", "s40"],
    ["S60", "s60"],
    ["S70", "s70"],
    ["S80", "s80"],
    ["S90", "s90"],
    ["V40", "v40"],
    ["V50", "v50"],
    ["V60", "v60"],
    ["V70", "v70"],
    ["V90", "v90"],
    ["XC 60", "xc-60"],
    ["XC 70", "xc-70"],
    ["XC 90", "xc-90"],
    ["Inny", "other"],
    ["XC 40", "xc-40"],
    ["C40", "c-40"],
    ["MY23", "my23"],
    ["V90 Cross Country", "v90-cross-country"],
  ],
  "jaguar": [
    ["Daimler", "daimler"],
    ["E-Type", "e-type"],
    ["F-Type", "f-type"],
    ["MK II", "mk-ii"],
    ["S-Type", "s-type"],
    ["X-Type", "x-type"],
    ["XF", "xf"],
    ["XJ", "xj"],
    ["XK", "xk"],
    ["XK8", "xk8"],
    ["Inny", "other"],
    ["XE", "xe"],
    ["F-Pace", "f-pace"],
    ["E-Pace", "e-pace"],
    ["I-Pace", "i-pace"],
    ["XJS", "xjs"],
    ["XJR", "xjr"],
  ],
}

export const translateModel = model => {
  let finalModel

  for (const [key, value] of Object.entries(MODEL_ARRAY)) {
    const tmp = value.find(el => el[1] === model)
    if (tmp) {
      finalModel = tmp
    }
  }

  if (finalModel) {
    return finalModel[0]
  }
  return model
}

export const CAR_BODY_TYPES_ARRAY = [
  ["Auto małe", "mini"],
  ["Auto miejskie", "cityCar"],
  ["Coupe", "coupe"],
  ["Kompakt", "compact"],
  ["Kombi", "combi"],
  ["Kabriolet", "cabrio"],
  ["Sedan", "sedan"],
  ["SUV", "suv"],
  ["Minivan", "minivan"],
  ["Hatchback", "hatchback"],
]
export const TRUCK_BODY_TYPES_ARRAY = [
  ["Autolaweta", "carCarrier"],
  ["Do zabudowy", "builtIn"],
  ["Doka", "doubleCab"],
  ["Kontener", "container"],
  ["Plandeka", "softShell"],
  ["Skrzynia", "dropside"],
  ["Wywrotka", "dumper"],
  ["Chłodnia", "temperatureControlled"],
  ["Furgon", "panelVan"],
  ["Kamper", "rvs"],
  ["Mikrobus", "microbuses"],
  ["Pick-up", "pickUp"],
  ["Platformy", "platforms"],
  ["Specjalny", "special"],
  ["Van", "vans"],
  ["Inne", "others"],
]

export const translateBodyType = bodyType => {
  const tmp = [...CAR_BODY_TYPES_ARRAY, ...TRUCK_BODY_TYPES_ARRAY].find(el => el[1] === bodyType)
  if (tmp) {
    return tmp[0]
  }
  return bodyType
}

export const FUEL_TYPES_ARRAY = [
  ["Benzyna", "petrol"],
  ["Diesel", "diesel"],
  ["Hybryda", "hybrid"],
  ["Elektryk", "electric"],
]

export const GEARBOX_TYPES_ARRAY = [
  ["Manualna", "manual"],
  ["Automatyczna", "automatic"],
]
