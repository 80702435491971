import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';

const Avatar = ({ icon = faBuilding, background, margin }) => {

  return (
    <Wrapper background={background} margin={margin}>
      <FontAwesomeIcon icon={icon} />
    </Wrapper>
  );
};

export default Avatar;

const Wrapper = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme, background }) => theme.color[background]};
  border-radius: 50%;
  margin: ${({ margin }) => margin}
  `;