import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  UPDATE_USER,
  VERIFY_ERROR,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from "../actions/"
import { AnalyticsService } from "../../services/AnalyticsService"

export default (state = {}, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
        loginError: false,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.payload,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true,
      }
    case REGISTER_REQUEST:
      return {
        ...state,
        isRegistering: true,
        registerError: false,
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        isRegistering: false,
        registerError: true,
        isAuthenticated: true,
        user: action.payload,
      }
    case REGISTER_FAILURE:
      return {
        ...state,
        isRegistering: false,
        registerError: true,
      }
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        logoutError: false,
      }
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isVerifying: false,
        isLoggingOut: false,
        isAuthenticated: false,
        user: {},
      }
    case LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        logoutError: true,
      }
    case VERIFY_REQUEST:
      return {
        ...state,
        isVerifying: true,
        verifyingError: false,
      }
    case VERIFY_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isVerifying: false,
        user: action.payload,
      }
    case VERIFY_ERROR:
      return {
        ...state,
        isVerifying: false,
        isAuthenticated: false,
      }
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    default:
      return state
  }
}
