import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { useParams } from "react-router"
import { ChatService } from "../../../../services/ChatService"
import SpinnerBlock from "../../../../components/blocks/SpinnerBlock"
import Comment from "../../../../components/atoms/Comment"
import Input from "../../../../components/atoms/Input"
import { faLocationArrow, faPaperPlane, faPhone } from "@fortawesome/free-solid-svg-icons"
import { Box, Row } from "../../../../components/atoms/_atoms"
import Button from "../../../../components/atoms/Button"
import { useForm } from "react-hook-form"
import { Title } from "../../../../components/atoms/Title"
import { Label } from "../../../../components/atoms/Label"
import Icon from "../../../../components/atoms/Icon"
import { initSocket, useSocket } from "../../../../hooks/useSocket"
import { AnalyticsService } from "../../../../services/AnalyticsService"

const Message = () => {
  const { messageId } = useParams()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const message = watch("message")

  const [loading, setLoading] = useState(true)

  const [chat, setChat] = useState(null)
  const [messages, setMessages] = useState([])

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const chat = await ChatService.getChat(messageId)
      setChat(chat)
      setMessages(chat.messages.reverse())
    } catch (e) {
      AnalyticsService.error("[ERROR getChat API]:", e)
    } finally {
      setLoading(false)
    }
  }, [])

  const onSubmit = useCallback(
    el => {
      try {
        const body = {
          ...el,
          dtCreated: new Date(),
          profile: true,
        }

        ChatService.addMessage(messageId, body)
        setValue("message", "")
      } catch (e) {
        AnalyticsService.error("[ERROR addMessage API]:", e)
      }
    },
    [messages],
  )

  useEffect(() => {
    fetchData()

    const socket = initSocket([
      {
        url: `/chat/${messageId}/message`,
        onResponse: res => {
          setMessages(prevState => [JSON.parse(res.body), ...prevState])
        },
      },
    ])

    return () => {
      socket.disconnect()
    }
  }, [messageId])

  const messageIsValid = message && message !== ""

  return (
    <Wrapper>
      <Content>
        {loading && <SpinnerBlock width="100%" height="100%" />}
        {!loading && chat && (
          <>
            <HeaderWrapper>
              <Row alignItems="center">
                {/*<HeaderImage>{chat.name.substring(0, 2).toUpperCase()}</HeaderImage>*/}
                <Box>
                  <Row marginBottom="2px" alignItems="center">
                    <Title margin="0" type="secondary">
                      {chat.name}
                    </Title>
                  </Row>
                  <Label margin="0" type="third">
                    {chat.offer}
                  </Label>
                </Box>
              </Row>
              <Row>
                {chat.phone && (
                  <Row marginRight="20px" alignItems="center">
                    <Icon icon={faPhone} size="10px" margin="0 10px 0 0" />
                    <Label color="secondary">{chat.phone}</Label>
                  </Row>
                )}
                {chat.location?.city && (
                  <Row alignItems="center">
                    <Icon icon={faLocationArrow} size="10px" margin="0 10px 0 0" />
                    {/*<Row flexDirection="column" alignItems="flex-end">*/}
                    {/*  <Label small color="secondary">*/}
                    {/*    {chat.location.countryCode}*/}
                    {/*  </Label>*/}
                    {/*  <Label color="secondary">{chat.location.city}</Label>*/}
                    {/*</Row>*/}
                    <Label color="secondary">{chat.location.city}</Label>
                  </Row>
                )}
              </Row>
            </HeaderWrapper>
            <CommentWrapper className="hide_scroll">
              {messages.map(el => (
                <Comment
                  key={el.id}
                  background={el.profile ? "primary" : "white"}
                  color={el.profile ? "white" : "secondary"}
                  align={el.profile ? "right" : "left"}
                  maxWidth="90%"
                  data={el}
                />
              ))}
            </CommentWrapper>
          </>
        )}
      </Content>
      {!loading && (
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <Row alignItems="center">
            <Input background="white" label="Wiadomość" rhf={register("message")} />
            <StyledButton disabled={!messageIsValid} submit icon={faPaperPlane} />
          </Row>
        </StyledForm>
      )}
    </Wrapper>
  )
}

export default Message

const StyledForm = styled.form`
  padding: 15px;
`

const StyledButton = styled(Button)`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: 10px;

  svg {
    margin-right: 0px;
  }
`

const HeaderImage = styled.div`
  width: 45px;
  height: 45px;
  background: ${({ theme }) => theme.color.background};
  font-size: ${({ theme }) => theme.font.M};
  color: ${({ theme }) => theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.normal};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
`

const HeaderWrapper = styled.div`
  position: absolute;
  top: 15px;
  width: calc(100% - 30px);
  left: 15px;
  padding: 10px;
  padding-right: 20px;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
`

const CommentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  overflow: scroll;
  padding-bottom: 150px;
  transform: scaleY(-1);

  > div {
    transform: scaleY(-1);
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1;
  position: relative;
  height: calc(100% - 85px);
  padding: 15px;
  padding-bottom: 0;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`
