import { APIService } from "./APIService";

const login = user => APIService.post(`/login`, user)

const verify = () => APIService.post(`/verify`)

export const AuthService = {
  login,
  verify,
}
