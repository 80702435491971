import React from 'react';
import styled from 'styled-components';
import EMPTY_BOX_ICON from '../../assets/images/empty-box.svg';
import { P } from '../atoms/P';

const EmptyBlock = ({ margin, text }) => {

  return (
    <Wrapper margin={margin}>
      <img src={EMPTY_BOX_ICON} />
      {text && <P ta='center'>{text}</P>}
    </Wrapper>
  );
};

export default EmptyBlock;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${({ margin }) => margin};

  img {
    width: 40px;
    margin-bottom: 10px;
  }
`;
