import { APIService } from "./APIService"

const isSlugAvailable = slug => APIService.get(`/profiles/is-available?slug=${slug}`)

const acceptLegal = () => APIService.post(`/profiles/:profileId/accept-legal`)
const submitIntegrations = () => APIService.post(`/profiles/:profileId/submit-integrations`)

const create = profile => APIService.post(`/profiles`, profile)

const update = profile => APIService.patch(`/profiles/:profileId`, profile)

const updateOptions = options => APIService.patch(`/profiles/:profileId/options`, options)

const addIntegration = (slug, body) =>
  APIService.post(`/profiles/:profileId/integrations`, body, {
    params: {
      slug,
    },
  })

const updateIntegration = (slug, body) =>
  APIService.patch(`/profiles/:profileId/integrations`, body, {
    params: {
      slug,
    },
  })

const deleteIntegration = slug =>
  APIService.delete(`/profiles/:profileId/integrations`, {
    params: {
      slug,
    },
  })

export const ProfileService = {
  isSlugAvailable,
  acceptLegal,
  submitIntegrations,
  create,
  update,
  updateOptions,
  addIntegration,
  updateIntegration,
  deleteIntegration,
}
