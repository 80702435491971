import { Column } from "../../../../../../theme/styledComponents"
import { P } from "../../../../../../components/atoms/P"
import React, { useRef, useState } from "react"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import Icon from "../../../../../../components/atoms/Icon"
import Button from "../../../../../../components/atoms/Button"
import { useDispatch } from "react-redux"
import { useProfile } from "../../../../../../hooks/useProfile"

const OtomotoSuccessModal = ({ show, setShow }) => {
  const dispatch = useDispatch()
  const { id } = useProfile()

  const countRef = useRef()
  const [count, setCount] = useState()

  return (
    <Column ai="center" jc="center">
      <Icon size="50px" margin="10px auto 25px" color="green" icon={faCheckCircle}/>
      <P margin="0 0 20px" ta="center">
        Twoja integracja przebiegła pomyślnie - w ciągu <span>24h</span> pobierzemy Twoje ogłoszenia!
      </P>
      <Button onClick={() => setShow("")}>Zakończ</Button>
    </Column>
  )
}

export default OtomotoSuccessModal
