import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { OtomotoBlock, OtomotoModals } from "./Otomoto/Otomoto"
import { Title } from "components/atoms/Title"
import { P } from "components/atoms/P"
import Switch from "components/atoms/Switch"
import { deleteIntegration } from "redux/reducers/profile"
import { ProfileService } from "services"
import { Row } from "theme/styledComponents"
import { CustomCMSModals } from "./CustomCMS/CustomCMS"
import { Box } from "../../../../../components/atoms/_atoms"
import { GratkaBlock } from "./Gratka/Gratka"
import Tag from "../../../../../components/atoms/Tag"
import { AllegroBlock } from "./Allegro/Allegro"
import Alert from "../../../../../components/atoms/Alert"

export const IntegrationBlock = ({
  logo,
  title,
  text,
  active,
  onSwitch,
  children,
  disabled,
  isWarning,
  ...rest
}) => {
  return (
    <Block {...rest}>
      <Row jc="space-between">
        <Box>{logo && <Image src={logo} />}</Box>
        <Row>
          {disabled ? (
            <Tag>Wkrótce</Tag>
          ) : (
            <Switch disabled={disabled} checked={!isWarning && active} onChange={onSwitch} />
          )}
        </Row>
      </Row>
      {isWarning && (
        <Alert type="warning">
          Integracja wymaga ponownej aktywacji, kliknij{" "}
          <b
            style={{ cursor: "pointer" }}
            onClick={() =>
              onSwitch({
                target: {
                  checked: true,
                },
              })
            }
          >
            tutaj
          </b>
          !
        </Alert>
      )}
      <Title type="third" margin="15px 0 10px" fontWeight="bold">
        {title}
      </Title>
      <P margin="0px" small>
        {text}
      </P>
      {children}
    </Block>
  )
}

const Integrations = () => {
  const dispatch = useDispatch()
  const [current, setCurrent] = useState()

  const handleSwitch = useCallback(async (e, name) => {
    if (e.target.checked) {
      setCurrent(name)
    } else {
      const res = await ProfileService.deleteIntegration(name)
      dispatch(deleteIntegration(name))
    }
  }, [])

  const handleOtomotoSwitch = useCallback(async (e, name) => {
    if (e.target.checked) {
      setCurrent(name)
    } else {
      setCurrent("otomoto-delete-offers")
    }
  }, [])

  return (
    <>
      <Wrapper>
        <Title>Dodatkowe integracje</Title>
        <P>Uruchom dodatkowe aplikacje oraz skonfiguruj zewętrzne usługi</P>
        <Content>
          <OtomotoBlock setCurrent={setCurrent} onChange={handleOtomotoSwitch} />
          {/*<AllegroBlock />*/}
          {/*<GratkaBlock />*/}
          {/*<CustomCMSBlock setCurrent={setCurrent} onChange={handleSwitch} />*/}
        </Content>
      </Wrapper>
      <OtomotoModals state={current} setState={setCurrent} />
      <CustomCMSModals state={current} setState={setCurrent} />
    </>
  )
}

export default Integrations

const Image = styled.img`
  max-height: 40px;
  height: 40px;
  max-width: 150px;
  display: block;
  margin-left: 0px;
`

const Block = styled.div`
  background: ${({ theme, background = "white" }) => theme.color[background]};
  padding: 25px;
  border-radius: 5px;
  margin-bottom: 1rem;
`

const Content = styled.div`
  margin-top: 30px;
`

const Wrapper = styled.div``
