import React, { useCallback, useRef, useState } from "react"
import styled from "styled-components"
import { Label } from "../../../../../../components/atoms/Label"
import { Row } from "../../../../../../components/atoms"
import { faBars, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons"
import Icon from "../../../../../../components/atoms/Icon"
import AddStatusModal from "./AddStatusModal"
import Button from "../../../../../../components/atoms/Button"
import EditStatusModal from "./EditStatusModal"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import update from "immutability-helper"
import { AnalyticsService } from "../../../../../../services/AnalyticsService"

const ListItem = ({ index, item, onEdit, onDelete, onMove }) => {
  const { id } = item
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()

      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      onMove(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <ListItemWrapper ref={ref} opacity={opacity} data-handler-id={handlerId}>
      <Row alignItems="center">
        <Row marginRight="10px">
          <Icon size="10px" cursor="pointer" icon={faBars} />
        </Row>
        {/*<ColorCircle></ColorCircle>*/}
        <Label color="secondary">{item.name}</Label>
      </Row>
      <Row>
        <Row marginRight="10px">
          <Icon size="10px" cursor="pointer" icon={faPen} onClick={() => onEdit(item)} />
        </Row>
        <Row>
          <Icon size="10px" cursor="pointer" icon={faTrash} onClick={() => onDelete(item)} />
        </Row>
      </Row>
    </ListItemWrapper>
  )
}

const StatusesList = ({ data, setData }) => {
  const [modal, setModal] = useState()
  const [selected, setSelected] = useState()

  const handleOnEdit = useCallback(item => {
    setModal("edit")
    setSelected(item)
  }, [])

  const handleSubmitEdit = useCallback(
    item => {
      const arr = [...data]
      const index = arr.findIndex(el => el.id === item.id)
      arr[index] = {
        ...arr[index],
        ...item,
      }

      setData(arr)
    },
    [data],
  )

  const handleSubmitAdd = useCallback(
    item => {
      setData([...data, item])
    },
    [data],
  )

  const handleSubmitDelete = useCallback(
    item => {
      setData(data.filter(el => el.id !== item.id))
      AnalyticsService.track("Profile Lead Status Delete")
    },
    [data],
  )

  const onMove = useCallback((dragIndex, hoverIndex) => {
    setData(prevCards =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    )
  }, [])

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Wrapper>
          {data.map((item, index) => (
            <ListItem
              key={item.id}
              index={index}
              item={item}
              onMove={onMove}
              onEdit={handleOnEdit}
              onDelete={handleSubmitDelete}
            />
          ))}
        </Wrapper>
      </DndProvider>
      <Row>
        <Button background="white" icon={faPlus} type="secondary" onClick={() => setModal("add")}>
          Dodaj
        </Button>
      </Row>
      {modal === "add" && (
        <AddStatusModal show={modal === "add"} setShow={setModal} onAdd={handleSubmitAdd} />
      )}
      {modal === "edit" && (
        <EditStatusModal
          item={selected}
          show={modal === "edit"}
          setShow={setModal}
          onEdit={handleSubmitEdit}
        />
      )}
    </>
  )
}

export default StatusesList

const ColorCircle = styled.div`
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
  margin-right: 10px;
`

const ListItemWrapper = styled.div`
  background: white;
  padding: 10px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${({ opacity }) => opacity};
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`
