import { APIService } from "../APIService"

const getOffers = () => APIService.get(`/profiles/:profileId/integrations/otomoto/offers`)

const deleteOffers = () => APIService.delete(`/profiles/:profileId/integrations/otomoto/offers`)

export const OtomotoService = {
  getOffers,
  deleteOffers,
}
