import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Title } from "../../../../components/atoms/Title"
import { LeadService } from "../../../../services/LeadService"
import Table, { Row } from "../../../../components/Table"
import { Column } from "../../../../components/atoms"
import { Row as FlexRow } from "../../../../theme/styledComponents"
import Icon from "../../../../components/atoms/Icon"
import { faBuilding, faExclamationTriangle, faEye, faUser } from "@fortawesome/free-solid-svg-icons"
import EmptyBlock from "../../../../components/blocks/EmptyBlock"
import { format } from "date-fns"
import { NavLink, Route } from "react-router-dom"
import Lead from "./Lead"
import ToolTip from "../../../../components/atoms/Tooltip"
import { AdminService } from "../../../../services/AdminService"

const LeadRow = ({ item }) => {
  return (
    <Row
      key={item.id}
      data={[
        <FlexRow jc="flex-start" className="bold" flexWrap="no-wrap" key={`name-${item.id}`}>
          <Icon
            size="12px"
            margin="0 10px 0 0"
            icon={item.type === "customer" ? faUser : faBuilding}
          />
          {item.name}
        </FlexRow>,
        <a key={`email-${item.id}`} href={`mailto:${item.email}`}>
          {item.email}
        </a>,
        <>
          {!item.phoneVerified ? (
            <ToolTip key={`phone-${item.id}`} text="Niezweryfikowany numer telefonu">
              <FlexRow jc="flex-start" className="bold" flexWrap="no-wrap">
                <Icon
                  size="12px"
                  color="warning"
                  margin="0 10px 0 0"
                  icon={faExclamationTriangle}
                />
                {item.phone}
              </FlexRow>
            </ToolTip>
          ) : (
            <>{item.phone}</>
          )}
        </>,
        item.number ?? "-",
        format(new Date(item.dtCreated), "dd/MM/yyyy HH:mm"),
        <NavLink key={`link-${item.id}`} to={`/panel/leads/${item.id}`}>
          <Icon cursor="pointer" margin="0 10px" icon={faEye} />
        </NavLink>,
      ]}
    />
  )
}

const Leads = () => {
  const [refresh, setRefresh] = useState(false)

  const fetchData = useCallback(page => {
    return AdminService.getLeads(page)
  }, [])

  const renderEmptyComponent = useCallback(() => {
    return (
      <Column padding="60px 30px" jc="center" ai="center">
        <EmptyBlock text="Brak zapytań w bazie danych" />
      </Column>
    )
  }, [])

  const renderItem = useCallback((lead, data, setData) => {
    return <LeadRow key={`${lead.id}-${lead.status}`} item={lead} data={data} setData={setData} />
  }, [])

  return (
    <>
      <Wrapper>
        <Title margin="0 0 25px">Wszystkie leady</Title>
        <TableWrapper>
          <Table
            getData={fetchData}
            columns={["Status", "Nazwa", "Email", "Telefon", "PSESL/NIP", "Data", ""]}
            refresh={refresh}
            rows={renderItem}
            emptyComponent={renderEmptyComponent}
          />
        </TableWrapper>
      </Wrapper>
      <Route path="/panel/leads/:id">
        <Lead />
      </Route>
    </>
  )
}

export default Leads

const TableWrapper = styled.div`
  background: white;
  border-radius: 5px;
  overflow: hidden;
`

const Wrapper = styled.div`
  padding: 40px;
`
