import React from "react"
import { ThemeProvider } from "styled-components"
import "./App.css"
import { Provider } from "react-redux"
import { theme } from "./theme/theme"
import { BrowserRouter as Router } from "react-router-dom"
import { Wrapper } from "@googlemaps/react-wrapper"
import * as Sentry from "@sentry/react"

//REDUX
import configureStore from "./configureStore"
import Home from "./Home"

export const store = configureStore()

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
})

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <Router>
            <Home />
          </Router>
        </Wrapper>
      </ThemeProvider>
    </Provider>
  )
}

export default App
