import React, { useEffect } from "react"
import { Route } from "react-router-dom"
import PanelView from "../../../views/Panel"
import FileProvider from "../../../providers/FileProvider"
import Leads from "./Leads/Leads"

const AdminPanel = () => {
  return (
    <FileProvider>
      <PanelView>
        <Route path="/panel/leads">
          <Leads />
        </Route>
      </PanelView>
    </FileProvider>
  )
}

export default AdminPanel
