import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { P } from "../../../../../components/atoms/P"
import { format } from "date-fns"
import Button from "../../../../../components/atoms/Button"
import { faPaperPlane, faPlus } from "@fortawesome/free-solid-svg-icons"
import Input from "../../../../../components/atoms/Input"
import { Row } from "../../../../../components/atoms/_atoms"
import { useForm } from "react-hook-form"
import { makeid } from "../../../../../utils/stringUtils"
import { LeadService } from "../../../../../services/LeadService"
import Comment from "../../../../../components/atoms/Comment"
import { AnalyticsService } from "../../../../../services/AnalyticsService"

const LeadComment = ({ data }) => {
  return <Comment data={data} />
}

const LeadComments = ({ data, comments, setComments }) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const message = watch("message")

  const onSubmit = useCallback(
    el => {
      try {
        const body = {
          ...el,
          dtCreated: new Date(),
        }

        LeadService.addComment(data.id, body)
        AnalyticsService.track("Lead Add Comment", {
          Length: el.message.length,
        })
        setComments([body, ...comments])
        setValue("message", "")
      } catch (e) {
        AnalyticsService.error("[ERROR addComment API]:", e)
      }
    },
    [comments],
  )

  const messageIsValid = message && message !== ""
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Input label="Wiadomość" rhf={register("message")} />
          <StyledButton disabled={!messageIsValid} submit icon={faPaperPlane} />
        </Row>
      </form>
      {comments.length > 0 && (
        <Wrapper>
          {comments.map((comment, index) => (
            <LeadComment key={index} data={comment} />
          ))}
        </Wrapper>
      )}
    </>
  )
}

export default LeadComments

const StyledButton = styled(Button)`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-left: 10px;
  margin-bottom: 10px;

  svg {
    margin-right: 0px;
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-top: 10px;
`
