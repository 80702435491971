import React, { useCallback, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { useHistory } from "react-router"
import FinanceInformations from "./Blocks/FinanceInformations"
import AdditionalInformations from "./Blocks/AdditionalInformations"
import Gallery from "./Blocks/Gallery"
import BasicInformations from "./Blocks/BasicInformations"
import Description from "./Blocks/Description"
import { MOTORATA_URL } from "theme/constans"
import ActionModal from "components/ActionModal"
import Modal from "components/atoms/Modal"
import { theme } from "theme/theme"
import { Title } from "components/atoms/Title"
import { P } from "components/atoms/P"
import { Box, Column, Row } from "theme/styledComponents"
import { OffersService } from "services"
import Button from "components/atoms/Button"
import Icon from "components/atoms/Icon"
import LoadingBlock from "components/blocks/LoadingBlock"
import { translateBrand, translateModel } from "../../../../../theme/data"
import { AnalyticsService } from "../../../../../services/AnalyticsService"
import Equipment from "./Blocks/Equipment"

const initState = {
  1: null,
  2: null,
  3: null,
  4: [],
  5: [],
  6: null,
}

const AddOfferModal = ({ refresh }) => {
  const history = useHistory()

  const [showGoBackModal, setShowGoBackModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [offer, setOffer] = useState(null)
  const [step, setStep] = useState(1)
  const [state, setState] = useState(initState)

  const handleSubmitStep = useCallback(
    (number, item) => {
      setState({
        ...state,
        [number]: item,
      })
      setStep(step + 1)
    },
    [step, state],
  )

  const handleBackStep = useCallback(() => {
    const newStep = step - 1
    setStep(newStep)
  }, [setStep, step])

  const goBack = useCallback(() => {
    history.push("/panel/offers")
  }, [])

  const handleShowGoBackModal = useCallback(() => {
    setShowGoBackModal(true)
  }, [])

  const handleCloseGoBackModal = useCallback(() => {
    setShowGoBackModal(false)
  }, [])

  const handleGoBack = useCallback(() => {
    if (step > 1 && !offer) {
      handleShowGoBackModal()
    } else {
      goBack()
    }
  }, [step, offer])

  const handleSubmitOffer = useCallback(async () => {
    try {
      setLoading(true)
      const body = {
        state: state["2"]?.state,
        type: state["1"]?.type,
        year: state["1"]?.year,
        brand: state["1"]?.brand,
        model: state["1"]?.model,
        bodyType: state["1"]?.bodyType,
        fuelType: state["1"]?.fuelType,
        gearBox: state["1"]?.gearBox,
        enginePower: state["1"]?.enginePower,
        engineCapacity: state["1"]?.engineCapacity,
        course: state["2"]?.course && state["2"]?.course !== "" ? state["2"]?.course : "0",
        version: state["1"]?.version,
        isAvailable: state["2"]?.isAvailable,
        isDamaged: state["2"]?.isDamaged,
        params: {
          color: state["2"]?.color,
          colorType: state["2"]?.colorType,
          vin: state["2"]?.vin,
          pickupDate: state["2"]?.pickupDate,
          address: state["2"]?.address,
          country: state["2"]?.country,
          versionName: state["5"]?.name && state["5"]?.name !== "" ? state["5"]?.name : null,
        },
        finance: {
          price: state["3"]?.price,
          showPrice: state["3"]?.showPrice,
          type: state["3"]?.priceType,
          invoice: state["3"]?.invoice,
        },
        images: [...state["4"]?.map(el => el.id)],
        equipment: state["5"]?.equipment,
        description: state["6"]?.description,
        coordinator: state["6"]?.coordinator,
      }
      console.log(JSON.stringify(body))
      const res = await OffersService.addOffer(body)
      AnalyticsService.track("Offer Add")
      setOffer(res)
      refresh()
    } catch (e) {
      AnalyticsService.error("[ERROR addOffer API]:", e.toString())
    } finally {
      setLoading(false)
    }
  }, [state])

  const handleAddNextOffer = useCallback(() => {
    setState(initState)
    setStep(1)
  }, [])

  return (
    <>
      <Modal
        title="Dodaj ogłoszenie"
        show={true}
        setShow={handleGoBack}
        type="side"
        maxWidth="900px"
        background={theme.color.background}
      >
        <>
          {!offer ? (
            <>
              <BasicInformations
                number={1}
                step={step}
                setStep={setStep}
                onSubmit={handleSubmitStep}
                onBack={handleBackStep}
              />
              <AdditionalInformations
                number={2}
                disabled={!(step < 2 || !state[1]?.version)}
                step={step}
                setStep={setStep}
                onSubmit={handleSubmitStep}
                onBack={handleBackStep}
              />
              <FinanceInformations
                number={3}
                disabled={step < 3}
                step={step}
                setStep={setStep}
                onSubmit={handleSubmitStep}
                onBack={handleBackStep}
                productType={state["1"]?.type}
                year={state["1"]?.year}
              />
              <Gallery
                number={4}
                disabled={step < 4}
                step={step}
                setStep={setStep}
                onSubmit={handleSubmitStep}
                onBack={handleBackStep}
              />
              {state[1]?.type === "motorbikes" ? (
                <>
                  <Description
                    number={5}
                    disabled={step < 5}
                    step={step}
                    setStep={setStep}
                    state={state}
                    onSubmit={handleSubmitStep}
                    onBack={handleBackStep}
                  />
                  <Button disabled={step !== 6} onClick={handleSubmitOffer}>
                    Dodaj ogłoszenie
                  </Button>
                </>
              ) : (
                <>
                  <Equipment
                    number={5}
                    disabled={step < 5}
                    step={step}
                    setStep={setStep}
                    onSubmit={handleSubmitStep}
                    onBack={handleBackStep}
                  />
                  <Description
                    number={6}
                    disabled={step < 6}
                    step={step}
                    setStep={setStep}
                    state={state}
                    onSubmit={handleSubmitStep}
                    onBack={handleBackStep}
                  />
                  <Button disabled={step !== 7} onClick={handleSubmitOffer}>
                    Dodaj ogłoszenie
                  </Button>
                </>
              )}
              {step === 6 && (
                <Button type="third" margin="10px auto" onClick={() => setStep(5)}>
                  Wróc
                </Button>
              )}
            </>
          ) : (
            <>
              <Box margin="100px auto 0">
                <Column ai="center" jc="center" margin="0 auto 60px" maxWidth="500px">
                  <Icon size="70px" margin="0 0 20px" color="green" icon={faCheckCircle} />
                  <Title tx="center">Ogłoszenie zostało pomyślnie dodane</Title>
                  <P ta="center">
                    Twoje głoszenie {translateBrand(offer.brand)} {translateModel(offer.model)}{" "}
                    {offer.year} zostało pomyślnie dodane i jest już dostępne dla użytkowników.
                  </P>
                  <Row margin="30px 0 0">
                    <Button
                      type="secondary"
                      margin="0 20px 0 0"
                      width="max-content"
                      onClick={handleGoBack}
                    >
                      Wróć do listy ogłoszeń
                    </Button>
                    <a href={`${MOTORATA_URL}/oferta/${offer.id}`} target="_blank" rel="noreferrer">
                      <Button width="max-content">Zobacz ogłoszenie</Button>
                    </a>
                  </Row>
                </Column>
              </Box>
            </>
          )}
          {loading && (
            <LoadingBlock white background="rgba(106, 103, 212, 0.9)" margin="0 0 30px">
              <Title margin="20px 0 0" color="white">
                Trwa dodawanie ogłoszenia
              </Title>
            </LoadingBlock>
          )}
        </>
      </Modal>
      <ActionModal
        show={showGoBackModal}
        setShow={setShowGoBackModal}
        title="Czy na pewno chcesz wróćić?"
        text="Twoje informacje oraz zmiany zostą utracone."
        onAccept={goBack}
      />
    </>
  )
}

export default AddOfferModal

export const Content = styled.form`
  margin-top: 20px;
  display: none;

  p {
    margin: 0px;
  }

  ${({ active }) =>
    active &&
    css`
      display: block;
    `}
`

export const Wrapper = styled.div`
  background: white;
  padding: 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: 20px;
`
