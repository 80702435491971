import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { P } from "components/atoms/P"
import { Title } from "components/atoms/Title"

const RowTitleBlock = ({ icon, title, text, disabled, onClick }) => {
  return (
    <RowTitleWrapper disabled={disabled} onClick={onClick}>
      <div>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div>
        <Title type="secondary" margin="0 0 3px">
          {title}
        </Title>
        <P margin="0">{text}</P>
      </div>
    </RowTitleWrapper>
  )
}

export default RowTitleBlock

const RowTitleWrapper = styled.div`
  display: flex;
  cursor: pointer;
  cursor: ${({ disabled }) => disabled && "not-allowed"};
  > div:first-of-type {
    margin-top: 2px;
    margin-right: 15px;

    svg {
      font-size: 18px;
      color: ${({ theme }) => theme.color.primary};
    }
  }
`
