import React, { useEffect } from "react"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import PanelView from "../../../views/Panel"
import FileProvider from "../../../providers/FileProvider"
import Offers from "./Offers/Offers"
import Settings from "./Settings/Settings"
import Drive from "./Drive/Drive"
import Leads from "./Leads/Leads"
import Messages from "./Messages/Messages"
import { initSocket } from "../../../hooks/useSocket"
import { useDispatch } from "react-redux"
import { setNotifications } from "redux/reducers/profile"
import { useProfile } from "hooks/useProfile"
import { AnalyticsService } from "../../../services/AnalyticsService"

const Panel = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { id, type } = useProfile()

  useEffect(() => {
    const socket = initSocket([
      {
        url: `/profile/${id}/notifications`,
        onResponse: res => {
          dispatch(setNotifications(JSON.parse(res.body)))
        },
      },
    ])

    return () => {
      socket.disconnect()
    }
  })

  useEffect(() => {
    AnalyticsService.page("Page Visit")
  }, [location])

  return (
    <FileProvider>
      <PanelView>
        <Route path="/panel/offers">
          <Offers />
        </Route>
        <Route path="/panel/settings">
          <Settings />
        </Route>
        {type === "owner" && (
          <Route path="/panel/leads">
            <Leads />
          </Route>
        )}
        <Route path="/panel/messages">
          <Messages />
        </Route>
        {/*<Route path="/panel/drive">*/}
        {/*  <Drive />*/}
        {/*</Route>*/}
      </PanelView>
    </FileProvider>
  )
}

export default Panel
