import React from "react"
import styled from "styled-components"
import CheckBox2 from "./CheckBox2"
import { common } from "./_atoms"

const CheckboxInput = ({ label, checked, onChange, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <CheckBox2 checked={checked} label={label} labelWeight="light" onChange={onChange} />
    </Wrapper>
  )
}

export default CheckboxInput

const Wrapper = styled.div`
  ${common};
  min-height: 50px;
  background: ${({ theme }) => theme.color.background};
  border-radius: 3px;
  padding: 6px 20px;
  display: flex;
  align-items: center;
`
