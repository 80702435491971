import { APIService } from "./APIService"

const getChat = (id = 0) => APIService.get(`/profiles/:profileId/chats/${id}`)
const getChats = (page = 0) => APIService.get(`/profiles/:profileId/chats?page=${page}&size=99`)

const getMessages = chatId => APIService.get(`/profiles/:profileId/chats/${chatId}/messages`)

const addMessage = (chatId, body) =>
  APIService.post(`/profiles/:profileId/chats/${chatId}/messages`, body)

export const ChatService = {
  getChat,
  getChats,
  getMessages,
  addMessage,
}
