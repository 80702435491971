import styled, { css } from "styled-components"

export const P = styled.p`
  font-size: ${({ theme, fontSize = "M" }) => theme.font[fontSize]};
  color: ${({ theme, color = "third" }) => theme.color[color]};
  font-weight: ${({ theme, fontWeight = "normal" }) => theme.font[fontWeight]};
  margin: ${({ margin }) => margin || "0px 0px 10px"};
  text-align: ${({ ta }) => ta || "left"};
  width: 100%;

  a,
  span {
    color: ${({ theme }) => theme.color.primary};
  }

  button {
    background: none;
    border: none;
    padding: 0px;
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.normal};
    cursor: pointer;
  }

  ${({ small }) =>
    small &&
    css`
      font-size: ${({ theme }) => theme.font.S};
      font-weight: ${({ theme }) => theme.font.light};
    `}
`
