import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Redirect, Route, Switch, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import Login from "./pages/auth/Login/Login"
import Register from "./pages/auth/Register/Register"
import Panel from "./pages/auth/Panel/Panel"
import Spinner from "./components/atoms/Spinner"
import RegisterSteps from "pages/auth/Register/RegisterSteps"
import RegisterConfirmEmail from "pages/auth/Register/RegisterConfirmEmail"
import { useQuery } from "./hooks/useQuery"
import AdminPanel from "./pages/auth/AdminPanel/AdminPanel"
import RegisterPendingAccount from "./pages/auth/Register/RegisterPendingAccount"
import { setToken } from "./services"
import { verifyRequest, verifyUser } from "./redux/actions"
import RegisterAcceptLegal from "./pages/auth/Register/RegisterAcceptLegal"
import RegisterIntegrations from "./pages/auth/Register/RegisterIntegrations"
import ExtendOffer from "pages/auth/Panel/@action/ExtendOffer/ExtendOffer"

function Home() {
  const { pathname } = useLocation()
  const query = useQuery()
  const dispatch = useDispatch()

  const { isAuthenticated, isVerifying, user = {} } = useSelector(store => store.auth)
  const { role, status } = user

  if (isVerifying) {
    return (
      <VerifyScreen>
        <Spinner />
      </VerifyScreen>
    )
  }

  if (isAuthenticated && role === "ROLE_ADMIN") {
    return (
      <Switch>
        <Route path="/panel">
          <AdminPanel />
        </Route>
        <Route>
          <Redirect to="/panel/leads" />
        </Route>
      </Switch>
    )
  }

  return (
    <>
      {!isAuthenticated && (
        <Switch>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route>
            <Redirect to={`/login?url=${pathname}`} />
          </Route>
        </Switch>
      )}
      {isAuthenticated && status === "confirm_email" && (
        <Switch>
          <Route path="/register/confirm-email">
            <RegisterConfirmEmail />
          </Route>
          <Route>
            <Redirect to="/register/confirm-email" />
          </Route>
        </Switch>
      )}
      {isAuthenticated && status === "add_information" && (
        <Switch>
          <Route path="/register/add-information">
            <RegisterSteps />
          </Route>
          <Route>
            <Redirect to="/register/add-information" />
          </Route>
        </Switch>
      )}
      {isAuthenticated && status === "accept_legal" && (
        <Switch>
          <Route path="/register/accept_legal">
            <RegisterAcceptLegal />
          </Route>
          <Route>
            <Redirect to="/register/accept_legal" />
          </Route>
        </Switch>
      )}
      {isAuthenticated && status === "integrations" && (
        <Switch>
          <Route path="/register/integrations">
            <RegisterIntegrations />
          </Route>
          <Route>
            <Redirect to="/register/integrations" />
          </Route>
        </Switch>
      )}
      {isAuthenticated && status === "pending" && (
        <Switch>
          <Route path="/register/pending">
            <RegisterPendingAccount />
          </Route>
          <Route>
            <Redirect to="/register/pending" />
          </Route>
        </Switch>
      )}
      {isAuthenticated && status === "active" && (
        <Switch>
          <Route path="/panel">
            <Panel />
          </Route>
          <Route path="/extend-offer">
            <ExtendOffer />
          </Route>
          <Route>
            <Redirect to={query.get("url") ?? "/panel/offers"} />
          </Route>
        </Switch>
      )}
    </>
  )
}

export default Home

const VerifyScreen = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
