import styled from 'styled-components'

export const Grid = styled.a`
  display: grid;
  grid-template-columns: ${({ gtc }) => gtc};
  grid-template-rows: ${({ gtr }) => gtr};
  grid-gap: ${({ gg }) => gg || '10px'};
  margin:${({ margin }) => margin || '0 0 10px'};
  
  @media (max-width: 900px){
    grid-template-columns: 1fr;
  }
`;