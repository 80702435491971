import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import { faCar } from "@fortawesome/free-solid-svg-icons"
import { Content, Wrapper } from "../AddOfferModal"
import RowTitleBlock from "components/blocks/RowTitleBlock"
import { ModelService } from "services"
import { Grid } from "theme/styledComponents"
import Select2 from "components/atoms/Select2"
import Button from "components/atoms/Button"
import Input from "components/atoms/Input"
import Input2 from "../../../../../../components/atoms/Input2"
import {
  BRANDS_MOTO_ARRAY,
  BODY_TYPE_CAR_ARRAY,
  BODY_TYPE_MOTO_ARRAY,
  BODY_TYPE_TRUCK_ARRAY,
  BRANDS_ARRAY,
  FUEL_ARRAY,
  FUEL_MOTO_ARRAY,
  MODELS_ARRAY,
  GEAR_ARRAY,
  GEAR_MOTO_ARRAY,
  YEAR_ARRAY,
} from "@motorata/base-ui.data"

const MotoInformations = ({ watch, setValue, errors, register }) => {
  const [brands, setBrands] = useState([])
  const [bodyTypes, setBodyTypes] = useState([])
  const [fuelTypes, setFuelTypes] = useState([])
  const [gearBoxes, setGearBoxes] = useState([])

  const type = watch("type")
  const year = watch("year")
  const brand = watch("brand")
  const model = watch("model")
  const bodyType = watch("bodyType")
  const fuelType = watch("fuelType")
  const enginePower = watch("enginePower")
  const engineCapacity = watch("engineCapacity")
  const gearBox = watch("gearBox")

  useEffect(async () => {
    setBrands([])
    setValue("brand", "")
    if (year) {
      setBrands(BRANDS_MOTO_ARRAY)
    }
  }, [year])

  useEffect(async () => {
    setBodyTypes([])
    setValue("bodyType", "")
    if (model) {
      setBodyTypes(BODY_TYPE_MOTO_ARRAY)
    }
  }, [model])

  useEffect(async () => {
    setFuelTypes([])
    setValue("fuelType", "")
    if (bodyType) {
      setFuelTypes(FUEL_MOTO_ARRAY)
    }
  }, [bodyType])

  useEffect(async () => {
    setGearBoxes([])
    setValue("gearBox", "")
    if (fuelType) {
      setGearBoxes(GEAR_MOTO_ARRAY)
    }
  }, [fuelType])

  return (
    <>
      <Select2
        disabled={!type}
        label="Rok modelowy"
        options={YEAR_ARRAY}
        value={year}
        margin="0"
        error={errors.year}
        rhf={register("year", { required: true })}
      />
      <Select2
        disabled={!year}
        label="Marka"
        options={brands}
        value={brand}
        margin="0"
        error={errors.brand}
        rhf={register("brand", { required: true })}
      />
      <Input2
        disabled={!brand}
        label="Model"
        value={model}
        error={errors.model}
        rhf={register("model", { required: false })}
      />
      <Select2
        disabled={!model}
        label="Nadwozie"
        options={bodyTypes}
        value={bodyType}
        margin="0"
        error={errors.bodyType}
        rhf={register("bodyType", { required: true })}
      />
      <Select2
        disabled={!bodyType}
        label="Rodzaj paliwa"
        options={fuelTypes}
        value={fuelType}
        margin="0"
        error={errors.fuelType}
        rhf={register("fuelType", { required: true })}
      />
      <Select2
        disabled={!fuelType}
        label="Skrzynia biegów"
        options={gearBoxes}
        value={gearBox}
        margin="0"
        error={errors.gearBox}
        rhf={register("gearBox", { required: true })}
      />
      <Input2
        disabled={!gearBox}
        type="int"
        label="Moc"
        endAdornment="KM"
        value={enginePower}
        error={errors.enginePower}
        rhf={register("enginePower", { required: false })}
      />
      <Input2
        disabled={!enginePower}
        type="int"
        label="Pojemność"
        endAdornment="cm3"
        value={engineCapacity}
        error={errors.engineCapacity}
        rhf={register("engineCapacity", { required: false })}
      />
    </>
  )
}

const TruckInformations = ({ watch, setValue, errors, register }) => {
  const [brands, setBrands] = useState([])
  const [bodyTypes, setBodyTypes] = useState([])
  const [fuelTypes, setFuelTypes] = useState([])
  const [gearBoxes, setGearBoxes] = useState([])

  const type = watch("type")
  const year = watch("year")
  const brand = watch("brand")
  const model = watch("model")
  const bodyType = watch("bodyType")
  const fuelType = watch("fuelType")
  const enginePower = watch("enginePower")
  const engineCapacity = watch("engineCapacity")
  const gearBox = watch("gearBox")

  useEffect(async () => {
    setBrands([])
    setValue("brand", "")
    if (year) {
      setBrands(BRANDS_ARRAY)
    }
  }, [year])

  useEffect(async () => {
    setBodyTypes([])
    setValue("bodyType", "")
    if (model) {
      setBodyTypes(BODY_TYPE_TRUCK_ARRAY)
    }
  }, [model])

  useEffect(async () => {
    setFuelTypes([])
    setValue("fuelType", "")
    if (bodyType) {
      setFuelTypes(FUEL_ARRAY)
    }
  }, [bodyType])

  useEffect(async () => {
    setGearBoxes([])
    setValue("gearBox", "")
    if (fuelType) {
      setGearBoxes(GEAR_ARRAY)
    }
  }, [fuelType])

  return (
    <>
      <Select2
        disabled={!type}
        label="Rok modelowy"
        options={YEAR_ARRAY}
        value={year}
        margin="0"
        error={errors.year}
        rhf={register("year", { required: true })}
      />
      <Select2
        disabled={!year}
        label="Marka"
        options={brands}
        value={brand}
        margin="0"
        error={errors.brand}
        rhf={register("brand", { required: true })}
      />
      <Input2
        disabled={!brand}
        label="Model"
        value={model}
        error={errors.model}
        rhf={register("model", { required: false })}
      />
      <Select2
        disabled={!model}
        label="Nadwozie"
        options={bodyTypes}
        value={bodyType}
        margin="0"
        error={errors.bodyType}
        rhf={register("bodyType", { required: true })}
      />
      <Select2
        disabled={!bodyType}
        label="Rodzaj paliwa"
        options={fuelTypes}
        value={fuelType}
        margin="0"
        error={errors.fuelType}
        rhf={register("fuelType", { required: true })}
      />
      <Select2
        disabled={!fuelType}
        label="Skrzynia biegów"
        options={gearBoxes}
        value={gearBox}
        margin="0"
        error={errors.gearBox}
        rhf={register("gearBox", { required: true })}
      />
      <Input2
        disabled={!gearBox}
        type="int"
        label="Moc"
        endAdornment="KM"
        value={enginePower}
        error={errors.enginePower}
        rhf={register("enginePower", { required: false })}
      />
      <Input2
        disabled={!enginePower}
        type="int"
        label="Pojemność"
        endAdornment="cm3"
        value={engineCapacity}
        error={errors.engineCapacity}
        rhf={register("engineCapacity", { required: false })}
      />
    </>
  )
}

const CarInformations = ({ watch, setValue, errors, register }) => {
  const [loading, setLoading] = useState()

  const [brands, setBrands] = useState([])
  const [models, setModels] = useState([])
  const [bodyTypes, setBodyTypes] = useState([])
  const [fuelTypes, setFuelTypes] = useState([])
  const [gearBoxes, setGearBoxes] = useState([])
  const [enginePowers, setEnginePowers] = useState(null)
  const [engineCapacities, setEngineCapacities] = useState(null)
  const [versions, setVersions] = useState([])

  const type = watch("type")
  const year = watch("year")
  const brand = watch("brand")
  const model = watch("model")
  const bodyType = watch("bodyType")
  const fuelType = watch("fuelType")
  const enginePower = watch("enginePower")
  const engineCapacity = watch("engineCapacity")
  const gearBox = watch("gearBox")
  const version = watch("version")

  useEffect(async () => {
    setBrands([])
    setValue("brand", "")
    if (year) {
      setBrands(BRANDS_ARRAY)
    }
  }, [year])

  useEffect(async () => {
    setModels([])
    setValue("model", "")
    if (brand) {
      setModels(MODELS_ARRAY[brand])
    }
  }, [brand])

  useEffect(async () => {
    setBodyTypes([])
    setValue("bodyType", "")
    if (model) {
      setBodyTypes(BODY_TYPE_CAR_ARRAY)
    }
  }, [model])

  useEffect(async () => {
    setFuelTypes([])
    setValue("fuelType", "")
    if (bodyType) {
      setFuelTypes(FUEL_ARRAY)
    }
  }, [bodyType])

  useEffect(async () => {
    setGearBoxes([])
    setValue("gearBox", "")
    if (fuelType) {
      setGearBoxes(GEAR_ARRAY)
    }
  }, [fuelType])

  useEffect(async () => {
    setEnginePowers(null)
    setValue("enginePower", "")
    if (year && fuelType && gearBox) {
      setLoading("enginePower")
      const res = await ModelService.getEnginePowers(year, brand, model, fuelType)
      setEnginePowers(res)
      setLoading(false)
    }
  }, [gearBox])

  useEffect(async () => {
    setEngineCapacities(null)
    setValue("engineCapacity", "")
    if (enginePower) {
      setLoading("engineCapacity")
      const res = await ModelService.getEngineCapacities(year, brand, model, fuelType, enginePower)
      setEngineCapacities(res)
      setLoading(false)
    }
  }, [enginePower])

  useEffect(async () => {
    setVersions(null)
    setValue("version", "")
    if (engineCapacity) {
      setLoading("version")
      const res = await ModelService.getVersions(
        year,
        brand,
        model,
        fuelType,
        gearBox,
        enginePower,
        engineCapacity,
      )
      setVersions(res)
      setLoading(false)
    }
  }, [engineCapacity])

  return (
    <>
      <Select2
        disabled={!type}
        label="Rok modelowy"
        options={YEAR_ARRAY}
        value={year}
        margin="0"
        error={errors.year}
        rhf={register("year", { required: true })}
      />
      <Select2
        disabled={!year}
        label="Marka"
        options={brands}
        value={brand}
        margin="0"
        error={errors.brand}
        rhf={register("brand", { required: true })}
      />
      <Select2
        disabled={!brand}
        label="Model"
        options={models}
        value={model}
        margin="0"
        error={errors.model}
        rhf={register("model", { required: true })}
      />
      <Select2
        disabled={!model}
        label="Nadwozie"
        options={bodyTypes}
        value={bodyType}
        margin="0"
        error={errors.bodyType}
        rhf={register("bodyType", { required: true })}
      />
      <Select2
        disabled={!bodyType}
        label="Rodzaj paliwa"
        options={fuelTypes}
        value={fuelType}
        margin="0"
        error={errors.fuelType}
        rhf={register("fuelType", { required: true })}
      />
      <Select2
        disabled={!fuelType}
        label="Skrzynia biegów"
        options={gearBoxes}
        value={gearBox}
        margin="0"
        error={errors.gearBox}
        rhf={register("gearBox", { required: true })}
      />
      {!enginePowers || enginePowers?.length > 0 ? (
        <Select2
          disabled={!gearBox || enginePowers?.length === 0}
          loading={loading === "enginePower"}
          label="Moc"
          options={enginePowers?.map(el => [`${el} KM`, el])}
          margin="0"
          error={errors.enginePower}
          rhf={register("enginePower", { required: true })}
        />
      ) : (
        <Input2
          type="int"
          label="Moc"
          endAdornment="KM"
          value={enginePower}
          error={errors.enginePower}
          rhf={register("enginePower", { required: false })}
        />
      )}
      {!engineCapacities || engineCapacities?.length > 0 ? (
        <Select2
          disabled={!enginePower || engineCapacities?.length === 0}
          loading={loading === "engineCapacity"}
          label="Pojemność"
          options={engineCapacities?.map(el => [`${el} cm3`, el])}
          margin="0"
          error={errors.engineCapacity}
          rhf={register("engineCapacity", { required: true })}
        />
      ) : (
        <Input2
          type="int"
          label="Pojemność"
          endAdornment="cm3"
          value={engineCapacity}
          error={errors.engineCapacity}
          rhf={register("engineCapacity", { required: false })}
        />
      )}
      <VersionWrapper>
        {!versions || versions?.length > 0 ? (
          <Select2
            disabled={!engineCapacity || versions?.length === 0}
            loading={loading === "version"}
            label="Wersja"
            options={versions?.map(el => [`${el}`, el])}
            margin="0"
            error={errors.version}
            rhf={register("version", { required: true })}
          />
        ) : (
          <Input2
            label="Wersja"
            value={version}
            error={errors.version}
            rhf={register("version", { required: false })}
          />
        )}
      </VersionWrapper>
    </>
  )
}

const BasicInformations = ({ number, step, setStep, onSubmit, disabled }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      type: "car",
      year: "",
      brand: "",
      model: "",
      bodyType: "",
      fuelType: "",
      enginePower: "",
      engineCapacity: "",
      gearBox: "",
      version: "",
    },
  })

  const type = watch("type")

  const handleOnSubmit = useCallback(async e => {
    onSubmit(number, e)
  }, [])

  return (
    <Wrapper>
      <RowTitleBlock
        disabled={disabled}
        onClick={() => setStep(number)}
        title="Podstawowe informacje"
        icon={faCar}
        text="Podaj podstawowe informacje o pojeździe"
      />
      <Content active={step === number} onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid gtc="1fr 1fr 1fr 1fr" gg="10px">
          <Select2
            label="Rodzaj"
            options={[
              ["Osobowe", "car"],
              ["Dostawcze", "truck"],
              ["Motocykle", "motorbikes"],
            ]}
            value={type}
            margin="0"
            error={errors.type}
            rhf={register("type", { required: true })}
          />
          {type === "car" && (
            <CarInformations
              watch={watch}
              setValue={setValue}
              errors={errors}
              register={register}
            />
          )}
          {type === "truck" && (
            <TruckInformations
              watch={watch}
              setValue={setValue}
              errors={errors}
              register={register}
            />
          )}
          {type === "motorbikes" && (
            <MotoInformations
              watch={watch}
              setValue={setValue}
              errors={errors}
              register={register}
            />
          )}
        </Grid>
        <Button margin="10px 0 0 auto" width="max-content" submit>
          Dalej
        </Button>
      </Content>
    </Wrapper>
  )
}

export default BasicInformations

const VersionWrapper = styled.div`
  //grid-column-start: 1;
  //grid-column-end: 3;
`
