import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import Button from "../../../../../components/atoms/Button"
import Input from "../../../../../components/atoms/Input"
import ProfileImages from "pages/auth/Panel/Settings/Profile/ProfileImages"
import { Row } from "theme/styledComponents"
import { Title } from "components/atoms/Title"
import { P } from "components/atoms/P"
import SettingsBlock from "components/blocks/SettingsBlock"
import { Box } from "components/atoms"
import { ProfileService } from "services"
import { updateProfile } from "redux/reducers/profile"
import { AnalyticsService } from "../../../../../services/AnalyticsService"
import { EMAIL_REGEX, PHONE_REGEX, POST_CODE_REGEX } from "../../../../../utils/regexHelpers"

const Profile = () => {
  const dispatch = useDispatch()
  const profile = useSelector(state => state.profile)
  const [loading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...profile,
    },
  })

  const [images, setImages] = useState({
    ...profile.images,
  })

  const saveProfile = async state => {
    try {
      setLoading(true)
      const el = {
        ...state,
        images,
        name: profile.name,
        slug: profile.slug,
      }

      const res = await ProfileService.update(el)
      AnalyticsService.track("Profile Update")
      dispatch(updateProfile(res))
    } catch (err) {
      AnalyticsService.error("[ERROR update API]:", err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    console.log(errors)
  }, [errors])
  return (
    <Box>
      <Row>
        <div>
          <Title>Ustawienia porfilu</Title>
          <P>Zmień informacje o Twojej firmie oraz dane kontaktowe wyświetlane użytkownikom</P>
        </div>
        <Button
          width="max-content"
          margin="0 0 0 auto"
          loading={loading}
          onClick={handleSubmit(saveProfile)}
        >
          <FontAwesomeIcon icon={faSave} /> Zapisz
        </Button>
      </Row>
      <Box mt="50px">
        <SettingsBlock title="Grafiki profilu" text="Zdjęcie profilowe oraz grafika w tle">
          <ProfileImages images={images} setImages={setImages} />
        </SettingsBlock>
        <SettingsBlock title="Głowne informacje" text="Główne informacje na temat profilu">
          <Input
            margin="0 0 10px"
            background="white"
            label="Nazwa profilu*"
            error={errors.name}
            disabled
            rhf={register("name", { required: true })}
          />
          <Input
            background="white"
            startAdornment="motorata.pl/"
            error={errors.slug}
            label="Slug*"
            disabled
            rhf={register("slug", { required: true })}
          />
          <P small>
            Aby dowiedzieć się wiecej jak zmienić nazwę profilu lub adres kliknij{" "}
            <a href="https://pomoc.motorata.pl/partners/" target="_blank" rel="noreferrer">
              tutaj
            </a>
            .
          </P>
        </SettingsBlock>
        <SettingsBlock title="Dane kontaktowe" text="Dane kontaktowe widoczne dla użytkowników">
          <Input
            margin="0 0 10px"
            background="white"
            label="Numer telefonu*"
            error={errors.phone}
            rhf={register("phone", {
              required: true,
              pattern: {
                value: PHONE_REGEX,
                message: "Niepoprawny format telefonu (+48123456789)",
              },
            })}
          />
          <Input
            background="white"
            margin="0 0 10px"
            label="Email*"
            error={errors.email}
            rhf={register("email", {
              required: true,
              pattern: {
                value: EMAIL_REGEX,
              },
            })}
          />
          <Input
            background="white"
            startAdornment="https://"
            error={errors.website}
            label="Strona internetowa"
            rhf={register("website", {
              required: false,
            })}
          />
          {/*<Input background="white" startAdornment="facebook.com/" label="Facebook" rhf={register('username', { required: true })}/>*/}
          {/*<Input background="white" startAdornment="instagram.com/" label="Instagram" value={profile.social?.ig} ref={register}/>*/}
        </SettingsBlock>
        <SettingsBlock
          title="Lokalizacja salonu"
          text="Dane na temat lokalizacji salonu dla użytkowników"
        >
          <Input
            margin="0 0 10px"
            background="white"
            label="Adres"
            error={errors.address?.address}
            rhf={register("address.address", { required: true })}
          />
          <Input
            margin="0 0 10px"
            background="white"
            label="Miasto"
            error={errors.address?.city}
            rhf={register("address.city", { required: true })}
          />
          <Input
            background="white"
            label="Kod pocztowy"
            error={errors.address?.postCode}
            rhf={register("address.postCode", {
              required: true,
              pattern: {
                value: POST_CODE_REGEX,
                message: "Niepoprawny format",
              },
            })}
          />
        </SettingsBlock>
        <SettingsBlock
          title="Dodatkowe informacje"
          text="Dodatkowe informacje dotyczące Twojej firmy"
        >
          <Input
            margin="0 0 10px"
            background="white"
            label="Opis firmy*"
            error={errors.description}
            rhf={register("description", { required: false })}
          />
        </SettingsBlock>
      </Box>
    </Box>
  )
}

export default Profile
