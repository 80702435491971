import React, { useCallback, useEffect, useState } from "react"
import { faFileContract } from "@fortawesome/free-solid-svg-icons"
import RowTitleBlock from "../../../../../../components/blocks/RowTitleBlock"
import { Content, Wrapper } from "../AddOfferModal"
import { useProfile } from "../../../../../../hooks/useProfile"
import Textarea from "../../../../../../components/atoms/Textarea"
import { replaceStringWithValues } from "../../../../../../utils/suggestionsHelper"
import Button from "../../../../../../components/atoms/Button"
import { Row } from "../../../../../../theme/styledComponents"
import Select2 from "../../../../../../components/atoms/Select2"
import { useForm } from "react-hook-form"

const DescriptionContent = ({ state, setDescription, description, wasChanged, setWasChange }) => {
  const profile = useProfile()
  const { options = {} } = profile

  useEffect(() => {
    if (!wasChanged) {
      const str = options?.descriptionTemplate?.plain || ""
      const arr = str.split("\n")
      const finalArr = arr.map(el => replaceStringWithValues(el, state, profile))
      setDescription(finalArr.join("\n"))
    }
  }, [])

  const handleOnChange = useCallback(e => {
    setWasChange(true)
    setDescription(e.target.value)
  }, [])

  return <Textarea value={description} onChange={handleOnChange} />
}
const Description = ({
  number,
  step,
  setStep,
  state,
  initState = "",
  onBack,
  onSubmit,
  disabled,
  editMode = false,
}) => {
  const { slug } = useProfile()
  const [description, setDescription] = useState(initState)
  const [wasChanged, setWasChange] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm()

  const handleOnSubmit = useCallback(
    el => {
      onSubmit(6, {
        description,
        coordinator: el.coordinator,
      })
    },
    [description, onSubmit],
  )

  return (
    <Wrapper>
      <RowTitleBlock
        title="Opis oferty"
        disabled={disabled}
        onClick={!disabled ? () => setStep(number) : () => {}}
        icon={faFileContract}
        text="Dodaj opis oferty z dodatkowymi informacjami"
      />
      <Content active={step === number}>
        {step === number && (
          <DescriptionContent
            state={state}
            description={description}
            setDescription={setDescription}
            wasChanged={wasChanged}
            setWasChange={setWasChange}
          />
        )}
        {slug === "myleasepl" && !editMode && (
          <Select2
            label="Opiekun oferty*"
            options={[
              ["Adam Bauza", "Adam Bauza"],
              ["Mariusz Kołecki", "Mariusz Kołecki"],
              ["Marcin Młynarczyk", "Marcin Młynarczyk"],
              ["Marcin Kluczewski", "Marcin Kluczewski"],
              ["Roman Tratkiewicz", "Roman Tratkiewicz"],
              ["Andrzej Selewoniuk", "Andrzej Selewoniuk"],
              ["Łukasz Białasik", "Łukasz Białasik"],
              ["Jakub Kondraciuk", "Jakub Kondraciuk"],
              ["Kacper Nijak", "Kacper Nijak"],
              ["Jarosław Jochemczak", "Jarosław Jochemczak"],
              ["Julian Kujawiak", "Julian Kujawiak"],
              ["Joanna Becmer", "Joanna Becmer"],
              ["Arkadiusz Karczyński", "Arkadiusz Karczyński"],
              ["Michał Balicki", "Michał Balicki"],
              ["Michał Dąbrowski", "Michał Dąbrowski"],
              ["Radosław Kołecki", "Radosław Kołecki"],
              ["Mikołaj Ratajczak", "Mikołaj Ratajczak"],
              ["Robert Grzymała", "Robert Grzymała"],
              ["Natalia Świtała", "Natalia Świtała"],
              ["Małgorzata Wątła", "Małgorzata Wątła"],
              ["Krzysztof Terski", "Krzysztof Terski"],
              ["Katarzyna Śliwa", "Katarzyna Śliwa"],
              ["Rafał Segda", "Rafał Segda"],
              ["Artur Rybacki", "Artur Rybacki"],
              ["Tomasz Kaszewski", "Tomasz Kaszewski"],
              ["Joanna Zygmunt", "Joanna Zygmunt"],
              ["Paweł Storzyński", "Paweł Storzyński"],
              ["Daniel Mateusiak", "Daniel Mateusiak"],
              ["Rose Agyei", "Rose Agyei"],
              ["Łukasz Uciński", "Łukasz Uciński"],
              ["Krystian Stawarczyk", "Krystian Stawarczyk"],
              ["Renata Kmicic", "Renata Kmicic"],
              ["Krystian Cichocki", "Krystian Cichocki"],
              ["Anna Maciejewska", "Anna Maciejewska"],
              ["Iwona Jasińska", "Iwona Jasińska"],
            ]}
            defaultValue={initState?.coordinator}
            error={errors.colorType}
            margin="20px 0 0"
            rhf={register("coordinator", { required: true })}
          />
        )}
        <Row jc="flex-end">
          <Button type="third" margin="0 40px" width="max-content" onClick={onBack}>
            Wróć
          </Button>
          <Button margin="0" width="max-content" onClick={handleSubmit(handleOnSubmit)}>
            Dalej
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default Description
