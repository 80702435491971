import { WS_URL } from "../theme/constans"

export function initSocket(arr) {
  let socket = new SockJS(WS_URL)
  const stomp = Stomp.over(socket)

  stomp.connect({}, function (frame) {
    arr.map(({ url, onResponse }) => stomp.subscribe(url, onResponse))
  })

  return stomp
}
