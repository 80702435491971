
export const UPLOADED_FILE = "UPLOADED_FILE";
export const FILES_TO_UPLOAD = "FILES_TO_UPLOAD";

export const uploadedFile = data => {
  return {
    type: UPLOADED_FILE,
    payload: data,
  };
};

const addFilesToUpload = data => {
  return {
    type: FILES_TO_UPLOAD,
    payload: data,
  };
};

export const addFiles = files => async dispatch => {
  dispatch(addFilesToUpload(files))
}