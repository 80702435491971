import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { faSwatchbook } from "@fortawesome/free-solid-svg-icons"
import { useForm } from "react-hook-form"
import { getGeocode, getLatLng } from "use-places-autocomplete"
import { Content, Wrapper } from "../AddOfferModal"
import RowTitleBlock from "components/blocks/RowTitleBlock"
import { Title } from "components/atoms/Title"
import { Grid, Row } from "theme/styledComponents"
import Select2 from "components/atoms/Select2"
import Button from "components/atoms/Button"
import MonthPicker from "components/atoms/MonthPicker"
import { P } from "components/atoms/P"
import AutocompleteAddressInput from "components/AutocompleteAddressInput"
import Input from "components/atoms/Input"
import CheckboxInput from "../../../../../../components/atoms/CheckboxInput"
import Input2 from "../../../../../../components/atoms/Input2"
import { COLOR_TYPES_ARRAY, COLORS_ARRAY, COUNTRY_ARRAY } from "@motorata/base-ui.data"

const AdditionalInformations = ({
  number,
  step,
  setStep,
  disabled,
  initState = {},
  onBack,
  onSubmit,
  editMode,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      state: initState.state ?? "",
      isAvailable: initState.isAvailable ?? false,
      isDamaged: initState.isDamaged ?? false,
      course: initState.course ?? "",
      color: initState.params?.color ?? "",
      colorType: initState.params?.colorType ?? "",
      country: initState.params?.country ?? "polska",
      vin: initState.params?.vin ?? "",
      pickupDate: initState.params?.pickupDate ?? new Date(),
      address: initState.params?.address ?? null,
    },
  })

  const [loading, setLoading] = useState(false)

  const state = watch("state")
  const course = watch("course")
  const pickupDate = watch("pickupDate")
  const isAvailable = watch("isAvailable")
  const isDamaged = watch("isDamaged")

  const handleOnSubmit = useCallback(
    async state => {
      let obj = state
      if (obj.address?.description) {
        try {
          setLoading(true)
          const results = await getGeocode({
            address: obj.address?.description,
          })
          const { lat, lng } = await getLatLng(results[0])
          obj = {
            ...obj,
            address: {
              description: obj.address?.description,
              lat,
              lng,
            },
          }
        } catch (e) {
          console.log("GOOGLE ERROR [getGeocode]", e)
        } finally {
          setLoading(false)
        }
      }
      onSubmit(number, obj)
    },
    [onSubmit],
  )

  const handlePickupDate = useCallback(e => {
    setValue("pickupDate", e)
  }, [])

  const handleAddress = useCallback(e => {
    setValue("address", e)
  }, [])

  const handleIsAvailable = useCallback(e => {
    setValue("isAvailable", e)
  }, [])

  const handleIsDamaged = useCallback(e => {
    setValue("isDamaged", e)
  }, [])

  return (
    <Wrapper>
      <RowTitleBlock
        disabled={disabled}
        onClick={!disabled ? () => setStep(number) : () => {}}
        icon={faSwatchbook}
        title="Dodatkowe informacje"
        text="Podaj dodatkowe informacje o pojeździe"
      />
      <Content active={step === 2} onSubmit={handleSubmit(handleOnSubmit)}>
        <Block>
          <Grid gtc="1fr 1fr 1fr" gg="10px">
            <Select2
              disabled={editMode}
              label="Stan pojazdu"
              options={[
                ["Nowy", "brandNew"],
                ["Używany", "used"],
                ["Do produkcji", "configuration"],
                ["W czasie produkcji", "inProduction"],
              ]}
              defaultValue={initState.state}
              error={errors.state}
              rhf={register("state", { required: true })}
            />
            {(state === "brandNew" || state === "used") && (
              <>
                <Input2
                  type="int"
                  value={course}
                  label="Przebieg"
                  endAdornment="km"
                  error={errors.course}
                  rhf={register("course", { required: true })}
                />
              </>
            )}
            {state === "inProduction" && (
              <MonthPicker
                label="Przewidywany odbiór"
                error={errors.pickupDate}
                value={pickupDate}
                onChange={handlePickupDate}
                rhf={register("pickupDate", { required: true })}
              />
            )}
          </Grid>
          {(state === "brandNew" || state === "used") && (
            <Grid marginTop="20px" gtc="1fr 1fr 1fr" gg="10px">
              <CheckboxInput
                label="Dostępny od ręki"
                checked={isAvailable}
                onChange={handleIsAvailable}
              />
              <CheckboxInput label="Bezwypadkowy" checked={isDamaged} onChange={handleIsDamaged} />
            </Grid>
          )}
        </Block>
        <Block>
          <Title type="third">Wygląd zewnętrzny</Title>
          <Grid gtc="1fr 1fr 1fr" gg="10px">
            <Select2
              label="Kolor"
              options={COLORS_ARRAY}
              defaultValue={initState.params?.color}
              error={errors.color}
              rhf={register("color", { required: true })}
            />
            <Select2
              label="Typ koloru"
              options={COLOR_TYPES_ARRAY}
              defaultValue={initState.params?.colorType}
              error={errors.colorType}
              rhf={register("colorType", { required: true })}
            />
          </Grid>
        </Block>
        <Block>
          <Title type="third">Inne</Title>
          <Grid gtc="1fr 1fr 1fr" gg="10px">
            <Select2
              label="Kraj pochodzenia"
              options={COUNTRY_ARRAY}
              defaultValue={initState.params?.country || "polska"}
              error={errors.country}
              rhf={register("country", { required: true })}
            />
            {(state === "new" || state === "used") && (
              <Input label="VIN" error={errors.vin} rhf={register("vin", { required: false })} />
            )}
          </Grid>
        </Block>
        {/*<Block>*/}
        {/*  <Title type="third" margin="0 0 5px">*/}
        {/*    Lokalizacja pojazdu*/}
        {/*  </Title>*/}
        {/*  <P small>Jeśli można zobaczyć pojazd na żywo, podaj lokalizację poniżej</P>*/}
        {/*  <AutocompleteAddressInput margin="10px 0 0" onChange={handleAddress} />*/}
        {/*</Block>*/}
        <Row jc="flex-end">
          {onBack && (
            <Button type="third" margin="0 40px" width="max-content" onClick={onBack}>
              Wróć
            </Button>
          )}
          <Button margin="0" width="max-content" loading={loading} submit>
            Dalej
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default AdditionalInformations

const Block = styled.div`
  margin-bottom: 20px;
`
