import React, { useCallback, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import { useProfile, useProfileOptions } from "hooks/useProfile"
import { Title } from "components/atoms/Title"
import { P } from "components/atoms/P"
import Button from "components/atoms/Button"
import { Row } from "theme/styledComponents"
import { ProfileService } from "services"
import { updateProfileOptions } from "redux/reducers/profile"
import SuggestionsTextarea from "components/atoms/SuggestionsTextarea"
import SettingsBlock from "components/blocks/SettingsBlock"
import { Box } from "components/atoms"
import StatusesList from "./components/StatusesList"
import { AnalyticsService } from "../../../../../services/AnalyticsService"

const LeadsSettings = () => {
  const dispatch = useDispatch()
  const { leadStatuses } = useProfileOptions()

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(leadStatuses)

  const saveProfile = useCallback(async () => {
    try {
      setLoading(true)
      const res = await ProfileService.updateOptions({
        leadStatuses: [...state],
      })
      AnalyticsService.track("Profile Lead Update", {
        Length: state.length,
      })
      dispatch(updateProfileOptions(res))
    } catch (err) {
      AnalyticsService.error("[ERROR updateOptions API]:", err)
    } finally {
      setLoading(false)
    }
  }, [state])

  const handleOnChange = useCallback(
    (name, value) => {
      setState({
        ...state,
        [name]: value,
      })
    },
    [state],
  )

  return (
    <Box>
      <Row>
        <div>
          <Title>Ustawienia zapytań</Title>
          <P>Zmień ustawienia modułu zapytań</P>
        </div>
        <Button width="max-content" margin="0 0 0 auto" loading={loading} onClick={saveProfile}>
          <FontAwesomeIcon icon={faSave} /> Zapisz
        </Button>
      </Row>
      <Box mt="50px">
        <SettingsBlock
          title="Statusy zapytań"
          text="Ustaw dostępne statusy zapytań dla Twojego profilu."
        >
          <StatusesList data={state} setData={setState} />
        </SettingsBlock>
      </Box>
    </Box>
  )
}

export default LeadsSettings
