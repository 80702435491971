import React, { useCallback, useMemo, useRef, useState } from "react"
import { Box } from "./index"
import { mentions } from "../../utils/suggestionsHelper"
import { Label } from "components/atoms/Label"
import styled, { css } from "styled-components"
import "@draft-js-plugins/mention/lib/plugin.css"
import { EditorState, convertToRaw, convertFromRaw } from "draft-js"
import Editor from "@draft-js-plugins/editor"
import createMentionPlugin from "@draft-js-plugins/mention"
// import editorStyles from './SimpleMentionEditor.module.css';

const SuggestionsTextarea = ({ value, onChange, multiline = true, label, ...rest }) => {
  const ref = useRef(null)
  const [editorState, setEditorState] = useState(() =>
    value ? EditorState.createWithContent(convertFromRaw(value)) : EditorState.createEmpty(),
  )
  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState(mentions)

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: "@",
      entityMutability: "IMMUTABLE",
      mentionComponent(mentionProps) {
        return <StyledSpan className="mention-span">{mentionProps.children}</StyledSpan>
      },
    })
    const { MentionSuggestions } = mentionPlugin
    const plugins = [mentionPlugin]
    return { plugins, MentionSuggestions }
  }, [])

  const onOpenChange = useCallback(_open => {
    setOpen(_open)
  }, [])

  const suggestionsFilter = useCallback((value, mentions) => {
    return mentions.filter(el => el.name.includes(value) || el.slug.includes(value))
  }, [])

  const onSearchChange = useCallback(({ value }) => {
    setSuggestions(suggestionsFilter(value, mentions))
  }, [])

  const generatePlaintext = useCallback((raw = []) => {
    const entities = raw.entityMap
    return raw.blocks
      .map((el, index) => {
        let tmp = el.text
        el.entityRanges.forEach(en => {
          const key = entities[en.key]
          tmp = tmp.replace(key.data.mention.name, key.data.mention.slug)
        })
        return tmp
      })
      .join(multiline ? "\n" : " ")
  }, [])

  const handleOnChange = useCallback(
    e => {
      setEditorState(e)
      const contentState = e.getCurrentContent()
      const raw = convertToRaw(contentState)
      const plain = generatePlaintext(raw)
      onChange({
        raw,
        plain,
      })
    },
    [onChange],
  )

  return (
    <StyledWrapper
      {...rest}
      isLabeled={!!label}
      multiline={multiline}
      onClick={() => {
        ref.current.focus()
      }}
    >
      {label && (
        <Label small padding="0 15px" paddingTop="8px" paddingBottom="2px" lineHeight="normal">
          {label}
        </Label>
      )}
      <Editor
        editorKey={"editor"}
        editorState={editorState}
        onChange={handleOnChange}
        plugins={plugins}
        ref={ref}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
      />
    </StyledWrapper>
  )
}

export default SuggestionsTextarea

const StyledWrapper = styled(Box)`
  border-radius: 3px;
  background: ${({ theme, background }) => background ?? theme.color.background};

  .DraftEditor-root {
    padding: ${({ isLabeled, multiline }) =>
      isLabeled ? (!multiline ? "0px 15px 4px" : "0px 15px 20px") : "15px 20px"};
    height: ${({ multiline }) => (multiline ? "500px" : "auto")};
    overflow-y: auto;

    * {
      font-size: ${({ theme }) => theme.font.M};
      line-height: 22px;
    }

    ${({ multiline }) =>
      multiline &&
      css`
        padding-bottom: 0px;
      `}

    ${({ multiline }) =>
      !multiline &&
      css`
        .DraftEditor-editorContainer > div > div > div {
          margin-right: 3px !important;
        }

        .DraftEditor-editorContainer > div > div {
          display: flex;
          width: max-content;
        }
      `}
  }

  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    height: 100%;
  }

  *::-webkit-scrollbar {
    display: none;
  }
`

const StyledSpan = styled.span`
  color: ${({ theme }) => theme.color.primary};
  font-weight: bold;
`
