import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFiles } from '../hooks/useFiles';
import { FileService } from '../services/FileService';
import { uploadedFile } from '../redux/actions';
import { wait } from '../utils/timeHelpers';

const FileProvider = ({ children }) => {
  const dispatch = useDispatch()
  const [isUploading, setIsUploading] = useState()
  const { files, filesToUpload } = useFiles()

  const uploadFile = useCallback(async item => {
    const res = await FileService.uploadFile(item.file)
    dispatch(uploadedFile({
      id: item.id,
      file: res,
    }))
    await wait(500)
    return res;
  }, [])

  const uploadFiles = useCallback(async () => {
    setIsUploading(true)
    await Promise.all(filesToUpload.map(item => uploadFile(item)))
    setIsUploading(false)
  }, [filesToUpload])

  useEffect(() => {
    if(!isUploading && filesToUpload.length > 0){
      uploadFiles()
    }
  }, [isUploading, filesToUpload])

  return (
    <>
      { children }
    </>
  );
};

export default FileProvider;