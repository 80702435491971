import React, { useCallback, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import Input from "../../../../components/atoms/Input"
import Button from "../../../../components/atoms/Button"
import { Grid } from "../../../../components/atoms/Grid"
import { Title } from "../../../../components/atoms/Title"
import { Flex } from "../../../../components/atoms/Flex"
import { ProfileService } from "../../../../services"
import { updateUser } from "redux/actions"
import { updateProfile } from "redux/reducers/profile"
import { EMAIL_REGEX, PHONE_REGEX, SLUG_REGEX } from "../../../../utils/regexHelpers"
import { AnalyticsService } from "../../../../services/AnalyticsService"
import { slugify } from "../../../../utils/stringUtils"

function Step2({ state = {}, value = {}, setStep, onChange = () => {} }) {
  const timeoutId = useRef()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: value,
  })

  const name = watch("name")
  const slug = watch("slug")

  const onSubmit = useCallback(
    async el => {
      setLoading(true)
      try {
        await ProfileService.isSlugAvailable(el.slug)
      } catch (e) {
        setError("slug", { message: "Nazwa jest już zajęta" })
        AnalyticsService.error("[ERROR isSlugAvailable API]:", e)
        setLoading(false)
        return
      }
      try {
        const profile = await ProfileService.create({
          ...state["1"],
          ...el,
        })
        AnalyticsService.track("Register Account Create")
        dispatch(updateProfile(profile))
        dispatch(
          updateUser({
            status: "pending",
          }),
        )
      } catch (e) {
        AnalyticsService.error("[ERROR createProfile API]:", e)
      } finally {
        setLoading(false)
      }
    },
    [state],
  )

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      if (name && name !== "") {
        setValue("slug", slugify(name))
      }
    }, 1000)
  }, [name])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Title type="secondary">Podstawowe dane profilu</Title>
      <Grid gtc="1fr 1fr">
        <Input
          label="Nazwa profilu*"
          error={errors.name}
          rhf={register("name", { required: true })}
        />
        <Input
          type="slug"
          label="Adres internetowy profilu*"
          startAdornment="motorata.pl/"
          value={slug}
          error={errors.slug}
          rhf={register("slug", {
            required: true,
            pattern: {
              value: SLUG_REGEX,
              message: "Niepoprawny format",
            },
          })}
        />
      </Grid>
      <Title type="secondary" margin="30px 0 10px">
        Dane kontaktowe
      </Title>
      <Grid gtc="1fr 1fr 1fr">
        <Input
          label="Numer telefonu*"
          error={errors.phone}
          rhf={register("phone", {
            required: true,
            pattern: {
              value: PHONE_REGEX,
              message: "Niepoprawny format telefonu (+48123456789)",
            },
          })}
        />
        <Input
          label="Email kontaktowy*"
          error={errors.email}
          rhf={register("email", {
            required: true,
            pattern: {
              value: EMAIL_REGEX,
              message: "Niepoprawny format email",
            },
          })}
        />
        <Input
          label="Strona internetowa"
          startAdornment="https://"
          margin="0"
          rhf={register("website")}
        />
      </Grid>
      <Flex jc="flex-end" margin="20px 0 0">
        <Button type="third" width="max-content" margin="0 20px" onClick={() => setStep(1)}>
          Wróć
        </Button>
        <Button loading={loading} width="max-content" margin="0" submit>
          Zakończ
        </Button>
      </Flex>
    </form>
  )
}

Step2.propTypes = {}

export default Step2
