import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import { IntegrationBlock } from "../Integrations"
import Modal from "components/atoms/Modal"
import { Row } from "theme/styledComponents"
import LOGO_CMS from "assets/images/CMS.svg"
import { Box } from "components/atoms"
import Button from "components/atoms/Button"
import CustomCMSConfigModal from "./CustomCMSConfigModal"

export const CustomCMSBlock = ({ onChange, setCurrent }) => {
  const state = useSelector(store => store.profile.integrations.customCMS)

  const handleSwitch = useCallback(e => {
    onChange(e, "customCMS")
  }, [])

  return (
    <IntegrationBlock
      logo={LOGO_CMS}
      title="Własny system CMS"
      text="Pobieraj, dodawaj oraz aktualizuj swoje ogłsozenia."
      active={state?.active}
      onSwitch={handleSwitch}
    >
      {state?.active && (
        <Box background padding="10px 15px" marginTop="20px" borderRadius="3px">
          <Row height="100%" minHeight="35px" ai="center" jc="flex-end">
            <Button
              margin="0"
              type="third"
              width="max-content"
              onClick={() => setCurrent("customCMS")}
            >
              <FontAwesomeIcon icon={faCog} />
              Konfiguruj
            </Button>
          </Row>
        </Box>
      )}
    </IntegrationBlock>
  )
}

export const CustomCMSModals = ({ state, setState }) => {
  return (
    <>
      {state === "customCMS" && (
        <Modal maxWidth="500px" title="Skonfigutuj integrację" show={state} setShow={setState}>
          <CustomCMSConfigModal />
        </Modal>
      )}
    </>
  )
}
