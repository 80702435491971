import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import AuthView from "../../../views/Auth"
import { Title } from "../../../components/atoms/Title"
import { P } from "../../../components/atoms/P"
import Button from "../../../components/atoms/Button"
import { logout } from "../../../redux/actions"
import Step1 from "./steps/Step1"
import Step2 from "./steps/Step2"

function RegisterSteps() {
  const dispatch = useDispatch()

  const [step, setStep] = useState(1)
  const [state, setState] = useState({
    1: null,
    2: null,
    3: null,
  })

  const handleStep = useCallback(
    (step, value) => {
      setStep(step + 1)
      setState({
        ...state,
        [step]: value,
      })
    },
    [state],
  )

  return (
    <StyledAuthView
      gtc="1fr 1fr"
      maxWidth="750px"
      footerChildren={
        <Button type="third" margin="0 auto" onClick={() => dispatch(logout())}>
          Wyloguj się
        </Button>
      }
    >
      <HeaderWrapper>
        <Title>Dokończ rejestracje</Title>
        <P margin="0 0 25px">Dodaj niezbędne informacje do twojego konta aby utworzyć profil.</P>
        <StepsWrapper step={step}>
          <Step active={step >= 1}>
            <span>1</span>
            {/*Dane firmy*/}
          </Step>
          <Step active={step >= 2}>
            <span>2</span>
            {/*Dane firmy*/}
          </Step>
          {/*<Step active={step === 3}>*/}
          {/*  <span>3</span>*/}
          {/*  /!*Dane firmy*!/*/}
          {/*</Step>*/}
        </StepsWrapper>
      </HeaderWrapper>
      {step === 1 && <Step1 value={state["1"]} onChange={e => handleStep(1, e)} />}
      {step === 2 && <Step2 state={state} value={state["2"]} setStep={setStep} />}
    </StyledAuthView>
  )
}

RegisterSteps.propTypes = {}

export default RegisterSteps

const StyledAuthView = styled(AuthView)`
  @media (max-width: 1500px) {
    grid-template-columns: 3fr 1fr;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`

const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme, active }) => (active ? "white" : theme.color.third)};
  font-weight: ${({ theme }) => theme.font.normal};
  font-size: ${({ theme }) => theme.font.S};

  span {
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    background: ${({ theme, active }) => (active ? theme.color.primary : theme.color.background)};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    border-left: 10px solid white;
    border-right: 10px solid white;
    box-sizing: content-box;
    transition: all 0.3s;
    //transition-delay: 0.15s;
  }
`

const StepsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 400px;

  &:before {
    height: 3px;
    width: 100%;
    display: block;
    position: absolute;
    top: 13px;
    left: 0px;
    content: "";
    background: ${({ theme }) => theme.color.background};
    z-index: -1;
  }

  &:after {
    height: 3px;
    width: ${({ step }) => (step === 1 ? 0 : "100%")};
    display: block;
    position: absolute;
    top: 13px;
    left: 0px;
    content: "";
    background: ${({ theme }) => theme.color.primary};
    z-index: -1;
    transition: width 0.3s;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

  h1,
  p {
    text-align: center;
  }
`
