import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import MessagesList from "./components/MessagesList"
import Message from "./Message"
import { useParams } from "react-router"
import { Route } from "react-router-dom"
import update from "immutability-helper"

const Messages = () => {
  const { messageId } = useParams()

  const [chat, setChat] = useState()

  return (
    <Wrapper>
      <MessagesList setChat={setChat} />
      <Route
        path="/panel/messages/:messageId"
        render={props => <Message key={props.match.params.messageId} />}
      />
    </Wrapper>
  )
}

export default Messages

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`
