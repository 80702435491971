import React, { useCallback } from "react"
import styled, { css } from "styled-components"
import Portal from "../Portal"
import { Column } from "../../theme/styledComponents"
import { Title } from "./Title"
import CloseButton from "./CloseButton"
import { P } from "./P"

export const ModalContent = ({
  show,
  setShow,
  type,
  maxWidth,
  background = "white",
  title,
  text,
  activeClose,
  children,
}) => {
  const hideBackground = useCallback(() => {
    setShow(false)
  }, [setShow])

  return (
    <>
      {show && (
        <Content className="overflow-y" type={type} maxWidth={maxWidth} background={background}>
          {(title || text) && (
            <Column margin="0 0 10px">
              {title && (
                <Title type={type !== "side" && "secondary"} margin="0 0 5px">
                  {title}
                </Title>
              )}
              {text && <P>{text}</P>}
            </Column>
          )}
          {activeClose && <CloseButton onClick={hideBackground} />}
          {children}
        </Content>
      )}
    </>
  )
}

const Modal = ({
  type,
  maxWidth = "600px",
  show,
  setShow = () => {},
  title,
  text,
  children,
  background,
  activeClose = true,
}) => {
  const hideBackground = useCallback(() => {
    setShow(false)
  }, [setShow])

  let childrenElement = React.cloneElement(children, { show: show, setShow: setShow })

  return (
    <Portal id="root-modal">
      {show && (
        <>
          <Background onClick={activeClose ? hideBackground : () => {}} />
          <ModalContent
            show={show}
            type={type}
            maxWidth={maxWidth}
            title={title}
            setShow={activeClose ? setShow : () => {}}
            text={text}
            background={background}
            activeClose={activeClose}
          >
            {childrenElement}
          </ModalContent>
        </>
      )}
    </Portal>
  )
}

export default Modal

const Content = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  background: ${({ background }) => background};
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  transform: translateY(-50%) translateX(-50%);

  ${({ type, maxWidth }) =>
    type === "side" &&
    css`
      top: 0px;
      right: 0px;
      left: auto;
      border-radius: 0px;
      height: 100%;
      width: 100%;
      max-width: ${maxWidth};
      transform: translateY(0%) translateX(0%);
      padding-top: 40px;
    `}
`

const Background = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`
