import React, { useCallback, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import { useProfile, useProfileOptions } from "hooks/useProfile"
import { Title } from "components/atoms/Title"
import { P } from "components/atoms/P"
import Button from "components/atoms/Button"
import { Row } from "theme/styledComponents"
import { ProfileService } from "services"
import { updateProfileOptions } from "redux/reducers/profile"
import SuggestionsTextarea from "components/atoms/SuggestionsTextarea"
import SettingsBlock from "components/blocks/SettingsBlock"
import { Box } from "components/atoms"
import { AnalyticsService } from "../../../../../services/AnalyticsService"

const OffersSettings = () => {
  const dispatch = useDispatch()
  const { descriptionTemplate = {} } = useProfileOptions()

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(descriptionTemplate)

  const saveProfile = useCallback(async () => {
    try {
      setLoading(true)
      const res = await ProfileService.updateOptions({
        descriptionTemplate: {
          ...state,
        },
      })
      AnalyticsService.track("Profile Offer Template Update")
      dispatch(updateProfileOptions(res))
    } catch (err) {
      AnalyticsService.error("[ERROR updateOptions API]:", err)
    } finally {
      setLoading(false)
    }
  }, [state])

  const handleOnChange = useCallback(
    value => {
      console.log(value)
      setState(value)
    },
    [state],
  )

  return (
    <Box>
      <Row>
        <div>
          <Title>Ustawienia ogłoszeń</Title>
          <P>Zmień ustawienia publikowanych ogłoszeń</P>
        </div>
        <Button width="max-content" margin="0 0 0 auto" loading={loading} onClick={saveProfile}>
          <FontAwesomeIcon icon={faSave} /> Zapisz
        </Button>
      </Row>
      <Box mt="50px">
        <SettingsBlock
          title="Szablon opisu"
          text="Zapisz swój szablon opisu oferty, aby szybciej dodawać ogłoszenia."
        >
          <SuggestionsTextarea background="white" value={state?.raw} onChange={handleOnChange} />
        </SettingsBlock>
      </Box>
    </Box>
  )
}

export default OffersSettings
