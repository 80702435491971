import styled from "styled-components"
import {
  background,
  border,
  color,
  flexbox,
  layout,
  opacity,
  position,
  size,
  space,
} from "styled-system"

export const common = [
  background,
  background,
  space,
  flexbox,
  border,
  color,
  position,
  layout,
  size,
  opacity,
]

export const Box = styled.div`
  position: relative;
  cursor: ${({ onClick }) => onClick && "pointer"};
  ${common};
  background: ${({ theme, background }) =>
    background === true ? theme.color.background : theme.color[background] ?? background};
`

export const Column = styled.div`
  ${common};
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  ${common};
  cursor: ${({ onClick }) => onClick && "pointer"};
  display: flex;
`

export const Absolute = styled(Box)`
  position: absolute;
`
