import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useForm } from "react-hook-form"
import AuthView from "../../../views/Auth"
import { Title } from "../../../components/atoms/Title"
import { P } from "../../../components/atoms/P"
import Input from "../../../components/atoms/Input"
import Button from "../../../components/atoms/Button"
import { loginUser } from "../../../redux/actions"
import Alert from "../../../components/atoms/Alert"

function Login() {
  const dispatch = useDispatch()
  const { loginError, isLoggingIn } = useSelector(store => store.auth)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  })

  const onSubmit = useCallback(state => {
    dispatch(loginUser(state))
  }, [])

  return (
    <AuthView>
      <Title>Logowanie</Title>
      <P margin="0 0 25px">Zaloguj sie panelu administracyjnego, aby zarządzać ogłoszeniami.</P>
      {loginError && <Alert>Nie udało się zalogować. Nie właściwy email lub hasło.</Alert>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Username"
          name="username"
          error={errors.username}
          margin="0 0 10px"
          rhf={register("username", { required: true })}
        />
        <Input
          label="Hasło"
          password
          name="password"
          error={errors.password}
          margin="0 0 10px"
          rhf={register("password", { required: true })}
        />
        <Button submit loading={isLoggingIn}>
          Zaloguj się
        </Button>
      </form>
      <Button margin="15px auto" type="third" to="/register">
        Zarejestruj się
      </Button>
    </AuthView>
  )
}

Login.propTypes = {}

export default Login
