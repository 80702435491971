import React, { useCallback } from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Modal from './atoms/Modal';
import { P } from './atoms/P';
import { Title } from './atoms/Title';
import LoadingBlock from 'components/blocks/LoadingBlock';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import { Column, Row } from 'theme/styledComponents';

const ActionModal = ({ show, setShow, icon = faExclamationTriangle, color = "orange", title, text, loading, onAccept }) => {

  const handleOnNo = useCallback(() => {
    setShow(false)
  }, [setShow])

  return (
    <Modal
      maxWidth="400px"
      show={show}
      setShow={setShow}
    >
      <>
        <Column ai="center" jc="center">
          <Icon
            size="40px"
            margin="10px 0 15px"
            color={color}
            icon={icon}
          />
          <Title type="secondary" tx="center">
            {title}
          </Title>
          <P ta="center">{text}</P>
          <Row width="100%" margin="20px 0 0" jc="streach">
            <Button
              type="secondary"
              margin="0"
              width="100%"
              onClick={handleOnNo}>
              Nie
            </Button>
            <Button width="100%" margin="0" onClick={onAccept}>
              Tak
            </Button>
          </Row>
        </Column>
        {
          loading &&
          <LoadingBlock />
        }
      </>
    </Modal>
  );
};

export default ActionModal;
