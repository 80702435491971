import React from "react"
import styled from "styled-components"

import LOGO from "../assets/images/logo.png"
import BACKGROUND from "../assets/images/authBackground.png"

function AuthView(props) {
  const { gtc, gtr, children, footerChildren, maxWidth, className } = props

  return (
    <Wrapper className={className} gtc={gtc}>
      <Content gtr={gtr}>
        <InnerContent>
          <LogoWrapper>
            <LogoContent>
              <img src={LOGO} />
            </LogoContent>
          </LogoWrapper>
          <ChildrenWrapper maxWidth={maxWidth}>{children}</ChildrenWrapper>
        </InnerContent>
        <FooterWrapper>{footerChildren}</FooterWrapper>
      </Content>
      <Image>
        <img src={BACKGROUND} />
      </Image>
    </Wrapper>
  )
}

AuthView.propTypes = {}

export default AuthView

const FooterWrapper = styled.div``

const ChildrenWrapper = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || "500px"};
  margin: 0 auto;
  width: 100%;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 30px;
`

const LogoContent = styled.div`
  width: max-content;
  margin: 0 auto;
  img {
    height: 50px;
  }
`

const InnerContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${({ gtr }) => gtr || "1fr auto 1fr"};
  grid-gap: 20px;
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: ${({ gtr }) => gtr || "auto max-content"};
  padding: 25px 5vw;
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: ${({ gtc }) => gtc || "1fr 2fr"};
  overflow: hidden;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    > div:last-of-type {
      display: none;
    }
  }
`
