import axios from "axios";
import qs from 'qs'
import { getUserIdFromJWT } from 'utils/getUserIdFromJWT';
import { store } from 'App';
import { API_URL } from 'theme/constans';
import { getToken } from 'services/TokenServce';


export const APIService = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: params =>
    qs.stringify(params, {
      arrayFormat: 'brackets',
    }),
})

APIService.interceptors.request.use(
  async config => {
    const token = await getToken()
    config.baseURL = API_URL
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    if (config.url.includes(':userId') && token) {
      const id = getUserIdFromJWT(token)
      config.url = config.url.replace(':userId', id)
    }
    if (config.url.includes(':profileId')) {
      const { profile } = store.getState();
      config.url = config.url.replace(':profileId', profile.id)
    }
    return config
  },
  error => {
    console.log(error.data)
    // eslint-disable-next-line no-undef
    Promise.reject(error)
  },
)

APIService.interceptors.response.use(
  async response => {
    return response.data
  },
  async error => {
    const err = error.toJSON()
    // const sholudLogOut = (err?.message.includes('403') || err?.message.includes('401'))
    //
    // if (sholudLogOut) {
    //     store.dispatch(logout())
    // }

    // eslint-disable-next-line no-undef
    return Promise.reject(error)
  },
)
