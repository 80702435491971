import React from "react"
import styled from "styled-components"
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MUSelect,
} from "@material-ui/core"
import Spinner from "./Spinner"
import { Box } from "components/atoms/index"

function Select2({
  label,
  rhf = {},
  name,
  value,
  onChange,
  valueFunc,
  border,
  options = [],
  option,
  keyFunc,
  disabled,
  margin = "0",
  error,
  errorText,
  loading,
  defaultValue,
}) {
  const { ref, ...rest } = rhf

  return (
    <Box>
      <StyledFormControl margin={margin} error={error} border={border}>
        <InputLabel disabled={disabled}>{label}</InputLabel>
        <MUSelect
          labelId="demo-simple-select-filled-label"
          error={!!error}
          inputRef={ref}
          name={name}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          disabled={disabled}
          {...rest}
        >
          {options?.map(el => (
            <MenuItem key={keyFunc ? keyFunc(el) : el[1]} value={valueFunc ? valueFunc(el) : el[1]}>
              {option ? option(el) : el[0]}
            </MenuItem>
          ))}
        </MUSelect>
        {loading && (
          <SpinnerWrapper>
            <Spinner size={20} borderWidth={3} />
          </SpinnerWrapper>
        )}
      </StyledFormControl>
      <StyledFormHelperText>
        {errorText ?? error?.type === "required" ? "To pole jest wymagane" : error?.message}
      </StyledFormHelperText>
    </Box>
  )
}

Select2.propTypes = {}

export default Select2

const SpinnerWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 15px;
  background: ${({ theme }) => theme.color.background} !important;
`

const StyledFormHelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.color.red} !important;
  padding: 0 20px;
`

const StyledFormControl = styled(FormControl)`
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
  background: ${({ theme, background }) => background || theme.color.background} !important;
  margin: ${({ margin }) => margin || "0px"} !important;

  .MuiInputLabel-root {
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(20px, 18px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(20px, 10px) scale(0.75);
    }
  }

  .MuiInputBase-root {
    border: ${({ border }) => `1px solid ${border}`} !important;
    margin: 0px;
    width: 100%;
    min-height: 50px;
    padding: 0px;

    &:before,
    &:after {
      display: none;
    }

    .MuiSelect-root {
      padding: 0px;
      padding: 24px 20px 7px;
      background: none !important;
      font-size: ${({ theme }) => theme.font.M};
      font-weight: ${({ theme }) => theme.font.light};
    }

    .MuiSvgIcon-root {
      right: 7px;
    }

    &.Mui-disabled {
      * {
        cursor: not-allowed;
      }
    }
  }

  .Mui-disabled {
    opacity: 0.9;
  }
`
