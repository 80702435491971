import React, { useCallback } from "react"
import styled from "styled-components"
import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import CheckBoxIcon from "@material-ui/icons/CheckBox"

import { theme } from "../../theme/theme"

const StyledCheckbox = withStyles({
  root: {
    "color": theme.color.third,
    "&$checked": {
      color: theme.color.primary,
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const CheckBox2 = ({
  name,
  label,
  labelWeight = "normal",
  text,
  textPosition = "row",
  error,
  checked,
  onChange,
  margin,
}) => {
  const handleOnChange = useCallback(
    e => {
      onChange(e.target.checked)
    },
    [onChange],
  )

  return (
    <Wrapper margin={margin} labelWeight={labelWeight}>
      <FormControlLabel
        control={
          <StyledCheckbox
            checked={checked}
            onChange={handleOnChange}
            name={name}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            sx={{
              "color": error ? theme.color.red : theme.color.second,
              "&.Mui-checked": {
                color: theme.color.primary,
              },
              "& .MuiSvgIcon-root": { fontSize: 20 },
            }}
          />
        }
        label={
          label && (
            <div>
              {textPosition === "row" ? (
                <Label>
                  {label}
                  {text ? <span>{text}</span> : ""}
                </Label>
              ) : (
                <>
                  <Label>{label}</Label>
                  {text && (
                    <Label style={{ display: "block", marginLeft: 0 }} className="small">
                      {text}
                    </Label>
                  )}
                </>
              )}
            </div>
          )
        }
      />
    </Wrapper>
  )
}

export default CheckBox2

const Label = styled.label`
  &.small {
    font-size: ${({ theme }) => theme.font.S};
    color: ${({ theme }) => theme.color.third};
    margin-left: 10px;
    line-height: normal;
  }
`

const Wrapper = styled.div`
  margin: ${({ margin }) => margin || "0px"};

  .MuiTypography-root {
    font-size: ${({ theme }) => theme.font.M};
    color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme, labelWeight }) => theme.font[labelWeight]};
  }
`
