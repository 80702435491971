export const filterDuplicates = arr => {
  return [...new Map(arr.map(x => [x.value, x])).values()];
};

export const filterArray = arr => {
  return [...new Map(arr.map(x => [x, x])).values()];
};

export const uniqueArray = (arr, name) => {
  return Array.from(new Set(arr.map(a => a[name]))).map(id => {
    return arr.find(a => a.id === id);
  });
};

export const objectToArray = obj => {
  const arr = [];
  for (const [key, value] of Object.entries(obj)) {
    arr.push([value.pl, key]);
  }

  return arr;
};
