import React, { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import AuthView from "../../../views/Auth"
import { logout, updateUser } from "../../../redux/actions"
import { Title } from "components/atoms/Title"
import { P } from "components/atoms/P"
import Button from "components/atoms/Button"
import { Box } from "../../../components/atoms"
import { AnalyticsService } from "../../../services/AnalyticsService"
import { ProfileService } from "../../../services"
import { deleteIntegration } from "../../../redux/reducers/profile"
import { OtomotoBlock, OtomotoModals } from "../Panel/Settings/Integrations/Otomoto/Otomoto"

function RegisterIntegrations() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [current, setCurrent] = useState()

  const handleSwitch = useCallback(async (e, name) => {
    if (e.target.checked) {
      setCurrent(name)
    } else {
      const res = await ProfileService.deleteIntegration(name)
      dispatch(deleteIntegration(name))
    }
  }, [])

  const handleOtomotoSwitch = useCallback(async (e, name) => {
    if (e.target.checked) {
      setCurrent(name)
    } else {
      setCurrent("otomoto-delete-offers")
    }
  }, [])

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      const data = await ProfileService.submitIntegrations()
      AnalyticsService.track("Register Submit Integrations")
      dispatch(updateUser(data))
    } catch (e) {
      AnalyticsService.error("[ERROR submitIntegrations API]:", e)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <>
      <AuthView
        maxWidth="750px"
        footerChildren={
          <Button type="third" margin="0 auto" onClick={() => dispatch(logout())}>
            Wyloguj się
          </Button>
        }
      >
        <Box minWidth="450px">
          <Title>Dodatkowe integracje</Title>
          <P margin="0 0 25px">Uruchom dodatkowe aplikacje oraz skonfiguruj zewętrzne usługi.</P>
          <Box marginBottom="30px">
            <OtomotoBlock
              setCurrent={setCurrent}
              onChange={handleOtomotoSwitch}
              background="background"
            />
          </Box>
        </Box>
        <Button submit onClick={onSubmit} loading={loading}>
          Zakończ rejestrację
        </Button>
      </AuthView>
      <OtomotoModals state={current} setState={setCurrent} />
    </>
  )
}

RegisterIntegrations.propTypes = {}

export default RegisterIntegrations
