import { FILES_TO_UPLOAD, UPLOADED_FILE } from '../actions';

export default (
  state = {
    files: [],
    filesToUpload: [],
  },
  action,
) => {
  switch (action.type) {
  case FILES_TO_UPLOAD:
    return {
      ...state,
      filesToUpload: [ ...state.filesToUpload, ...action.payload],
    };
  case UPLOADED_FILE:
    var { id, file } = action.payload
    return {
      ...state,
      filesToUpload: state.filesToUpload.filter(file => file.id !== id),
      files: [file, ...state.files],
    };
  default:
    return state;
  }
};
