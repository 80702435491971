import React, { useCallback, useEffect, useState } from "react"
import { faFileContract, faList } from "@fortawesome/free-solid-svg-icons"
import RowTitleBlock from "../../../../../../components/blocks/RowTitleBlock"
import { Content, Wrapper } from "../AddOfferModal"
import { useProfile } from "../../../../../../hooks/useProfile"
import Textarea from "../../../../../../components/atoms/Textarea"
import { replaceStringWithValues } from "../../../../../../utils/suggestionsHelper"
import Button from "../../../../../../components/atoms/Button"
import { Row } from "../../../../../../theme/styledComponents"
import Select2 from "../../../../../../components/atoms/Select2"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import {
  ASSISTANT_ARRAY,
  COMFORT_ARRAY,
  MULTIMEDIA_ARRAY,
  SECURITY_ARRAY,
} from "@motorata/base-ui.data"
import CheckboxInput from "../../../../../../components/atoms/CheckboxInput"
import Input2 from "components/atoms/Input2"

const Equipment = ({
  number,
  step,
  setStep,
  state,
  initState = "",
  onBack,
  onSubmit,
  disabled,
  editMode = false,
}) => {
  const { slug } = useProfile()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: initState?.params?.versionName || "",
    },
  })

  const name = watch("name")
  const [type, setType] = useState("security")
  const [equipment, setEquipment] = useState(initState.equipment ?? [])

  const onChange = useCallback(
    el => {
      if (equipment.includes(el)) {
        setEquipment(equipment.filter(e => e !== el))
      } else {
        setEquipment([...equipment, el])
      }
    },
    [equipment],
  )

  const handleOnSubmit = useCallback(
    el => {
      onSubmit(5, { equipment, name })
    },
    [equipment, onSubmit, name],
  )

  return (
    <Wrapper>
      <RowTitleBlock
        title="Wyposażenie pojazdu"
        disabled={disabled}
        onClick={!disabled ? () => setStep(number) : () => {}}
        icon={faList}
        text="Dodaj wyposażenie swojego pojazdu"
      />
      <Content active={step === 5}>
        {slug === "myleasepl" && (
          <Input2
            type="name"
            label="Nazwa wersji"
            value={name}
            rhf={register("name")}
            margin="0 0 20px"
          />
        )}
        <ButtonsWrapper>
          <Button
            type={type === "security" ? "primary" : "secondary"}
            onClick={() => setType("security")}
            width="max-content"
          >
            Bezpieczeństwo
          </Button>
          <Button
            type={type === "comfort" ? "primary" : "secondary"}
            onClick={() => setType("comfort")}
            width="max-content"
          >
            Komfort
          </Button>
          <Button
            type={type === "assistant" ? "primary" : "secondary"}
            onClick={() => setType("assistant")}
            width="max-content"
          >
            Systemy wspomagające
          </Button>
          <Button
            type={type === "audio" ? "primary" : "secondary"}
            onClick={() => setType("audio")}
            width="max-content"
          >
            Audio i multimedia
          </Button>
        </ButtonsWrapper>
        <GridWrapper>
          {type === "security" &&
            SECURITY_ARRAY.map(el => (
              <CheckboxInput
                key={el[0]}
                label={el[0]}
                checked={equipment.includes(el[1])}
                onChange={() => onChange(el[1])}
              />
            ))}
          {type === "audio" &&
            MULTIMEDIA_ARRAY.map(el => (
              <CheckboxInput
                key={el[0]}
                label={el[0]}
                checked={equipment.includes(el[1])}
                onChange={() => onChange(el[1])}
              />
            ))}
          {type === "comfort" &&
            COMFORT_ARRAY.map(el => (
              <CheckboxInput
                key={el[0]}
                label={el[0]}
                checked={equipment.includes(el[1])}
                onChange={() => onChange(el[1])}
              />
            ))}
          {type === "assistant" &&
            ASSISTANT_ARRAY.map(el => (
              <CheckboxInput
                key={el[0]}
                label={el[0]}
                checked={equipment.includes(el[1])}
                onChange={() => onChange(el[1])}
              />
            ))}
        </GridWrapper>
        <Row jc="flex-end">
          <Button type="third" margin="0 40px" width="max-content" onClick={onBack}>
            Wróć
          </Button>
          <Button margin="0" width="max-content" onClick={handleOnSubmit}>
            Dalej
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default Equipment

const ButtonsWrapper = styled.div`
  display: flex;
  grid-gap: 10px;
  margin-bottom: 20px;
`

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
`
