export const TRANSLATE = {
  used: "Używany",
  configuration: "Konfiguracja",
  inProduction: "W produkcji",
  brandNew: "Nowy",

  active: "Aktywny",
  pending: "Oczekujący",
  deleted: "Usunięty",
  deactivate: "Nie aktywny",

  car: "Osobowy",
  truck: "Dostawczy",

  //BODY TYPES
  coupe: "Coupe",
  mini: "Mini",
  compact: "Kompakt",
  cityCar: "Auto miejskie",
  sedan: "Sedan",
  combi: "Kombi",
  minivan: "Mini Van",
  suv: "SUV",
  cabrio: "Cabrio",
  hatchback: "Hatchback",

  white: "Biały",
  brown: "Brązowy",
  black: "Czarny",
  red: "Czerwony",
  violet: "Fioletowy",
  blue: "Niebieski",
  silver: "Srebrny",
  grey: "Szary",
  green: "Zielony",
  yellow: "Żółty",
  other: "Inny kolor",

  metallic: "Metalik",
  matt: "Matowy",
  pearl: "Perłowy",

  petrol: "Benzyna",
  diesel: "Diesel",
  hybrid: "Hybryda",
  electric: "Elektryk",
  hidrogen: "Hidrogen",
  etanol: "Etanol",
  petrolLpg: "Benzyna + LPG",
  petrolCng: "Benzyna + CNG",

  manual: "Manualna",
  automatic: "Automatyczna",
  stepless: "Bezstopniowa",
  semiAutomatic: "Półautomatyczna",
}
