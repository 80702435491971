import React from "react"
import styled, { css } from "styled-components"
import { NavLink } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Spinner from "./Spinner"

function Button({
  secondary,
  type,
  to,
  isActive,
  children,
  width,
  margin,
  onClick,
  loading = false,
  alignItems,
  justifyContent,
  background,
  icon,
  submit,
  disabled,
  ...rest
}) {
  return (
    <>
      {to ? (
        <StyledA
          to={to}
          isActive={isActive}
          secondary={secondary}
          typeButton={type}
          width={width}
          margin={margin}
          alignItems={alignItems}
          justifyContent={justifyContent}
        >
          {icon && <FontAwesomeIcon icon={icon} />}
          {children}
        </StyledA>
      ) : (
        <StyledButton
          disabled={disabled}
          secondary={secondary}
          typeButton={type}
          width={width}
          margin={margin}
          background={background}
          onClick={!loading ? onClick : () => {}}
          alignItems={alignItems}
          justifyContent={justifyContent}
          type={submit ? "submit" : "button"}
          {...rest}
        >
          {icon && <FontAwesomeIcon icon={icon} />}
          {children}
          {loading && (
            <span className={`loading ${loading ? "active" : ""}`}>
              <Spinner white size={20} />
            </span>
          )}
        </StyledButton>
      )}
    </>
  )
}

export default Button

export const baseButtonStyles = css`
  position: relative;
  font-size: ${({ theme }) => theme.font.M};
  background: ${({ theme, background = "primary" }) => theme.color[background]};
  color: ${({ theme, color = "white" }) => theme.color[color]};
  font-weight: ${({ theme }) => theme.font.normal};
  margin: ${({ margin }) => margin || "0px"};
  padding: 10px 15px;
  border: 0px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};
  align-items: ${({ alignItems }) => alignItems || "center"};
  width: ${({ width }) => width || "100%"};
  min-height: 45px;
  overflow: hidden;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }

  ${({ typeButton }) =>
    typeButton === "secondary" &&
    css`
      min-height: 0;
      background: none;
      color: ${({ theme, color }) => (color ? theme.color[color] ?? color : theme.color.secondary)};
      transition: all 0.3s;
      padding: 10px 15px;
      margin: ${({ margin }) => margin || "0px"};
      background: ${({ theme, background }) => theme.color[background]};
      min-height: 45px;

      &:hover {
        color: ${({ theme, color }) => (color ? theme.color[color] ?? color : theme.color.primary)};
      }

      &.active {
        color: ${({ theme }) => theme.color.primary};
        background: ${({ theme }) => theme.color.background};
      }
    `}

  ${({ typeButton }) =>
    typeButton === "third" &&
    css`
      min-height: 0;
      background: none;
      color: ${({ theme }) => theme.color.secondary};
      transition: all 0.3s;
      width: max-content;
      padding: 0px;
      margin: ${({ margin }) => margin || "0px"};

      &:hover {
        color: ${({ theme }) => theme.color.primary};
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.5;
    `};
`

const StyledA = styled(NavLink)`
  ${baseButtonStyles}
`

const StyledButton = styled.button`
  position: relative;

  .loading {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.15s;
    background: ${({ theme }) => theme.color.primary};

    &.active {
      opacity: 1;
    }
  }

  ${baseButtonStyles}
`
