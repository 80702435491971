import { APIService } from "./APIService"

const confirmEmail = code => APIService.post(`/users/:userId/confirm-email?code=${code}`)

const resendCode = () => APIService.get(`/users/:userId/resend-code`)

const getStatus = () => APIService.get(`/users/:userId/status`)

const getProfile = () => APIService.get(`/users/:userId/profiles`)

export const UserService = {
  getProfile,
  confirmEmail,
  resendCode,
  getStatus,
}
