import React from "react"
import styled from "styled-components"
import Spinner from "../atoms/Spinner"
import { Column } from "components/atoms"

const SpinnerBlock = ({ margin, white, children, ...rest }) => {
  return (
    <Wrapper margin={margin} {...rest}>
      <Column>
        <Spinner white={white} />
        {children}
      </Column>
    </Wrapper>
  )
}

export default SpinnerBlock

const Wrapper = styled.div`
  height: ${({ height = "auto" }) => height};
  width: ${({ width = "auto" }) => width};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${({ margin }) => margin};

  img {
    width: 40px;
    margin-bottom: 10px;
  }
`
