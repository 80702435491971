import React from "react"
import styled from "styled-components"
import FormControlLabel from "@mui/material/FormControlLabel"
import { default as MUCheckbox } from "@mui/material/Checkbox"
import { theme } from "../../theme/theme"
import { common } from "./_atoms"

const Checkbox = ({ name, label, error, children, value, rhf, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <FormControlLabel
        control={
          <MUCheckbox
            {...rhf}
            checked={value}
            sx={{
              "color": error ? theme.color.red : theme.color.second,
              "&.Mui-checked": {
                color: theme.color.primary,
              },
              "& .MuiSvgIcon-root": { fontSize: 20 },
            }}
          />
        }
        label={children ?? label}
      />
    </Wrapper>
  )
}

export default Checkbox

const Wrapper = styled.div`
  ${common};

  .MuiFormControlLabel-root {
    margin-left: 0 !important;
    margin-right: 0 !important;
    align-items: flex-start;
  }

  .MuiCheckbox-root {
    padding: 0 !important;
    margin-right: 10px;
  }

  .MuiTypography-root {
    font-size: ${({ theme }) => theme.font.S};
    color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.light};

    a,
    span {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`
