import { API_URL } from '../theme/constans';
import { APIService } from "./APIService";

const getFiles = page => APIService.get(`/profiles/:profileId/files?page=${page}&size=40`)

const uploadFile = file => {
  const formData = new FormData()
  formData.append("file", file)

  return APIService.post(`${API_URL}/profiles/:profileId/files`, formData);
}

const deleteFile = id => APIService.delete(`/profiles/:profileId/files/${id}`)

export const FileService = {
  getFiles,
  uploadFile,
  deleteFile,
}
