import React from 'react';
import styled from 'styled-components'
import { Box, common } from 'components/atoms/index';

function Tag ({ children, ...rest }) {
  return(
    <StyledBox {...rest}>
      {children}
    </StyledBox>
  )
}

Tag.propTypes = {
};

export default Tag;

const StyledBox = styled(Box)`
  ${common};
  width: max-content;
  border-radius: 50px;
  font-size: ${({ theme }) => theme.font.S};
  color: ${({ theme, color }) => color ? theme.color[color] ?? color :  theme.color.third};
  font-weight: ${({ theme }) => theme.font.normal};
  background: ${({ theme, color }) => color ? theme.color[`${color}Background`] ?? color : theme.color.fourth };
  padding: 5px 10px;
`;
