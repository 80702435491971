import React, { useCallback, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import LoadingBlock from "components/blocks/LoadingBlock"
import { P } from "./atoms/P"
import update from "immutability-helper"

export function Row({ data }) {
  return (
    <tr className="row">
      {data.map((column, index) => (
        <td key={index}>{column}</td>
      ))}
    </tr>
  )
}

function Table({ columns = [], rows, getData, refresh, emptyComponent }) {
  const [loading, setLoading] = useState(false)
  const [activePage, setActivePage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const [numberOfElements, setNumberOfElements] = useState(0)
  const [totalElements, setTotalElements] = useState(0)

  const [data, setData] = useState([])

  useEffect(async () => {
    setLoading(true)
    const { content, totalPages, numberOfElements, totalElements } = await getData(activePage)
    setData(content)
    setTotalPages(totalPages)
    setNumberOfElements(numberOfElements)
    setTotalElements(totalElements)
    setLoading(false)
  }, [activePage, refresh])

  var pagination = []
  for (var i = 0; i < totalPages; i++) {
    pagination.push(i)
  }

  const handleUpdateItem = useCallback(
    (index, newData) => {
      const arr = update(data, {
        [index]: {
          $set: newData,
        },
      })
      setData(arr)
    },
    [setData, data],
  )

  return (
    <ScrollWrapper>
      <TableWrapper hasData={data.length > 0}>
        <ContentTable cellspacing="0" cellpadding="0">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column}</th>
            ))}
          </tr>
          {data.map((row, index) => rows(row, e => handleUpdateItem(index, e)))}
        </ContentTable>
        {totalPages > 1 && (
          <PaginationWrapper>
            <PaginationNumber
              arrow
              disabled={activePage === 0}
              onClick={() => (activePage !== 0 ? setActivePage(activePage - 1) : {})}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </PaginationNumber>

            {pagination.map((el, index) => (
              <PaginationNumber
                key={index}
                active={activePage === index}
                onClick={() => setActivePage(index)}
              >
                {index + 1}
              </PaginationNumber>
            ))}

            <PaginationNumber
              arrow
              disabled={activePage === totalPages - 1}
              onClick={() => (activePage !== totalPages - 1 ? setActivePage(activePage + 1) : {})}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </PaginationNumber>
          </PaginationWrapper>
        )}
        <P small margin="0" ta="right">
          {numberOfElements} z {totalElements}
        </P>
        {loading && <LoadingBlock />}
        {!loading && data.length === 0 && emptyComponent()}
      </TableWrapper>
    </ScrollWrapper>
  )
}

Table.propTypes = {}

export default Table

const PaginationNumber = styled.button`
  color: ${({ theme }) => theme.color.secondary};
  font-size: 15px;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2px;
  border-radius: 3px;

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.color.primary};
      color: white;
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      cursor: not-allowed;
    `}

  ${({ arrow }) =>
    arrow &&
    css`
      font-size: 10px;
      color: ${({ theme }) => theme.color.secondary};
    `}
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
`

const LoadingWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
`

const TableWrapper = styled.div`
  min-width: max-content;
  background: white;
  padding: 10px;
  border-radius: 5px;
  min-height: ${({ hasData }) => !hasData && "350px"};
  position: relative;
`

const ContentTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  tr {
    border-bottom: 1px solid ${({ theme }) => theme.color.background};

    &:first-of-type,
    &:last-of-type {
      border-bottom: 0px;
    }
  }

  th,
  td {
    border: none;
    padding: 0px;
  }

  th {
    background: ${({ theme }) => theme.color.background};
    font-size: ${({ theme }) => theme.font.S};
    font-weight: ${({ theme }) => theme.font.normal};
    color: ${({ theme }) => theme.color.secondary};
    padding: 8px 15px;
    text-align: left;

    &:first-of-type {
      border-radius: 3px 0 0 3px;
    }

    &:last-of-type {
      border-radius: 3px 0 0 3px;
    }

    &.textAlign-center {
      text-align: center;
    }
  }

  td {
    padding: 10px 15px;
    font-size: ${({ theme }) => theme.font.M};
    color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.normal};

    svg {
      margin: 0 auto;
      display: block;
    }

    .bold {
      font-weight: ${({ theme }) => theme.font.bold};
    }

    .tag {
      background: ${({ theme }) => theme.color.background};
      font-size: ${({ theme }) => theme.font.XS};
      font-weight: ${({ theme }) => theme.font.bold};
      padding: 5px 10px;
      border-radius: 3px;
      width: max-content;
    }

    .small {
      font-size: ${({ theme }) => theme.font.S};
      color: ${({ theme }) => theme.color.third};
    }

    .span {
      text-transform: uppercase;
      font-size: ${({ theme }) => theme.font.XS};
      border-radius: 3px;
      padding: 3px 15px;
      font-weight: ${({ theme }) => theme.font.bold};
    }
  }

  td,
  th {
    min-width: max-content;
    white-space: nowrap;
  }
`

const ScrollWrapper = styled.div``
