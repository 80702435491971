import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import AuthView from "../../../views/Auth"
import { Title } from "../../../components/atoms/Title"
import { P } from "../../../components/atoms/P"
import Input from "../../../components/atoms/Input"
import Button from "../../../components/atoms/Button"
import { registerUser } from "../../../redux/actions"
import { useAuth } from "../../../hooks/useAuth"
import { EMAIL_REGEX } from "../../../utils/regexHelpers"
import { AnalyticsService } from "../../../services/AnalyticsService"
import styled from "styled-components"
import { Box } from "../../../components/atoms"
import Alert from "../../../components/atoms/Alert"
import Checkbox from "../../../components/atoms/Checkbox"

function Register() {
  const { isRegistering, registerError } = useAuth()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = useCallback(async state => {
    console.log("errors", errors)
    AnalyticsService.track("Register Init")
    dispatch(registerUser({ ...state }))
  }, [])

  return (
    <AuthView>
      <Title>Rejestracja</Title>
      <P margin="0 0 25px">Zarejestruj sie do naszego serwisu, aby móc dodawać ogłoszenia.</P>
      {registerError && <Alert>Podany email jest już zajęty. Spróbuj podać inne dane.</Alert>}
      <Input
        margin="0 0 10px"
        label="E-mail"
        error={errors.username}
        rhf={register("username", {
          required: true,
          pattern: {
            value: EMAIL_REGEX,
            message: "Niepoprawny email",
          },
        })}
      />
      <Input
        margin="0 0 10px"
        label="Hasło"
        password
        error={errors.password}
        rhf={register("password", {
          required: true,
          pattern: {
            value: /^.{8,}$/,
            message: "Hasło musi się składać min. z 8 znaków",
          },
        })}
      />
      <Checkbox
        margin="0 0 20px"
        label={`Przeczytałem i akceptuję`}
        error={errors.checkbox}
        rhf={register("checkbox", {
          required: true,
        })}
      >
        Przeczytałem i akceptuję{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://motorata.pl/regulamin-dla-kont-biznesowych"
        >
          Regulamin
        </a>
        {", "}
        <a target="_blank" rel="noreferrer" href="https://motorata.pl/polityka-prywatnosci">
          Politykę prywatności
        </a>
        {" oraz "}
        <a target="_blank" rel="noreferrer" href="https://motorata.pl/rodo">
          Informacje o ochronie danych osobowych
        </a>
        .
      </Checkbox>
      <Button loading={isRegistering} submit onClick={handleSubmit(onSubmit)}>
        Zarejestruj się
      </Button>
      <Button margin="15px auto" type="third" to="/login">
        Wróć do logowania
      </Button>
    </AuthView>
  )
}

export default Register

const TypeButtonWrapper = styled.div`
  font-size: ${({ theme }) => theme.font.M};
  padding: 10px 15px;
  background: ${({ theme, background = "background" }) => theme.color[background]};
  color: ${({ theme, color = "secondary" }) => theme.color[color]};
  font-weight: ${({ theme }) => theme.font.normal};
  border: 0px;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 45px;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }
`
