import React, { useCallback, useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"
import Input from "../../../../components/atoms/Input"
import Button from "../../../../components/atoms/Button"
import { Grid } from "../../../../components/atoms/Grid"
import { Title } from "../../../../components/atoms/Title"
import { Flex } from "../../../../components/atoms/Flex"
import { AnalyticsService } from "../../../../services/AnalyticsService"
import { PublicService } from "../../../../services/PublicService"
import { Box } from "../../../../components/atoms"
import { POST_CODE_REGEX } from "../../../../utils/regexHelpers"

function Step1({ value = {}, onChange }) {
  const timeoutId = useRef()

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: value,
  })

  const [loading, setLoading] = useState(false)
  const [showForm, setShowForm] = useState(false)
  const [isFinished, setIsFinished] = useState(false)

  const nip = watch("company.nip")
  const companyName = watch("company.name")
  const address = watch("address.address")

  const onSubmit = useCallback(state => {
    AnalyticsService.track("Register Account Draft")
    onChange(state)
  }, [])

  const searchCompanyByNip = useCallback(
    async number => {
      setLoading(true)
      try {
        const res = await PublicService.getCompanyFromGUS(number)
        setValue("company", {
          nip: number,
          name: res.name || "",
          address: res.address,
          city: res.city,
          postCode: res.postCode,
        })
        setValue("address", {
          address: res.address || "",
          city: res.city,
          postCode: res.postCode,
        })
      } catch (e) {
        console.log("[ERROR getCompanyFromGUS API]", e)
      } finally {
        setShowForm(true)
        setLoading(false)
        setIsFinished(true)
      }
    },
    [companyName],
  )

  useEffect(() => {
    if (companyName) {
      return
    }

    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = setTimeout(() => {
      if (nip) {
        searchCompanyByNip(nip)
      }
    }, 1000)
  }, [nip, searchCompanyByNip])

  const disableCompany = companyName ? false : !isFinished
  const disableAddress = address ? false : !isFinished

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box marginBottom="30px">
        <Input
          label="Podaj NIP*"
          error={errors.company?.nip}
          loading={loading}
          rhf={register("company.nip", {
            required: true,
            pattern: {
              value: /^[0-9]{10}$/,
              message: "Niepoprawny format NIP",
            },
          })}
        />
      </Box>
      {showForm && (
        <Box>
          <Title type="secondary">Dane firmy</Title>
          <Grid gtc="1fr">
            <Input
              disabled={disableCompany}
              label="Nazwa firmy*"
              error={errors.company?.name}
              rhf={register("company.name", { required: true })}
            />
          </Grid>
          <Grid gtc="1.5fr 1fr 1fr">
            <Input
              disabled={disableCompany}
              label="Adres*"
              error={errors.company?.address}
              rhf={register("company.address", { required: true })}
            />
            <Input
              disabled={disableCompany}
              label="Kod pocztowy*"
              error={errors.company?.postCode}
              rhf={register("company.postCode", {
                required: true,
                pattern: {
                  value: POST_CODE_REGEX,
                },
              })}
            />
            <Input
              disabled={disableCompany}
              label="Miasto*"
              error={errors.company?.city}
              rhf={register("company.city", { required: true })}
            />
          </Grid>
        </Box>
      )}
      {showForm && (
        <Box>
          <Title type="secondary" margin="30px 0 10px">
            Główna lokalizacja siedziby
          </Title>
          <Grid gtc="1fr">
            <Input
              label="Adres*"
              disabled={disableAddress}
              error={errors.address?.address}
              rhf={register("address.address", { required: true })}
            />
          </Grid>
          <Grid gtc="1fr 1fr">
            <Input
              label="Kod pocztowy*"
              disabled={disableAddress}
              error={errors.address?.postCode}
              rhf={register("address.postCode", {
                required: true,
                pattern: {
                  value: POST_CODE_REGEX,
                  message: "Niepoprawny format",
                },
              })}
            />
            <Input
              label="Miasto*"
              disabled={disableAddress}
              error={errors.address?.city}
              rhf={register("address.city", { required: true })}
            />
          </Grid>
        </Box>
      )}
      <Flex jc="flex-end" margin="20px 0 0">
        <Button disabled={!(companyName && address)} width="max-content" margin="0" submit>
          Dalej
        </Button>
      </Flex>
    </form>
  )
}

Step1.propTypes = {}

export default Step1
