import { applyMiddleware, compose, createStore } from "redux"
import { configureStore } from "@reduxjs/toolkit"
import thunkMiddleware from "redux-thunk"

// import { verifyAuth } from "./redux/actions/";
import rootReducer from "./redux/reducers"
import { verifyUser } from "./redux/actions"
import { useQuery } from "./hooks/useQuery"

export default () => {
  /* eslint-disable no-underscore-dangle */
  // const store = createStore(
  //   rootReducer,
  //   /* preloadedState, */ compose(
  //     applyMiddleware(thunkMiddleware),
  //     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  //   ),
  // )
  /* eslint-enable */

  const store = configureStore({
    reducer: rootReducer,
  })

  store.dispatch(verifyUser())
  return store
}
