import React from "react"
import styled from "styled-components"
import { P } from "./P"
import { format } from "date-fns"
import { formatDate } from "../../utils/date.helper"

const Comment = ({ data, maxWidth, color = "secondary", align = "left", ...rest }) => {
  return (
    <CommentWrapper align={align} maxWidth={maxWidth}>
      <CommentContent {...rest}>
        <P small margin="0" color={color}>
          {data.message}
        </P>
      </CommentContent>
      {data.dtCreated && (
        <P margin="5px 0 0" fontSize="XXS">
          {formatDate(data.dtCreated)}
        </P>
      )}
    </CommentWrapper>
  )
}

export default Comment

const CommentWrapper = styled.div`
  width: fit-content;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ align }) => (align === "left" ? "0 auto 0 0" : "0 0 0 auto")};

  > div:first-of-type {
    margin: ${({ align }) => (align === "left" ? "0 auto 0 0" : "0 0 0 auto")};
  }
`

const CommentContent = styled.div`
  background: ${({ theme, background = "background" }) => theme.color[background]};
  padding: 10px;
  border-radius: 3px;
  width: fit-content;
`
