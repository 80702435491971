import React from 'react';
import styled from 'styled-components';
import { Row } from '../../theme/styledComponents';
import { Label } from './Label';

const LabelValue = ({ label, margin, children }) => {

  return (
    <Row jc="flex-start" ai="flex-end" margin={margin}>
      <Label>{label}</Label>
      <StyledSpan>{children}</StyledSpan>
    </Row>
  );
};

export default LabelValue;

const StyledSpan = styled.span`
  font-size: ${({ theme }) => theme.font.S};
  color: ${({ theme }) => theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.bold};
  margin-left: 5px;
  `;