import React, { useEffect, useRef } from "react"
import styled from "styled-components"

const Map = ({ lat, long }) => {
  const ref = useRef()

  const center = { lat: lat, lng: long }
  const zoom = 8

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      gestureHandling: "none",
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      panControl: false,
      disableDefaultUI: false,
      controlSize: 30,
      maxZoom: 8,
      minZoom: 5,
    })

    map.set
  })

  return (
    <Wrapper>
      <Content>
        <div ref={ref} id="map" />
        <RangeWrapper />
      </Content>
    </Wrapper>
  )
}

export default Map

const RangeWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  padding-bottom: 20%;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: "";
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 50%;
    background: ${({ theme }) => theme.color.primaryBackgroundOpacity};
    border: 2px solid ${({ theme }) => theme.color.primary};
  }
`

const Content = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  #map {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 50%;
  border-radius: 10px;
  overflow: hidden;
`
