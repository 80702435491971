import React, { useCallback, useState } from "react"
import styled, { css } from "styled-components"
import FinanceInformations from "./Blocks/FinanceInformations"
import AdditionalInformations from "./Blocks/AdditionalInformations"
import Gallery from "./Blocks/Gallery"
import BasicInformations from "./Blocks/BasicInformations"
import ActionModal from "components/ActionModal"
import Modal from "components/atoms/Modal"
import { theme } from "theme/theme"
import { Title } from "components/atoms/Title"
import { OffersService } from "services"
import Button from "components/atoms/Button"
import LoadingBlock from "components/blocks/LoadingBlock"
import Description from "./Blocks/Description"
import { AnalyticsService } from "../../../../../services/AnalyticsService"
import Equipment from "./Blocks/Equipment"

const initState = {
  1: null,
  2: null,
  3: null,
  4: [],
  5: null,
}

const EditOfferModal = ({ refresh, item, setItem }) => {
  const [showGoBackModal, setShowGoBackModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [offer, setOffer] = useState(null)
  const [step, setStep] = useState(2)
  const [state, setState] = useState({
    ...initState,
    1: {
      year: item.year,
      brand: item.brand.value,
      model: item.model.value,
      bodyType: item.bodyType,
      fuelType: item.fuelType,
      gearBox: item.gearBox,
      enginePower: item.enginePower,
      engineCapacity: item.engineCapacity,
      version: item.version,
    },
  })

  const handleSubmitStep = useCallback(
    (number, item) => {
      setState({
        ...state,
        [number]: item,
      })
      setStep(step + 1)
    },
    [step, state],
  )

  const handleBackStep = useCallback(() => {
    const newStep = step - 1
    setStep(newStep)
  }, [setStep, step])

  const goBack = useCallback(() => {
    setItem(null)
  }, [])

  const handleShowGoBackModal = useCallback(() => {
    setShowGoBackModal(true)
  }, [])

  const handleCloseGoBackModal = useCallback(() => {
    setShowGoBackModal(false)
  }, [])

  const handleGoBack = useCallback(() => {
    if (step > 1 && !offer) {
      handleShowGoBackModal()
    } else {
      goBack()
    }
  }, [step, offer])

  const handleSubmitOffer = useCallback(async () => {
    try {
      setLoading(true)
      const res = await OffersService.editOffer(item.id, {
        course: state["2"]?.course ?? "0",
        isAvailable: state["2"]?.isAvailable,
        isDamaged: state["2"]?.isDamaged,
        params: {
          color: state["2"]?.color,
          colorType: state["2"]?.colorType,
          vin: state["2"]?.vin,
          pickupDate: state["2"]?.pickupDate,
          address: state["2"]?.address,
          country: state["2"]?.country,
          versionName: state["5"]?.name && state["5"]?.name !== "" ? state["5"]?.name : null,
        },
        finance: {
          price: state["3"]?.price,
          showPrice: state["3"]?.showPrice,
          type: state["3"]?.priceType,
          invoice: state["3"]?.invoice,
          leasing: {
            ...state["3"]?.leasing,
          },
          loan: {
            ...state["3"]?.loan,
          },
          credit: {
            ...state["3"]?.credit,
          },
        },
        images: [...state["4"]?.map(el => el.id)],
        equipment: state["5"]?.equipment,
        description: state["6"]?.description,
        coordinator: state["6"]?.coordinator,
      })
      AnalyticsService.track("Offer Edit")
      setOffer(res)
      refresh()
      setItem(null)
    } catch (e) {
      AnalyticsService.error("[ERROR editOffer API]:", e.toString())
    } finally {
      setLoading(false)
    }
  }, [state])

  const handleAddNextOffer = useCallback(() => {
    setState(initState)
    setStep(1)
  }, [])
  return (
    <>
      <Modal
        title="Edytuj ogłoszenie"
        show={true}
        setShow={handleGoBack}
        type="side"
        maxWidth="900px"
        background={theme.color.background}
      >
        <>
          <BasicInformations
            disabled
            number={1}
            step={step}
            onSubmit={handleSubmitStep}
            onBack={handleBackStep}
          />
          <AdditionalInformations
            number={2}
            step={step}
            initState={item}
            onSubmit={handleSubmitStep}
            editMode={true}
          />
          <FinanceInformations
            number={3}
            step={step}
            initState={item}
            onSubmit={handleSubmitStep}
            onBack={handleBackStep}
          />
          <Gallery
            number={4}
            step={step}
            initState={item}
            onSubmit={handleSubmitStep}
            onBack={handleBackStep}
          />
          <Equipment
            number={5}
            step={step}
            initState={item}
            onSubmit={handleSubmitStep}
            onBack={handleBackStep}
          />
          <Description
            number={6}
            step={step}
            state={state}
            initState={item}
            onSubmit={handleSubmitStep}
            onBack={handleBackStep}
            editMode={true}
          />
          <Button disabled={step !== 7} onClick={handleSubmitOffer}>
            Edytuj ogłoszenie
          </Button>
          {loading && (
            <LoadingBlock white background="rgba(106, 103, 212, 0.9)" margin="0 0 30px">
              <Title margin="20px 0 0" color="white">
                Trwa edytowanie ogłoszenia
              </Title>
            </LoadingBlock>
          )}
        </>
      </Modal>
      <ActionModal
        show={showGoBackModal}
        setShow={setShowGoBackModal}
        title="Czy na pewno chcesz wróćić?"
        text="Twoje informacje oraz zmiany zostą utracone."
        onAccept={goBack}
      />
    </>
  )
}

export default EditOfferModal

export const Content = styled.form`
  margin-top: 20px;
  display: none;

  p {
    margin: 0px;
  }

  ${({ active }) =>
    active &&
    css`
      display: block;
    `}
`

export const Wrapper = styled.div`
  background: white;
  padding: 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-bottom: 20px;
`
