import React from "react"
import styled from "styled-components"
import { FormControl, InputLabel, MenuItem, Select as MUSelect } from "@material-ui/core"
import Spinner from "../../../../../components/atoms/Spinner"

const LeadStatusSelect = ({ name, value, onChange, options = [] }) => {
  return (
    <StyledFormControl>
      <MUSelect
        labelId="demo-simple-select-filled-label"
        name={name}
        value={value}
        onChange={onChange}
      >
        {options?.map(el => (
          <MenuItem key={el.id} value={el.id}>
            {el.name}
          </MenuItem>
        ))}
      </MUSelect>
    </StyledFormControl>
  )
}

export default LeadStatusSelect

const StyledFormControl = styled(FormControl)`
  width: 100%;
  border-radius: 3px;
  overflow: hidden;

  .MuiInputLabel-root {
    position: absolute;
    left: 0px;
    top: 0px;
    transform: translate(20px, 18px) scale(1);

    &.MuiInputLabel-shrink {
      transform: translate(20px, 10px) scale(0.75);
    }
  }

  .MuiInputBase-root {
    margin: 0px;
    width: max-content;
    padding: 0px;

    &:before,
    &:after {
      display: none;
    }

    .MuiSelect-root {
      padding: 0;
      padding-right: 24px;
      background: none !important;
      font-size: ${({ theme }) => theme.font.M};
      font-weight: ${({ theme }) => theme.font.normal};
    }

    .MuiSvgIcon-root {
      right: 0px;
    }

    &.Mui-disabled {
      * {
        cursor: not-allowed;
      }
    }
  }

  .Mui-disabled {
    opacity: 0.9;
  }
`
