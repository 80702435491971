import React from "react"
import styled from "styled-components"
import { Switch as MUSwitch, withStyles } from "@material-ui/core"
import { theme } from "../../theme/theme"
import { Label } from "./Label"

const CustomSwitch = withStyles({
  switchBase: {
    "color": theme.color.fourth,
    "&$checked": {
      color: theme.color.primary,
    },
    "&$checked + $track": {
      backgroundColor: theme.color.primary,
    },
  },
  checked: {},
  track: {
    backgroundColor: "rgba(0,0,0,0.3)",
  },
})(MUSwitch)

const Switch = ({ checked, labels = [], onChange, margin, disabled }) => {
  return (
    <Wrapper margin={margin}>
      {labels[0] && <Label>{labels[0]}</Label>}
      <CustomSwitch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        color="green"
        name="checkedA"
        inputProps={{ "aria-label": "secondary checkbox" }}
      />
      {labels[1] && <Label>{labels[1]}</Label>}
    </Wrapper>
  )
}

export default Switch

const Wrapper = styled.div`
  margin: ${({ margin }) => margin};
  display: flex;
  align-items: center;
`
