import React, { useCallback } from "react"
import styled, { css } from "styled-components"
import TextField from "@material-ui/core/TextField"
import { InputAdornment } from "@material-ui/core"
import { DOUBLE_REGEX, INT_REGEX, SLUG_REGEX } from "utils/regexHelpers"

function Input({
  type = "string",
  step,
  label = "Label",
  value,
  rhf = {},
  password,
  rules,
  margin = "0",
  disabled,
  startAdornment,
  endAdornment,
  error,
  errorText,
  background,
  maxWidth,
  border,
}) {
  const { ref, onChange = {}, ...rest } = rhf

  const handleOnChange = useCallback(
    e => {
      switch (type) {
        case "double":
          if (DOUBLE_REGEX.test(e.target.value)) {
            onChange(e)
          }
          break
        case "int":
          if (INT_REGEX.test(e.target.value)) {
            onChange(e)
          }
          break
        case "slug":
          if (SLUG_REGEX.test(e.target.value)) {
            onChange(e)
          }
          break
        default:
          onChange(e)
          break
      }
    },
    [onChange],
  )

  return (
    <StyledTextField
      onChange={handleOnChange}
      id="filled-basic"
      label={label}
      error={!!error}
      disabled={disabled}
      rules={rules}
      background={background}
      maxWidth={maxWidth}
      margin={margin}
      inputRef={ref}
      border={border}
      variant="filled"
      type={password ? "password" : "text"}
      value={value}
      step={step}
      helperText={
        errorText ?? error?.type === "required" ? "To pole jest wymagane" : error?.message
      }
      InputProps={{
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : null,
        endAdornment: endAdornment ? (
          <InputAdornment position="start">{endAdornment}</InputAdornment>
        ) : null,
      }}
      {...rest}
    />
  )
}

Input.propTypes = {}

export default Input

export const StyledInputBase = css`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin || "0 0 10px"} !important;

  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    display: none !important;
  }

  .MuiFilledInput-root {
    background: ${({ theme, background }) => background || theme.color.background} !important;
    border-radius: 3px;

    &.Mui-disabled {
      * {
        cursor: not-allowed;
      }
    }
  }

  .MuiFilledInput-input {
    padding: 24px 20px 7px;
    font-size: ${({ theme }) => theme.font.M};
    font-weight: ${({ theme }) => theme.font.light};
  }

  .MuiInputBase-root.Mui-disabled {
    opacity: 0.9;
  }

  .MuiInputLabel-root.Mui-focused {
    color: ${({ theme }) => theme.color.primary};
  }

  .MuiInputLabel-filled {
    transform: translate(20px, 18px) scale(1);

    &.Mui-error {
      color: #f8563f !important;
    }

    &.MuiInputLabel-shrink {
      transform: translate(20px, 10px) scale(0.75);
    }
  }

  .MuiFormHelperText-root {
    margin-left: 20px !important;
  }

  .MuiFilledInput-adornedStart {
    .MuiInputAdornment-positionStart {
      margin-right: 3px !important;

      p {
        font-size: ${({ theme }) => theme.font.S};
        padding-left: 8px;
      }
    }

    input {
      padding-left: 0px;
    }
  }

  ${({ icon }) =>
    icon &&
    css`
      .MuiInputAdornment-root {
        margin-top: 0px !important;
      }

      .MuiSvgIcon-root {
        width: 23px;
        height: 23px;
        color: ${({ theme }) => theme.color.primary};
      }

      .MuiInputAdornment-positionStart {
        margin-right: 5px;
      }

      .MuiFilledInput-input {
        padding-left: 0px !important;
      }

      .MuiInputLabel-filled {
        transform: translate(40px, 18px) scale(1);

        &.MuiInputLabel-shrink {
          transform: translate(40px, 10px) scale(0.75);
        }
      }
    `}
`

export const StyledTextField = styled(TextField)`
  ${StyledInputBase}
`
