import React from "react"
import styled from "styled-components"
import { Tooltip as MUTooltip } from "@mui/material"

const ToolTip = ({ text, placement = "top", children, ...rest }) => {
  return (
    <MUTooltip arrow title={text} placement={placement} {...rest}>
      {children}
    </MUTooltip>
  )
}

export default ToolTip

const StyledButton = styled.button`
  background: none;
  border: none;
  font-size: ${({ theme }) => theme.font.S};
  color: ${({ theme, color }) => theme.color[color]};
  padding: 0px;
  height: ${({ theme }) => theme.font.S};
  margin-bottom: 2px;

  svg {
    height: ${({ theme }) => theme.font.S};
  }
`
