import React, { useCallback } from "react"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import Spinner from "../atoms/Spinner"
import Image from "../atoms/Image"

export const ImageBlockLoader = () => {
  return (
    <LoaderWrapper>
      <LoaderContent className="gradient-animation" />
    </LoaderWrapper>
  )
}

const ImageBlock = ({ item, active, onClick = () => {}, width, onDelete }) => {
  const handleOnClick = useCallback(() => {
    onClick(item)
  }, [onClick, item])

  const handleDelete = useCallback(
    e => {
      // FileService.deleteFile(item.id)
      e.preventDefault()
      onDelete(item)
    },
    [item, onDelete],
  )

  return (
    <Wrapper active={active} width={width} onClick={!item.isUploading && handleOnClick}>
      <Content>
        <Image src={item.src} width="450" />
      </Content>
      {item.isUploading && (
        <IsUploading>
          <Spinner />
        </IsUploading>
      )}
      {onDelete && (
        <DeleteWrapper className="delete-button" onClick={handleDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </DeleteWrapper>
      )}
    </Wrapper>
  )
}

export default ImageBlock

const DeleteWrapper = styled.button`
  display: none;
  width: 20%;
  aspect-ratio: 1 / 1;
  background: ${({ theme }) => theme.color.red};
  position: absolute;
  right: 7%;
  top: 7%;
  max-width: 25px;
  border: 0px;
  border-radius: 3px;
  color: white;
  font-size: 10px;
  cursor: pointer;
`

const IsUploading = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
`

const LoaderWrapper = styled.div`
  padding: 5px;
`

const LoaderContent = styled.div`
  border: 3px solid rgba(0, 0, 0, 0);
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  aspect-ratio: 1 / 1;
  height: auto;
`

const Content = styled.div`
  position: absolute;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 7px;
  overflow: hidden;
  background: white;
`

const Wrapper = styled.div`
  border: 3px solid rgba(0, 0, 0, 0);
  width: ${({ width }) => width || "100%"};
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  aspect-ratio: 1 / 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${({ theme, active }) =>
    active &&
    css`
      border: 3px solid ${theme.color.primary};
    `};

  &:hover {
    .delete-button {
      display: block;
    }
  }
`
