import { Column } from "../../../../../../theme/styledComponents"
import Spinner from "../../../../../../components/atoms/Spinner"
import { P } from "../../../../../../components/atoms/P"
import React, { useCallback, useState } from "react"
import { faCheckCircle, faExclamationTriangle, faTrash } from "@fortawesome/free-solid-svg-icons"
import Icon from "../../../../../../components/atoms/Icon"
import Button from "../../../../../../components/atoms/Button"
import { ProfileService } from "../../../../../../services"
import { deleteIntegration } from "../../../../../../redux/reducers/profile"
import { useDispatch } from "react-redux"
import { Box } from "../../../../../../components/atoms"
import ActionModal from "../../../../../../components/ActionModal"
import { OtomotoService } from "../../../../../../services/integrations/OtomotoService"
import { AnalyticsService } from "../../../../../../services/AnalyticsService"

const OtomotoDeleteOffersModal = ({ show, setShow }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async () => {
    setLoading(true)

    try {
      await OtomotoService.deleteOffers()
    } catch (err) {
      AnalyticsService.error("[ERROR deleteOffers API]:", err)
    }

    try {
      await ProfileService.deleteIntegration("otomoto")
      dispatch(deleteIntegration("otomoto"))
    } catch (err) {
      AnalyticsService.error("[ERROR deleteIntegration API]:", err)
    }

    setLoading(false)
    setShow(false)
  }, [])

  return (
    <ActionModal
      icon={faExclamationTriangle}
      color="orange"
      show={show}
      setShow={setShow}
      title="Czy na pewno chcesz wyłączyć integrację?"
      text="Wyłączenie integracji z Otomoto oznacza usunięcie wszystkich ogłoszeń z naszego serwisu."
      loading={loading}
      onAccept={onSubmit}
    />
  )
}

export default OtomotoDeleteOffersModal
