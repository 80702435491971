import React from 'react';
import styled from 'styled-components';
import Spinner from '../atoms/Spinner';
import { Column } from 'components/atoms';

const LoadingBlock = ({ width, height, white, children, ...rest }) => {

  return (
    <Wrapper width={width} height={height} {...rest}>
      <Column>
        <Spinner white={white}/>
        {children}
      </Column>
    </Wrapper>
  );
};

export default LoadingBlock;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background ?? 'rgba(255,255,255,0.6)'};
  `;
