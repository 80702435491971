import React from "react";
import styled from "styled-components"
import { Box } from "./_atoms";
import { Label } from "./Label";
import { Title } from "./Title";

const LabelText = ({ label, children, ...rest }) => {
  return (
    <Box width="max-content" {...rest}>
      <Label margin="0 0 5px">{label}</Label>
      <Title margin="0" type="third">
        {children}
      </Title>
    </Box>
  )
}

export default LabelText