import styled from "styled-components"
import { common } from "../components/atoms"

export const Row = styled.div`
  ${common};
  display: flex;
  justify-content: ${({ jc }) => jc || "center"};
  align-items: ${({ ai }) => ai || "center"};
  flex-wrap: ${({ flexWrap }) => flexWrap || "wrap"};
  width: ${({ width }) => width};
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ jc }) => jc || "flex-start"};
  align-items: ${({ ai }) => ai || "flex-start"};
  margin: ${({ margin }) => margin || "0"};
  padding: ${({ padding }) => padding || "0"};
  max-width: ${({ maxWidth }) => maxWidth};
`

export const Grid = styled.div`
  ${common};
  display: grid;
  grid-template-columns: ${({ gtc }) => gtc || "1fr 1fr"};
  grid-gap: ${({ gg }) => gg || "20px"};
`

export const Box = styled.div`
  margin: ${({ margin }) => margin || "0"};
`
