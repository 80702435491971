import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { Label } from "../../../../../components/atoms/Label"
import { Title } from "../../../../../components/atoms/Title"
import { P } from "../../../../../components/atoms/P"
import { NavLink } from "react-router-dom"
import { ChatService } from "../../../../../services/ChatService"
import SpinnerBlock from "../../../../../components/blocks/SpinnerBlock"
import EmptyBlock from "../../../../../components/blocks/EmptyBlock"
import { Row } from "../../../../../components/atoms/_atoms"
import { formatDistanceStrict } from "date-fns"
import pl from "date-fns/locale/pl"
import { initSocket } from "../../../../../hooks/useSocket"
import update from "immutability-helper"
import { useProfile } from "../../../../../hooks/useProfile"
import { formatDate } from "../../../../../utils/date.helper"
import { AnalyticsService } from "../../../../../services/AnalyticsService"

const ListItem = ({ item, updateItem }) => {
  useEffect(() => {
    const socket = initSocket([
      {
        url: `/chat/${item.id}/message`,
        onResponse: res => {
          updateItem(item.id, "messages", [JSON.parse(res.body)])
        },
      },
      {
        url: `/chat/${item.id}/is-readed-by-profile`,
        onResponse: res => {
          updateItem(item.id, "isReadedByProfile", JSON.parse(res.body))
        },
      },
    ])

    return () => {
      socket.disconnect()
    }
  }, [])

  return (
    <ListItemWrapper to={`/panel/messages/${item.id}`}>
      <ListItemImage className="item_image">
        {item.isReadedByProfile === false && <ItemNotificationDot />}
        {/*{item.name.substring(0, 2).toUpperCase()}*/}
      </ListItemImage>
      <ListItemContent>
        <Row justifyContent="space-between">
          <Title margin="0 0 5px" type="secondary">
            {item.name}
          </Title>
          <Label small>{formatDate(item.messages[0].dtCreated)}</Label>
        </Row>
        <LeadItemTextWrapper>
          <P margin="0" small>
            {item.messages[0].message}
          </P>
        </LeadItemTextWrapper>
      </ListItemContent>
    </ListItemWrapper>
  )
}

const MessagesList = ({ setChat }) => {
  const { id } = useProfile()
  const [loading, setLoading] = useState(true)

  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [hasMorePages, setHasMorePages] = useState(false)

  const fetchData = useCallback(
    async newPage => {
      try {
        setLoading(true)
        const { content, totalPages } = await ChatService.getChats(newPage)
        setData(newPage > 0 ? [...data, ...content] : content)
        setCurrentPage(newPage)
        setHasMorePages(newPage < totalPages)
      } catch (e) {
        AnalyticsService.error("[ERROR getChats API]:", e)
      } finally {
        setLoading(false)
      }
    },
    [data],
  )

  useEffect(() => {
    fetchData(0)

    const socket = initSocket([
      {
        url: `/profile/${id}/chat`,
        onResponse: res => {
          setData(prevData => [JSON.parse(res.body), ...prevData])
        },
      },
    ])

    return () => {
      socket.disconnect()
    }
  }, [])

  const handleUpdateItem = useCallback((id, name, value) => {
    setData(prevData => {
      const index = prevData.findIndex(el => el.id === id)
      if (index !== -1) {
        return update(prevData, {
          [index]: {
            [name]: {
              $set: value,
            },
          },
        })
      }
    })
  }, [])

  return (
    <Wrapper className="hide_scroll">
      <Title margin="40px 20px 25px">Wiadomości</Title>
      {loading && data.length === 0 && <SpinnerBlock />}
      {!loading && data.length === 0 && <EmptyBlock margin="100px 0" text="Brak wiadomości" />}
      {data
        .sort((a, b) => {
          return new Date(b.messages[0].dtCreated) - new Date(a.messages[0].dtCreated)
        })
        .map(el => (
          <ListItem key={el.id} item={el} updateItem={handleUpdateItem} />
        ))}
    </Wrapper>
  )
}

export default MessagesList

const LeadItemTextWrapper = styled.div`
  line-height: ${({ theme }) => theme.font.M};
  max-height: 32px;
  overflow: hidden;
`

const ListItemContent = styled.div`
  flex: 1 1;
`

const ItemNotificationDot = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  background: ${({ theme }) => theme.color.red};
  width: 10px;
  height: 10px;
  display: block;
  border-radius: 50%;
`

const ListItemImage = styled.div`
  width: 42px;
  height: 42px;
  background: ${({ theme }) => theme.color.background};
  font-size: ${({ theme }) => theme.font.M};
  color: ${({ theme }) => theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.normal};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
`

const ListItemWrapper = styled(NavLink)`
  //border-bottom: 1px solid ${({ theme }) => theme.color.fourth};
  display: flex;
  align-items: center;
  padding: 15px 20px;

  &.active,
  &:hover {
    background: rgba(0, 0, 0, 0.03);

    .item_image {
      background: white;
    }
  }
`

const Wrapper = styled.div`
  background: white;
  margin-left: 2px;
  width: 350px;
  max-width: 350px;
  min-width: 350px;
  overflow: auto;
`
