import React from "react"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { common } from "components/atoms/index"

const Icon = ({ icon, size = "S", color = "secondary", margin = "0", onClick, ...rest }) => {
  return (
    <IconWrapper
      size={size}
      color={color}
      margin={margin}
      cursor={onClick && "pointer"}
      onClick={onClick}
      {...rest}
    >
      <FontAwesomeIcon icon={icon} />
    </IconWrapper>
  )
}

export default Icon

const IconWrapper = styled.div`
  ${common};
  cursor: ${({ cursor }) => cursor};
  font-size: ${({ theme, size }) => theme.font[size] ?? size};
  margin: ${({ margin }) => margin};

  * {
    color: ${({ theme, color }) => theme.color[color]};
  }

  ${({ onClick }) =>
    onClick &&
    css`
      &:hover {
        * {
          color: ${({ theme, color }) => theme.color.primary};
        }
      }
    `};
`
