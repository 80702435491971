import React from 'react';
import styled, { css } from 'styled-components'
import { makeStyles } from '@material-ui/core/styles';
import { Alert as MAlert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Alert({ type = "error", children, margin }) {
  const classes = useStyles();

  return (
    <Wrapper margin={margin} type={type}>
      <MAlert severity={type}>{children}</MAlert>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: ${({ margin }) => margin || ' 0 0 10px'};

  .MuiPaper-root {
    align-items: center;
  }

  .MuiAlert-message,
  .MuiAlert-icon {
    font-weight: ${({ theme }) => theme.font.normal};
    ${({ theme, type }) =>
            type === "error" &&
            css`
              color: ${theme.color.red} !important;
            `};

    ${({ theme, type }) =>
            type === "warning" &&
            css`
              color: ${theme.color.orange} !important;
            `};

    ${({ theme, type }) =>
            type === "success" &&
            css`
              color: ${theme.color.green} !important;
            `};
  }

`;
