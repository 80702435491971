import React, { useCallback, useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { faImages, faPhotoVideo, faPlusCircle, faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDropzone } from "react-dropzone"
import update from "immutability-helper"
import { DndProvider, useDrag, useDrop } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { Wrapper } from "../AddOfferModal"
import RowTitleBlock from "components/blocks/RowTitleBlock"
import Button from "components/atoms/Button"
import { Title } from "components/atoms/Title"
import { Row } from "theme/styledComponents"
import { makeid } from "utils/stringUtils"
import { FileService } from "services/FileService"
import ImageBlock, { ImageBlockLoader } from "components/blocks/ImageBlock"
import { ModalContent } from "components/atoms/Modal"
import { uniqueArray } from "utils/arrayHelpers"
import EmptyBlock from "components/blocks/EmptyBlock"

const GalleryList = ({ images = [], goBack, onSubmit }) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [isLastPage, setIsLastPage] = useState(false)
  const [selected, setSelected] = useState(images)

  const fetchData = useCallback(
    async page => {
      if (!isLastPage) {
        try {
          setLoading(true)
          const { content, number, last } = await FileService.getFiles(page)
          const newData = page === 0 ? content : [...data, ...content]
          setData(newData)
          setCurrentPage(number)
          setIsLastPage(last)
        } catch (e) {
          console.log("API Error: Can not get the files")
        } finally {
          setLoading(false)
        }
      }
    },
    [data],
  )

  useEffect(() => {
    fetchData(0)
  }, [])

  const isSelected = useCallback(
    image => {
      return selected.filter(el => el.id === image.id).length > 0
    },
    [selected],
  )

  const handleSelect = useCallback(
    item => {
      if (isSelected(item)) {
        const data = selected.filter(el => el.id !== item.id)
        setSelected(data)
      } else {
        const data = [item, ...selected]
        setSelected(data)
      }
    },
    [selected],
  )

  const handleSubmit = useCallback(() => {
    onSubmit(selected)
  }, [selected, onSubmit])

  return (
    <>
      <ImagesContent columns={5}>
        {data?.map(image => (
          <ImageBlock
            key={image.id}
            active={selected.map(el => el.id).includes(image.id)}
            width="100%"
            item={image}
            onClick={handleSelect}
          />
        ))}
        {loading && (
          <>
            <ImageBlockLoader />
            <ImageBlockLoader />
            <ImageBlockLoader />
          </>
        )}
      </ImagesContent>
      <GalleryListFooter>
        <Row jc="flex-end">
          <Button type="third" margin="0 40px" width="max-content" onClick={goBack}>
            Wróć
          </Button>
          <Button margin="0" width="max-content" type="submit" onClick={handleSubmit}>
            Wybierz ({selected.length})
          </Button>
        </Row>
      </GalleryListFooter>
    </>
  )
}

const BlockWrapper = ({ id, children, index, moveCard }) => {
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {children}
    </div>
  )
}

const Gallery = ({ number, step, setStep, initState, onBack, onSubmit, disabled }) => {
  const [isUploading, setIsUploading] = useState(false)
  const [showGallery, setShowGallery] = useState(false)
  const [images, setImages] = useState(initState?.images ?? [])

  const { acceptedFiles, getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 20,
    onDrop: acceptedFiles => {
      const data = acceptedFiles.map(file => {
        return {
          id: makeid(10),
          isUploading: true,
          src: URL.createObjectURL(file),
          file,
        }
      })

      setImages([...images, ...data])
    },
  })

  const uploadFile = async item => {
    try {
      const res = await FileService.uploadFile(item.file)
      const data = images
      const index = data.findIndex(el => el.id === item.id)
      data[index] = res
      setImages(data)
    } catch (e) {
      console.log("API Error: Can not upload the file")
    }
  }

  const uploadFiles = async data => {
    setIsUploading(true)
    await Promise.all(data.map(item => uploadFile(item)))
    setIsUploading(false)
  }

  useEffect(() => {
    if (!isUploading) {
      const data = images.filter(el => el.isUploading)
      if (data.length > 0) {
        uploadFiles(data)
      }
    }
  }, [isUploading, images])

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = images[dragIndex]
      setImages(
        update(images, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      )
    },
    [images],
  )

  const handleOnSubmit = useCallback(() => {
    onSubmit(number, images)
  }, [onSubmit, number, images])

  const handleAddButton = useCallback(() => {
    document.querySelector("#upload-input").click()
  }, [])

  const handleDelete = useCallback(
    item => {
      const data = images.filter(el => el.id !== item.id)
      setImages(data)
    },
    [images],
  )

  const handleShowModal = useCallback(() => {
    setShowGallery(true)
  }, [])

  const handleSelectedImages = useCallback(
    selectedImages => {
      const data = uniqueArray([...selectedImages], "id")
      setImages(data)
      setShowGallery(false)
    },
    [images],
  )

  const renderImages = (image, index) => {
    return (
      <BlockWrapper key={image.id} index={index} id={image.id} moveCard={moveCard}>
        <ImageBlock width="100%" active={index === 0} item={image} onDelete={handleDelete} />
      </BlockWrapper>
    )
  }

  return (
    <>
      <DropzoneWrapper
        {...getRootProps({
          onClick: event => event.stopPropagation(),
        })}
      >
        <Wrapper>
          <RowTitleBlock
            icon={faImages}
            disabled={disabled}
            onClick={!disabled ? () => setStep(number) : () => {}}
            title="Zdjęcie pojazdu"
            text="Dodaj zdjęcia pojazdu"
            step={4}
          />
          <Content active={step === 4}>
            <Row jc="flex-end">
              <Button
                type="third"
                width="max-content"
                background="background"
                margin="0 20px 0 0"
                onClick={handleShowModal}
              >
                <FontAwesomeIcon icon={faPhotoVideo} />
                Wybierz z galerii
              </Button>
              <Button width="max-content" margin="0 0 0 10px" onClick={handleAddButton}>
                <FontAwesomeIcon icon={faPlusCircle} />
                Dodaj zdjęcie
              </Button>
              <input id="upload-input" {...getInputProps()} />
            </Row>
            <ImagesWrapper>
              {images.length > 0 && (
                <DndProvider backend={HTML5Backend}>
                  <ImagesContent>{images.map((card, i) => renderImages(card, i))}</ImagesContent>
                </DndProvider>
              )}
              {images.length === 0 && (
                <EmptyBlock text="Dodaj nowe zdjęcie lub wybierz z dostępnych." />
              )}
            </ImagesWrapper>
            <Row jc="flex-end">
              <Button type="third" margin="0 40px" width="max-content" onClick={onBack}>
                Wróć
              </Button>
              <Button
                margin="0"
                disabled={images.length < 1}
                width="max-content"
                onClick={handleOnSubmit}
              >
                Dalej
              </Button>
            </Row>
          </Content>
        </Wrapper>
        {isDragAccept && (
          <DropzoneBackground>
            <FontAwesomeIcon icon={faUpload} />
            <Title>Upuść pliki, aby je dodać</Title>
          </DropzoneBackground>
        )}
      </DropzoneWrapper>
      <ModalContent
        title="Galeria zdjęć"
        text="Wybierz zdjęcia dostępne w Twojej galerii"
        show={showGallery}
        setShow={setShowGallery}
        type="side"
      >
        <GalleryList images={images} onSubmit={handleSelectedImages} />
      </ModalContent>
    </>
  )
}

export default Gallery

const GalleryListFooter = styled.div`
  position: sticky;
  bottom: -20px;
  left: 0px;
  padding: 10px;
  width: 100%;
  background: white;
`

const GalleryListWrapper = styled.div``

const DropzoneBackground = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(106, 103, 212, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    font-size: 55px;
    margin-bottom: 20px;
  }

  * {
    color: white;
  }
`

const DropzoneWrapper = styled.div``

const EmptyBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;

  img {
    width: 40px;
    margin-bottom: 10px;
  }
`

const ImagesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;

  > div {
    width: calc((100% / ${({ columns }) => columns || 7}) - 5px);
    margin-right: 5px;
    margin-bottom: 5px;
  }
`

const ImagesWrapper = styled.div`
  width: 100%;
  min-height: 140px;
  background: ${({ theme }) => theme.color.background};
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 15px;
  padding-bottom: 0px;
  padding-right: 10px;

  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
    height: 100%;
  }
`

export const Content = styled.div`
  margin-top: 20px;
  display: none;

  p {
    margin: 0px;
  }

  ${({ active }) =>
    active &&
    css`
      display: block;
    `}
`
