import { APIService } from "./APIService";

const getBrands = () => APIService.get(`/brands`);

const getModels = brand => APIService.get(`/brands/${brand}/models`);

const getModelSpecification = (brand, model, body) =>
  APIService.get(`/brands/${brand}/models/${model}`, {
    params: {
      ...body,
    },
  });

const getEnginePowers = (year, brand, model, fuelType) =>
  APIService.get(
    `/brands/${brand}/models/${model}/engine-powers?year=${year}&fuelType=${fuelType}`,
  );
const getEngineCapacities = (year, brand, model, fuelType, enginePower) =>
  APIService.get(
    `/brands/${brand}/models/${model}/engine-capacities?year=${year}&fuelType=${fuelType}&enginePower=${enginePower}`,
  );

const getVersions = (
  year,
  brand,
  model,
  fuelType,
  gearBox,
  enginePower,
  engineCapacity,
) =>
  APIService.get(
    `/brands/${brand}/models/${model}/versions?year=${year}&fuelType=${fuelType}&gearBox=${gearBox}&enginePower=${enginePower}&engineCapacity=${engineCapacity}`,
  );

export const ModelService = {
  getBrands,
  getModels,
  getModelSpecification,
  getEnginePowers,
  getEngineCapacities,
  getVersions,
};
