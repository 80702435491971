import styled, { css } from "styled-components"
import { common } from "components/atoms/index"

export const Label = styled.label`
  ${common};
  display: block;
  font-size: ${({ theme }) => theme.font.S};
  color: ${({ theme, color = "third" }) => theme.color[color]};
  font-weight: ${({ theme }) => theme.font.normal};
  margin: ${({ margin }) => margin || "0px"};
  line-height: ${({ lineHeight }) => lineHeight || "normal"};

  ${({ small }) =>
    small &&
    css`
      font-size: 12px !important;
      font-weight: normal;
    `};
`
