import React, { useCallback, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDropzone } from "react-dropzone"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { useDispatch } from "react-redux"
import moment from "moment"
import { Title } from "../../../../components/atoms/Title"
import Button from "../../../../components/atoms/Button"
import ImageBlock, { ImageBlockLoader } from "../../../../components/blocks/ImageBlock"
import { Column, Row } from "../../../../theme/styledComponents"
import LabelValue from "../../../../components/atoms/LabelValue"
import CloseButton from "../../../../components/atoms/CloseButton"
import { addFiles } from "../../../../redux/actions"
import { FileService } from "../../../../services/FileService"
import { makeid } from "../../../../utils/stringUtils"
import { useFiles } from "../../../../hooks/useFiles"
import "moment/locale/pl"
import { P } from "../../../../components/atoms/P"
import EmptyBlock from "../../../../components/blocks/EmptyBlock"
import Image from "../../../../components/atoms/Image"

const Drive = () => {
  const dispatch = useDispatch()
  const [active, setActive] = useState()
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    page: 0,
    hasNextPage: true,
    data: [],
  })

  const { files, filesToUpload } = useFiles()

  const { acceptedFiles, getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: "image/jpeg, image/png",
    maxFiles: 20,
    onDrop: acceptedFiles => {
      let isBig = false
      acceptedFiles.map(file => {
        if (file.size / 1024 / 1024 > 5) {
          isBig = true
        }
      })
      if (isBig) {
        alert("Rozmiar pliku nie może być większy niż 5MB")
        return
      }
      const data = acceptedFiles.map(file => {
        if (file.size / 1024 / 1024 > 5) {
          return
        }
        return {
          id: makeid(10),
          isUploading: true,
          src: URL.createObjectURL(file),
          file,
        }
      })
      dispatch(addFiles(data))
    },
  })

  const fetchData = useCallback(
    async page => {
      if (!state.loading && state.hasNextPage) {
        setLoading(true)
        const { content, number, last } = await FileService.getFiles(page)
        const data = [...state.data, ...content]
        setState({
          page: number,
          hasNextPage: !last,
          data: data,
        })
        setLoading(false)
      }
    },
    [dispatch, state],
  )

  useEffect(() => {
    fetchData(0)
  }, [])

  const handleActive = useCallback(item => {
    setActive(item)
  }, [])

  const handleClose = useCallback(item => {
    setActive()
  }, [])

  const filesToRender = [...filesToUpload, ...files, ...state.data].filter(
    (thing, index, self) => index === self.findIndex(t => t.id === thing.id),
  )

  const handleButtonClick = useCallback(() => {
    document.querySelector("#upload-input").click()
  }, [])

  return (
    <DropzoneWrapper
      {...getRootProps({
        onClick: event => event.stopPropagation(),
      })}
    >
      <Wrapper>
        <Row jc="space-between" ai="flex-start" margin="0 0 25px">
          <Title margin="0">Przestrzeń dyskowa</Title>
          <Button margin="0" width="max-content" onClick={handleButtonClick}>
            Dodaj plik
          </Button>
          <input id="upload-input" {...getInputProps()} />
        </Row>
        <Content>
          <ImagesWrapper className="overflow-y">
            <ImagesContent columns={active ? 5 : 7}>
              {filesToRender.map(image => (
                <ImageBlock
                  key={image.id}
                  item={image}
                  active={active?.id === image.id}
                  onClick={handleActive}
                />
              ))}
              {loading && (
                <>
                  <ImageBlockLoader />
                  <ImageBlockLoader />
                  <ImageBlockLoader />
                </>
              )}
            </ImagesContent>
            {!loading && filesToRender.length === 0 && (
              <Column margin="0 auto" ai="center" jc="center" padding="60px 0" maxWidth="400px">
                <EmptyBlock text="Dodaj swój pierwszy plik przeciągając go na ekran lub przy użyciu przycisku" />
                <Button margin="5px 0 5px" width="max-content">
                  Dodaj plik
                </Button>
                <P small ta="center">
                  max 10 MB
                </P>
              </Column>
            )}
          </ImagesWrapper>
          {active && (
            <ActiveImageWrapper>
              <ActiveImageContent>
                <CloseButton onClick={handleClose} />
                <ActiveImage>
                  <Image width="650" src={active.src} />
                </ActiveImage>
                <ActiveContent>
                  {/*<Title type="secondary" margin="0 0 20px">*/}
                  {/*  Zrzut ekranu ekrarnu eka 2021-09-9 o 18.27.57.png*/}
                  {/*</Title>*/}
                  {/*<Title type="third">Właściwości</Title>*/}
                  <LabelValue margin="0 0 5px" label="Typ:">
                    {active.type.toUpperCase()}
                  </LabelValue>
                  {/*<LabelValue margin="0 0 5px" label="Rozmiar:">*/}
                  {/*  1200 x 730 px*/}
                  {/*</LabelValue>*/}
                  <LabelValue margin="0 0 5px" label="Data dodania:">
                    {moment(active.dtCreated).format("DD MMMM YY")}
                  </LabelValue>
                  <LabelValue margin="0 0 5px" label="Zmodyfikowany:">
                    {moment(active.dtModified).format("DD MMMM YY")}
                  </LabelValue>
                  {/*<Button margin="20px 0 0" type="secondary" background="background">*/}
                  {/*  Edytuj zdjęcie*/}
                  {/*</Button>*/}
                </ActiveContent>
              </ActiveImageContent>
            </ActiveImageWrapper>
          )}
        </Content>
      </Wrapper>
      {isDragAccept && (
        <DropzoneBackground>
          <FontAwesomeIcon icon={faUpload} />
          <Title>Upuść pliki, aby je dodać</Title>
        </DropzoneBackground>
      )}
    </DropzoneWrapper>
  )
}

export default Drive

const EmptyListWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    font-size: 40px;
    margin-bottom: 20px;
  }
`

const DropzoneBackground = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(106, 103, 212, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    font-size: 55px;
    margin-bottom: 20px;
  }

  * {
    color: white;
  }
`

const DropzoneWrapper = styled.div`
  height: 100vh;
`

const ActiveContent = styled.div`
  padding: 20px;
`

const ImagesContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${({ columns }) => css`
    > div {
      width: calc(100% / ${columns});
    }
  `};
`

const ImagesWrapper = styled.div`
  width: 100%;
`

const ActiveImage = styled.div`
  height: 250px;
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ActiveImageContent = styled.div`
  width: 350px;
  min-width: 330px;
  //height: 100%;
  background: white;
  border-radius: 5px;
  margin-left: 25px;
  position: relative;
  overflow: hidden;
`

const ActiveImageWrapper = styled.div`
  padding-bottom: 40px;
`

const Content = styled.div`
  display: flex;
  flex: 1 1;
`

const Wrapper = styled.div`
  padding: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
`
