import React, { useCallback } from "react"
import styled from "styled-components"
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InputAdornment } from "@material-ui/core"
import { DOUBLE_REGEX } from "../../utils/regexHelpers"
import { convertNumber } from "../../utils/numberHelpers"
// import { StyledTextField } from './Input';

const NumberInput = ({ label, name, value, border, margin, onChange }) => {
  const handleChange = useCallback(
    e => {
      const value = e.target.value
      if (DOUBLE_REGEX.test(value)) {
        onChange(e)
      }
    },
    [onChange],
  )

  const handleOnBlur = useCallback(() => {
    onChange({
      target: {
        name: name,
        value: convertNumber(value),
      },
    })
  }, [value, onChange])

  const handlePlus = useCallback(() => {
    const number = parseFloat(value) + parseFloat("0.1")
    onChange({
      target: {
        name: name,
        value: convertNumber(number.toFixed(2)),
      },
    })
  }, [value, onChange])

  const handleMinus = useCallback(() => {
    const number = parseFloat(value) - parseFloat("0.1")
    onChange({
      target: {
        name: name,
        value: convertNumber(number.toFixed(2)),
      },
    })
  }, [value, onChange])

  return (
    <Wrapper margin={margin} border={border}>
      {/*<StyledTextField*/}
      {/*  id='filled-basic'*/}
      {/*  label={label}*/}
      {/*  variant='filled'*/}
      {/*  onChange={handleChange}*/}
      {/*  name={name}*/}
      {/*  value={value}*/}
      {/*  onBlur={handleOnBlur}*/}
      {/*  margin="0"*/}
      {/*  InputProps={{*/}
      {/*    endAdornment: <InputAdornment position='start'>%</InputAdornment>,*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<ButtonsWrapper>*/}
      {/*  <ButtonClick type="button" onClick={handlePlus}>*/}
      {/*    <FontAwesomeIcon icon={faPlus}/>*/}
      {/*  </ButtonClick>*/}
      {/*  <ButtonClick type="button">*/}
      {/*    <FontAwesomeIcon icon={faMinus} onClick={handleMinus}/>*/}
      {/*  </ButtonClick>*/}
      {/*</ButtonsWrapper>*/}
    </Wrapper>
  )
}

export default NumberInput

const ButtonClick = styled.button`
  flex: 1 1;
  background: ${({ theme }) => theme.color.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px;

  svg {
    font-size: 10px;
    color: white;
  }
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0 5px 5px 0;
  overflow: hidden;
  padding: 5px;
  background: ${({ theme }) => theme.color.background};
  width: 40px;

  > *:first-of-type {
    margin-bottom: 1px;
    border-radius: 3px 3px 0 0;
  }
  > *:last-of-type {
    border-radius: 0 0 3px 3px;
  }
`

const Wrapper = styled.div`
  display: flex;
  margin: ${({ margin }) => margin};
  border: ${({ border }) => `1px solid ${border}`} !important;
  border-radius: 3px;
  height: 50px;

  .MuiInputBase-root {
    height: 48px;
  }

  .MuiFilledInput-root {
    border-radius: 3px 0 0 3px;
  }

  .MuiFilledInput-adornedEnd {
    padding-right: 0px !important;
  }
`
