import { deleteToken, setToken, UserService, AuthService } from "../../services"
import { PublicService } from "../../services/PublicService"
import { setProfile } from "../reducers/profile"
import { AnalyticsService } from "../../services/AnalyticsService"
import { getQuery } from "../../utils/queryHelper"

export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"

export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAILURE = "REGISTER_FAILURE"

export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILURE = "LOGOUT_FAILURE"

export const VERIFY_REQUEST = "VERIFY_REQUEST"
export const VERIFY_SUCCESS = "VERIFY_SUCCESS"
export const VERIFY_ERROR = "VERIFY_ERROR"

export const UPDATE_USER = "UPDATE_USER"

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  }
}

const receiveLogin = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  }
}

const requestRegister = () => {
  return {
    type: REGISTER_REQUEST,
  }
}

const receiveRegister = user => {
  return {
    type: REGISTER_SUCCESS,
    payload: user,
  }
}

const registerError = () => {
  return {
    type: REGISTER_FAILURE,
  }
}

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  }
}

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  }
}

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  }
}

export const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  }
}

export const verifySuccess = user => {
  return {
    type: VERIFY_SUCCESS,
    payload: user,
  }
}

const verifyError = () => {
  return {
    type: VERIFY_ERROR,
  }
}

export const updateUser = user => {
  return {
    type: UPDATE_USER,
    payload: user,
  }
}

export const registerUser = state => async dispatch => {
  dispatch(requestRegister())
  try {
    const user = await PublicService.register(state)
    const { role, token } = user
    if (role === "ROLE_PROFILE") {
      setToken(token)
      dispatch(receiveRegister(user))
    }
  } catch (e) {
    dispatch(registerError())
    AnalyticsService.error("[ERROR register API]:", e)
  }
}

export const loginUser = us => async dispatch => {
  dispatch(requestLogin())
  try {
    const user = await AuthService.login(us)
    const { role, token, status } = user
    if (role === "ROLE_PROFILE") {
      setToken(token)
      const profile = await UserService.getProfile()
      AnalyticsService.identify(profile.id, {
        Name: profile.name,
        Slug: profile.slug,
        Email: profile.email,
        Status: status,
        Username: profile.username,
      })
      AnalyticsService.track("Log in")
      dispatch(setProfile(profile))
      dispatch(receiveLogin(user))
    } else if (role === "ROLE_ADMIN") {
      setToken(token)
      dispatch(receiveLogin(user))
    }
  } catch (e) {
    dispatch(loginError())
  }
}

export const verifyUser = query => async dispatch => {
  const query = getQuery()
  if (query.get("token")) {
    setToken(query.get("token"))
  }

  dispatch(verifyRequest())

  try {
    const user = await AuthService.verify()
    const { role, status } = user
    if (role === "ROLE_PROFILE") {
      const profile = await UserService.getProfile()
      AnalyticsService.identify(profile.id, {
        Name: profile.name,
        Slug: profile.slug,
        Email: profile.email,
        Status: status,
        Username: profile.username,
      })
      AnalyticsService.track("Verify")
      dispatch(setProfile(profile))
      dispatch(verifySuccess(user))
    } else if (role === "ROLE_ADMIN") {
      dispatch(verifySuccess(user))
    }
  } catch (e) {
    dispatch(verifyError())
  }
}

export const logout = () => dispatch => {
  dispatch(requestLogout())
  deleteToken()
  dispatch(receiveLogout())
}
