import styled, { css } from "styled-components"

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.XXL};
  color: ${({ theme, color }) => (color ? theme.color[color] ?? color : theme.color.secondary)};
  font-weight: ${({ theme }) => theme.font.bold};
  margin: ${({ margin }) => margin || "0px 0px 10px"};
  text-align: ${({ tx, textAlign }) => textAlign || tx || "left"};

  ${({ type }) =>
    type === "secondary" &&
    css`
      font-size: ${({ theme }) => theme.font.L};
      font-weight: ${({ theme }) => theme.font.bold};
    `}

  ${({ type }) =>
    type === "third" &&
    css`
      font-size: ${({ theme }) => theme.font.M};
      font-weight: ${({ theme, fontWeight = "normal" }) => theme.font[fontWeight]};
      position: relative;
    `}
`
