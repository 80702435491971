import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import Modal from "../../../../components/atoms/Modal"
import { useHistory, useParams } from "react-router"
import SpinnerBlock from "../../../../components/blocks/SpinnerBlock"
import { LeadService } from "../../../../services/LeadService"
import { Title } from "../../../../components/atoms/Title"
import { Label } from "../../../../components/atoms/Label"
import { Row } from "../../../../components/atoms"
import { format } from "date-fns"
import { Box, Grid } from "../../../../components/atoms/_atoms"
import Icon from "../../../../components/atoms/Icon"
import {
  faBuilding,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import Map from "../../../../components/atoms/Map"
import LeadComments from "./components/LeadComments"
import LeadStatusSelect from "./components/LeadStatusSelect"
import { useProfileOptions } from "../../../../hooks/useProfile"
import { Row as FlexRow } from "../../../../theme/styledComponents"
import ToolTip from "../../../../components/atoms/Tooltip"
import { AnalyticsService } from "../../../../services/AnalyticsService"
import LabelText from "../../../../components/atoms/LabelText";

const Dropdown = ({ active, setActive, slug, title, children }) => {
  const isActive = active === slug

  return (
    <DropdownWrapper>
      <Row onClick={() => setActive(slug)} alignItems="center">
        <Icon size="15px" margin="0 10px 0 0" icon={isActive ? faChevronUp : faChevronDown} />
        <Title width="max-content" margin="0" type="secondary">
          {title}
        </Title>
      </Row>
      {isActive && <Box marginTop="10px">{children}</Box>}
    </DropdownWrapper>
  )
}

const LeadBasicInfo = ({ data }) => {
  return (
    <>
      <Grid marginBottom="20px" gridTemplateColumns="1fr 1fr">
        <LabelText label="Telefon">
          {!data.phoneVerified ? (
            <ToolTip text="Niezweryfikowany numer telefonu">
              <FlexRow jc="flex-start" className="bold" flexWrap="no-wrap">
                <Icon
                  size="12px"
                  color="warning"
                  margin="0 10px 0 0"
                  icon={faExclamationTriangle}
                />
                {data.phone}
              </FlexRow>
            </ToolTip>
          ) : (
            data.phone
          )}
        </LabelText>
        <LabelText label="Email">{data.email}</LabelText>
      </Grid>
      {data?.company && (
        <Box marginBottom="30px">
          <Grid marginBottom="20px" gridTemplateColumns="1fr 1fr">
            <LabelText label="Nazwa firmy">{data.company.name}</LabelText>
            <LabelText label="NIP">{data.number}</LabelText>
          </Grid>
          <LabelText label="Adress">
            {data.company.address}, {data.company.postCode} {data.company.city}
          </LabelText>
        </Box>
      )}
    </>
  )
}

const Lead = () => {
  const history = useHistory()
  let { id } = useParams()
  const { leadStatuses } = useProfileOptions()

  const [activeTab, setActiveTab] = useState("localization")
  const [data, setData] = useState()
  const [comments, setComments] = useState([])
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState()

  const fetchLead = useCallback(async () => {
    try {
      const lead = await LeadService.getLead(id)
      setData(lead)
      setStatus(lead.status)
      setComments(lead.comments)
      if (!lead.location) {
        setActiveTab("comments")
      }
    } catch (e) {
      AnalyticsService.error("[ERROR getLead API]:", e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchLead()
  }, [])

  useEffect(() => {
    if (data) {
      AnalyticsService.track("Lead Visit")
    }
  }, [data])

  const handleUpdateStatus = useCallback(
    e => {
      try {
        LeadService.updateStatus(data?.id, e.target.value)
        AnalyticsService.track("Lead Status Change")
        setStatus(e.target.value)
      } catch (e) {
        AnalyticsService.error("[ERROR updateStatus API]:", e)
      }
    },
    [data],
  )

  return (
    <Modal type="side" maxWidth="600px" show={true} setShow={() => history.push("/panel/leads")}>
      <>
        {loading && <SpinnerBlock height="100%" />}
        {!loading && data && (
          <Content>
            <Row margin="0 0 30px" alignItems="flex-end" justifyContent="space-between">
              <Row alignItems="center">
                <Icon
                  size="20px"
                  margin="0 10px 0 0"
                  icon={data.type === "customer" ? faUser : faBuilding}
                />
                <Title margin="0">{data.name}</Title>
              </Row>
              <Label>{format(new Date(data.dtCreated), "dd/MM/yyyy HH:mm")}</Label>
            </Row>
            <Box marginBottom="20px">
              <Label margin="0 0 5px">Status</Label>
              <LeadStatusSelect
                value={status}
                options={[{ id: "new", name: "Nowy" }, ...leadStatuses]}
                onChange={handleUpdateStatus}
              />
            </Box>
            <LeadBasicInfo data={data} />
            {data.location && (
              <Dropdown
                active={activeTab}
                setActive={setActiveTab}
                slug="localization"
                title="Lokalizacja"
              >
                <Map lat={data.location.latitude} long={data.location.longitude} />
              </Dropdown>
            )}
            <Dropdown
              active={activeTab}
              setActive={setActiveTab}
              slug="comments"
              title="Komentarze"
            >
              <LeadComments data={data} comments={comments} setComments={setComments} />
            </Dropdown>
          </Content>
        )}
      </>
    </Modal>
  )
}

export default Lead

const DropdownWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.fourth};
  padding: 10px 20px;
  margin-left: -20px;
  width: calc(100% + 40px);

  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.color.fourth};
  }
`

const Content = styled.div``
