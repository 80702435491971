import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import Button from "components/atoms/Button"
import Input from "components/atoms/Input"
import Modal from "components/atoms/Modal"
import { AnalyticsService } from "../../../../../../services/AnalyticsService"

const EditStatusModal = ({ item, show, setShow, onEdit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: item.name,
    },
  })

  const onSubmit = useCallback(
    async el => {
      onEdit({
        ...item,
        ...el,
      })
      AnalyticsService.track("Profile Lead Status Edit")
      setShow(false)
    },
    [item, onEdit],
  )

  return (
    <Modal show={show} setShow={setShow} title="Edytuj status" maxWidth="400px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          label="Name"
          name="name"
          error={errors.name}
          margin="0 0 10px"
          rhf={register("name", { required: true })}
        />
        <Button submit margin="0px">
          Edytuj
        </Button>
      </form>
    </Modal>
  )
}

export default EditStatusModal
