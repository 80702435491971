import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import AuthView from "../../../views/Auth"
import Alert from "../../../components/atoms/Alert"
import { logout, registerUser, updateUser } from "../../../redux/actions"
import { Title } from "components/atoms/Title"
import { P } from "components/atoms/P"
import Button from "components/atoms/Button"
import { useAuth } from "../../../hooks/useAuth"
import LabelText from "../../../components/atoms/LabelText"
import { Box } from "../../../components/atoms"
import { Grid } from "../../../components/atoms/_atoms"
import { useForm } from "react-hook-form"
import Checkbox from "../../../components/atoms/Checkbox"
import { AnalyticsService } from "../../../services/AnalyticsService"
import { ProfileService, UserService } from "../../../services"
import { updateProfile } from "../../../redux/reducers/profile"
import { useProfile } from "../../../hooks/useProfile"

function RegisterAcceptLegal() {
  const dispatch = useDispatch()

  const { user } = useAuth()
  const profile = useProfile()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [elRefs, setElRefs] = useState([])

  const onSubmit = useCallback(
    async state => {
      setLoading(true)
      if (!profile.params?.useOtomotoScrapping) {
        try {
          const data = await ProfileService.acceptLegal()
          AnalyticsService.track("Register Accept Legal")
          dispatch(updateUser(data))
        } catch (e) {
          AnalyticsService.error("[ERROR acceptLegal API]:", e)
        } finally {
          setLoading(false)
        }
      } else {
        try {
          const data = await ProfileService.submitIntegrations()
          AnalyticsService.track("Register Submit Integrations")
          dispatch(updateUser(data))
        } catch (e) {
          AnalyticsService.error("[ERROR submitIntegrations API]:", e)
        } finally {
          setLoading(false)
        }
      }
    },
    [profile.params],
  )

  return (
    <AuthView
      maxWidth="750px"
      footerChildren={
        <Button type="third" margin="0 auto" onClick={() => dispatch(logout())}>
          Wyloguj się
        </Button>
      }
    >
      <Box minWidth="450px">
        <Title>Aktywuj swój profil</Title>
        <P margin="0 0 25px">
          Zweryfikuj poniższe informacje oraz zaakceptuj niezbędne informacje.
        </P>
        {error && <Alert>Twój kod, który podałeś jest nieprawidłowy.</Alert>}
        <Title type="secondary" margin="30px 0 10px">
          {profile.name}
        </Title>
        <Grid gridTemplateColumns="1fr 1fr" gridGap="10px" marginBottom="10px">
          <LabelText label="Nazwa firmy">{profile.company.name}</LabelText>
          <LabelText label="NIP">{profile.company.nip}</LabelText>
        </Grid>
        <Grid gridTemplateColumns="1fr 1fr 1fr" marginBottom="30px">
          <LabelText label="Adres">{profile.company.address}</LabelText>
          <LabelText label="Kod pocztowy">{profile.company.postCode}</LabelText>
          <LabelText label="Miasto">{profile.company.city}</LabelText>
        </Grid>
        <Checkbox
          margin="0 0 20px"
          label={`Przeczytałem i akceptuję`}
          error={errors.checkbox}
          rhf={register("checkbox", {
            required: true,
          })}
        >
          Przeczytałem i akceptuję{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://motorata.pl/regulamin-dla-kont-biznesowych"
          >
            Regulamin
          </a>
          {", "}
          <a target="_blank" rel="noreferrer" href="https://motorata.pl/polityka-prywatnosci">
            Politykę prywatności
          </a>
          {" oraz "}
          <a target="_blank" rel="noreferrer" href="https://motorata.pl/rodo">
            Informacje o ochronie danych osobowych
          </a>
          .
        </Checkbox>
        <Button submit onClick={handleSubmit(onSubmit)} loading={loading}>
          Aktywuj profil
        </Button>
      </Box>
    </AuthView>
  )
}

RegisterAcceptLegal.propTypes = {}

export default RegisterAcceptLegal
