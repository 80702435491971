import React from 'react';
import styled from 'styled-components';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { pl } from "date-fns/locale";

const MonthPicker = ({ label, error, value, onChange, rhf }) => {
  const { ref, ...rest } = rhf

  return (
    <Wrapper>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pl}>
        <DatePicker
          inputRef={ref}
          disableToolbar
          variant="inline"
          openTo="year"
          format="MM/yyyy"
          views={["year", "month"]}
          label={label}
          error={error}
          {...rest}
          value={value}
          onChange={onChange}
        />
      </MuiPickersUtilsProvider>
    </Wrapper>
  );
};

export default MonthPicker;

const Wrapper = styled.div`
  >*{
    width: 100%;
  }
  .MuiFormControl-root{
    background: ${({ theme }) => theme.color.background};
    border-radius: 3px;
    overflow: hidden;
    
    .MuiInputBase-root{
      padding: 24px 20px 7px;
      margin: 0px;
      
      .MuiInputBase-input{
        padding: 0px;
      }
    }

    .MuiInputLabel-formControl{
      top: 7px;
      left: 20px;
    }
    
    .MuiInput-underline:before, .MuiInput-underline:after{
      display: none !important;
    }
  }
  `;