import styled from "styled-components"
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  opacity,
  position,
  size,
  space,
  textAlign,
  textStyle,
} from "styled-system"

export const common = [
  background,
  space,
  flexbox,
  border,
  color,
  position,
  layout,
  size,
  opacity,
  textAlign,
  textStyle,
  grid,
]

export const Box = styled.div`
  ${common};
  background: ${({ theme, background }) => theme.color[background] ?? background};
  position: relative;
`

export const Column = styled.div`
  ${common};
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  ${common};
  display: flex;
  background: ${({ theme, background }) => theme.color[background] ?? background};
`

export const Grid = styled.div`
  ${common};
  display: grid;
`

export const Absolute = styled(Box)`
  position: absolute;
`
