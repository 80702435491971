import React from 'react';
import styled from 'styled-components';
import { P } from 'components/atoms/P';
import { Title } from 'components/atoms/Title';

const SettingsBlock = ({ title, text, children }) => {

  return (
    <Wrapper>
      <div>
        <Title type="secondary">{title}</Title>
        <P>{text}</P>
      </div>
      <div>
        {children}
      </div>
    </Wrapper>
  );
};

export default SettingsBlock;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  grid-gap: 40px;
  padding: 20px 0 ;
  border-top: 1px solid ${({ theme }) => theme.color.fourth};
  width: 100%;
  
  >div:nth-child(2){
    width: 100%;
    max-width: 550px;
    
    >div{
      margin-bottom: 10px;
    }
  }
  `;
