import { APIService } from "./APIService"

const getOffers = (page = 0) => APIService.get(`/profiles/:profileId/offers?page=${page}&size=10`)

const addOffer = offer => APIService.post(`/profiles/:profileId/offers`, offer)

const editOffer = (id, offer) => APIService.put(`/profiles/:profileId/offers/${id}`, offer)
const extendOffer = id => APIService.put(`/profiles/:profileId/offers/${id}/extend`)

const deactivateOffer = id => APIService.put(`/profiles/:profileId/offers/${id}/deactivate`)

const updateIsAvailableOffer = (id, isAvailable) =>
  APIService.put(`/profiles/:profileId/offers/${id}/is-available?isAvailable=${isAvailable}`)

const deleteOffer = id => APIService.delete(`/profiles/:profileId/offers/${id}`)

export const OffersService = {
  getOffers,
  addOffer,
  editOffer,
  extendOffer,
  deleteOffer,
  deactivateOffer,
  updateIsAvailableOffer,
}
