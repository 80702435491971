import React, { createRef, useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import AuthView from "../../../views/Auth"
import { UserService } from "../../../services"
import Alert from "../../../components/atoms/Alert"
import { logout, updateUser } from "../../../redux/actions"
import { Title } from "components/atoms/Title"
import { P } from "components/atoms/P"
import Button from "components/atoms/Button"
import { testRegex } from "theme/functions"
import { IS_ONE_DIGIT } from "theme/Regex"
import { useAuth } from "../../../hooks/useAuth"
import { AnalyticsService } from "../../../services/AnalyticsService"
import { CONFIRM_CODE_REGEX } from "../../../utils/regexHelpers"

function RegisterConfirmEmail() {
  const arrLength = 6
  const dispatch = useDispatch()

  const { user } = useAuth()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [elRefs, setElRefs] = useState([])

  useEffect(() => {
    // add or remove refs
    const array = Array(arrLength)
      .fill()
      .map((_, i) => createRef())
    setElRefs(array)
  }, [arrLength])

  useEffect(() => {
    if (elRefs[0]) {
      elRefs[0].current.focus()
    }
  }, [elRefs])

  const [state, setState] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
  })

  const onChange = (e, number) => {
    const value = e.target.value
    if (value.length === 1 && testRegex(value, IS_ONE_DIGIT)) {
      setState({
        ...state,
        [`${number}`]: value,
      })
      if (number < 6) {
        elRefs[number].current.focus()
      }
    } else if (value.length === 0) {
      setState({
        ...state,
        [`${number}`]: "",
      })
    } else if (value.length === 6 && testRegex(value, CONFIRM_CODE_REGEX)) {
      setState({
        1: value[0],
        2: value[1],
        3: value[2],
        4: value[3],
        5: value[4],
        6: value[5],
      })
    }
  }

  const handleResendCode = useCallback(async () => {
    try {
      await UserService.resendCode()
      AnalyticsService.track("Register Email Resend")
      window.alert("Kod został wysłany ponownie!")
    } catch (e) {
      AnalyticsService.error("[ERROR resendCode API]:", e)
    }
  }, [])

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true)
      const data = await UserService.confirmEmail(
        `${state[1]}${state[2]}${state[3]}${state[4]}${state[5]}${state[6]}`,
      )
      AnalyticsService.track("Register Email Confirm")
      dispatch(updateUser(data))
    } catch (e) {
      setError(true)
      AnalyticsService.error("[ERROR confirmEmail API]:", e)
    } finally {
      setLoading(false)
    }
  }, [state])

  return (
    <AuthView
      footerChildren={
        <Button type="third" margin="0 auto" onClick={() => dispatch(logout())}>
          Wyloguj się
        </Button>
      }
    >
      <Title>Potwierdz adres email</Title>
      <P margin="0 0 25px">
        Na twój email <span>{user.username}</span> wysłaliśmy kod weryfikacyjny.
      </P>
      {error && <Alert>Twój kod, który podałeś jest nieprawidłowy.</Alert>}
      <CodeWrapper>
        <input ref={elRefs[0]} value={state["1"]} onChange={e => onChange(e, 1)} />
        <input ref={elRefs[1]} value={state["2"]} onChange={e => onChange(e, 2)} />
        <input ref={elRefs[2]} value={state["3"]} onChange={e => onChange(e, 3)} />
        <input ref={elRefs[3]} value={state["4"]} onChange={e => onChange(e, 4)} />
        <input ref={elRefs[4]} value={state["5"]} onChange={e => onChange(e, 5)} />
        <input ref={elRefs[5]} value={state["6"]} onChange={e => onChange(e, 6)} />
      </CodeWrapper>
      <Button
        disabled={!state[1] || !state[2] || !state[3] || !state[4] || !state[5] || !state[6]}
        loading={loading}
        onClick={handleSubmit}
      >
        Potwierdz email
      </Button>
      <P small margin="20px 0">
        Jeśli nie otrzymałeś emaila <button onClick={handleResendCode}>wyślij kod ponownie</button>?
      </P>
    </AuthView>
  )
}

RegisterConfirmEmail.propTypes = {}

export default RegisterConfirmEmail

const CodeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  input {
    background: ${({ theme }) => theme.color.background};
    color: ${({ theme }) => theme.color.secondary};
    font-weight: ${({ theme }) => theme.font.normal};
    width: 40px;
    height: 65px;
    border-radius: 3px;
    border: none;
    margin: 0 5px;
    text-align: center;
    font-size: 30px;
  }
`
