import React, { useCallback, useEffect } from "react"
import AuthView from "../../../views/Auth"
import { Title } from "../../../components/atoms/Title"
import { P } from "../../../components/atoms/P"
import { useAuth } from "../../../hooks/useAuth"
import Button from "../../../components/atoms/Button"
import { logout, updateUser } from "../../../redux/actions"
import { useDispatch } from "react-redux"
import { UserService } from "../../../services"

function RegisterPendingAccount() {
  const dispatch = useDispatch()

  const { user } = useAuth()

  const fetchStatus = useCallback(async () => {
    try {
      const res = await UserService.getStatus()
      console.log(res)
      if (res?.status === "active") {
        if (res?.redirect) {
          window.location.replace(res?.redirect)
        } else {
          window.location.replace("http://localhost:3000/")
        }
      }
    } catch (e) {
      console.log("[ERROR getStatus API]", e)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchStatus()
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  return (
    <AuthView>
      <Title>Weryfikacja konta</Title>
      <P margin="0 0 25px">
        Twoje konto czeka na weryfikację danych przez naszych specjalistów. Powiadomimy Cię o
        aktywacji konta za pośrednictwem wiadomości e-mail na adres <span>{user.username}</span>.
      </P>
      <Button type="secondary" onClick={() => dispatch(logout())}>
        Wyloguj się
      </Button>
    </AuthView>
  )
}

export default RegisterPendingAccount
