import React, { useCallback, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { Title } from "../../../../components/atoms/Title"
import { useProfileOptions } from "../../../../hooks/useProfile"
import Button from "../../../../components/atoms/Button"
import { Box } from "../../../../components/atoms/_atoms"
import { OffersService } from "../../../../services"
import { LeadService } from "../../../../services/LeadService"
import Table, { Row } from "../../../../components/Table"
import Tag from "../../../../components/atoms/Tag"
import { TRANSLATE } from "../../../../utils/translator"
import { Absolute, Column } from "../../../../components/atoms"
import { Row as FlexRow } from "../../../../theme/styledComponents"
import Icon from "../../../../components/atoms/Icon"
import {
  faBuilding,
  faExclamationTriangle,
  faEye,
  faGlobeEurope,
  faPen,
  faPlusCircle,
  faTrash,
  faUser,
} from "@fortawesome/free-solid-svg-icons"
import { MOTORATA_URL } from "../../../../theme/constans"
import EmptyBlock from "../../../../components/blocks/EmptyBlock"
import { format } from "date-fns"
import { NavLink, Route } from "react-router-dom"
import Lead from "./Lead"
import LeadStatusSelect from "./components/LeadStatusSelect"
import ToolTip from "../../../../components/atoms/Tooltip"
import { AnalyticsService } from "../../../../services/AnalyticsService"

const LeadsHeader = ({ type, setType, handleRefresh }) => {
  const { leadStatuses } = useProfileOptions()

  const handleOnChange = useCallback(
    e => {
      handleRefresh()
      setType(e)
    },
    [handleRefresh],
  )

  return (
    <LeadsHeaderWrapper className="hide_scroll">
      <StyledAll marginRight="10px">
        <ButtonOption
          active={type === "all"}
          margin="0 10px 0 0"
          onClick={() => handleOnChange("all")}
        >
          Wszystkie
        </ButtonOption>
      </StyledAll>
      <ButtonOption
        active={type === "new"}
        margin="0 10px 0 0"
        onClick={() => handleOnChange("new")}
      >
        Nowe
      </ButtonOption>
      {leadStatuses.map(el => (
        <ButtonOption
          key={el.id}
          active={type === el.id}
          margin="0 10px 0 0"
          onClick={() => handleOnChange(el.id)}
        >
          {el.name}
        </ButtonOption>
      ))}
    </LeadsHeaderWrapper>
  )
}

const LeadRow = ({ item }) => {
  const [status, setStatus] = useState(item.status)
  const { leadStatuses } = useProfileOptions()

  const handleUpdateStatus = useCallback(e => {
    try {
      LeadService.updateStatus(item.id, e.target.value)
      AnalyticsService.track("Lead Status Change List")
      setStatus(e.target.value)
    } catch (e) {
      AnalyticsService.error("[ERROR updateStatus API]:", e)
    }
  }, [])

  return (
    <Row
      key={item.id}
      data={[
        <LeadStatusSelect
          key={`status-${item.id}`}
          value={status}
          options={[{ id: "new", name: "Nowy" }, ...leadStatuses]}
          onChange={handleUpdateStatus}
        />,
        <FlexRow jc="flex-start" className="bold" flexWrap="no-wrap" key={`name-${item.id}`}>
          <Icon
            size="12px"
            margin="0 10px 0 0"
            icon={item.type === "customer" ? faUser : faBuilding}
          />
          {item.name}
        </FlexRow>,
        <a key={`email-${item.id}`} href={`mailto:${item.email}`}>
          {item.email}
        </a>,
        <>
          {!item.phoneVerified ? (
            <ToolTip key={`phone-${item.id}`} text="Niezweryfikowany numer telefonu">
              <FlexRow jc="flex-start" className="bold" flexWrap="no-wrap">
                <Icon
                  size="12px"
                  color="warning"
                  margin="0 10px 0 0"
                  icon={faExclamationTriangle}
                />
                {item.phone}
              </FlexRow>
            </ToolTip>
          ) : (
            <>{item.phone}</>
          )}
        </>,
        item.number ?? "-",
        format(new Date(item.dtCreated), "dd/MM/yyyy HH:mm"),
        <NavLink key={`link-${item.id}`} to={`/panel/leads/${item.id}`}>
          <Icon cursor="pointer" margin="0 10px" icon={faEye} />
        </NavLink>,
      ]}
    />
  )
}

const Leads = () => {
  const [type, setType] = useState("new")

  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(
    page => {
      return LeadService.getLeads(type, page)
    },
    [type],
  )

  const handleRefresh = useCallback(() => {
    setRefresh(!refresh)
  }, [refresh])

  const renderEmptyComponent = useCallback(() => {
    return (
      <Column padding="60px 30px" jc="center" ai="center">
        <EmptyBlock text="Brak zapytań w bazie danych" />
      </Column>
    )
  }, [])

  const renderItem = useCallback((lead, data, setData) => {
    return <LeadRow key={`${lead.id}-${lead.status}`} item={lead} data={data} setData={setData} />
  }, [])

  return (
    <>
      <Wrapper>
        <Title margin="0 0 25px">Wszystkie leady</Title>
        <TableWrapper>
          <LeadsHeader type={type} setType={setType} handleRefresh={handleRefresh} />
          <Table
            getData={fetchData}
            columns={["Status", "Nazwa", "Email", "Telefon", "PSESL/NIP", "Data", ""]}
            refresh={refresh}
            rows={renderItem}
            emptyComponent={renderEmptyComponent}
          />
        </TableWrapper>
      </Wrapper>
      <Route path="/panel/leads/:id">
        <Lead />
      </Route>
    </>
  )
}

export default Leads

const ButtonOption = styled.div`
  font-size: ${({ theme }) => theme.font.S};
  color: ${({ theme }) => theme.color.secondary};
  font-weight: ${({ theme }) => theme.font.normal};
  margin: ${({ margin }) => margin || "0px"};
  padding: 10px 25px;
  border-radius: 3px;
  cursor: pointer;
  background: ${({ theme }) => theme.color.background};

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.color.primary};
      color: ${({ theme }) => theme.color.white};
    `}
`

const StyledAll = styled.div`
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid ${({ theme }) => theme.color.fourth};
`

const LeadsHeaderWrapper = styled.div`
  padding: 10px;
  padding-bottom: 0px;
  background: white;
  border-radius: 5px;
  display: flex;
  overflow: scroll;

  * {
    min-width: max-content;
  }
`

const TableWrapper = styled.div`
  background: white;
  border-radius: 5px;
  overflow: hidden;
`

const Wrapper = styled.div`
  padding: 40px;
`
