import React, { useEffect, useReducer, useState } from "react"
import SCStyled from "styled-components"
import { styled } from "@mui/material/styles"
import TextField from "@mui/material/TextField"
import { theme } from "theme/theme"
import Spinner from "./Spinner"
import { InputAdornment } from "@material-ui/core"

const StyledTextField = styled(TextField)(() => {
  return {
    "width": "100%",
    "background": "none",
    ".MuiInputLabel-root": {
      "background": "none",
      "color": theme.color.secondary,
      "fontSize": theme.font.M,
      "fontWeight": theme.font.normal,
      "lineHeight": theme.font.M,
      "transform": "translate(20px,22px) scale(1)",

      "&.MuiInputLabel-shrink": {
        transform: "translate(20px, 13px) scale(0.9)",
        color: theme.color.secondary,
        fontSize: theme.font.S,
        lineHeight: theme.font.S,
      },
    },
    ".MuiFilledInput-root": {
      "min-height": "58px",
      "background": "none",
      "padding": "0",

      "&:before, &:after": {
        display: "none",
      },

      "input, textarea": {
        "box-sizing": "border-box",
        "color": theme.color.secondary,
        "fontSize": theme.font.L,
        "lineHeight": theme.font.L,
        "fontWeight": theme.font.light,
        "min-height": "58px !important",
        "padding-left": "20px",
        "padding-top": "31px",
        "padding-bottom": "11px",
      },
    },
    ".Mui-error": {
      color: theme.color.red,
    },
    ".MuiInputAdornment-root": {
      "padding-left": "20px",
      "padding-top": "31px",
      "padding-bottom": "11px",
      "margin-right": "-15px",
    },
    ".Mui-disabled": {
      "opacity": 0.6,
      "*": {
        cursor: "not-allowed",
      },
    },
  }
})

const Input = ({
  label,
  margin,
  background = "background",
  password,
  error,
  loading,
  startAdornment,
  endAdornment,
  rhf = {},
  ...rest
}) => {
  const { ref, onChange = {}, ...rhfRest } = rhf

  return (
    <StyledBox width="100%" margin={margin} background={background}>
      <StyledTextField
        {...rhfRest}
        {...rest}
        id="filled-basic"
        label={label}
        error={!!error}
        variant="filled"
        inputRef={ref}
        onChange={onChange}
        type={password ? "password" : "text"}
        helperText={
          error?.type === "required"
            ? "To pole jest wymagane"
            : error
            ? error.message ?? "Niepoprawny format"
            : ""
        }
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : null,
          endAdornment: endAdornment ? (
            <InputAdornment position="start">{endAdornment}</InputAdornment>
          ) : null,
        }}
      />
      {loading && (
        <SpinnerWrapper>
          <Spinner size={20} borderWidth={3} />
        </SpinnerWrapper>
      )}
    </StyledBox>
  )
}

export default Input

const SpinnerWrapper = SCStyled.div`
    position: absolute;
    right: 15px;
    top: 21px;
`

const StyledBox = SCStyled.div`
  width: 100%;
  margin: ${({ margin }) => margin};
  position: relative;
  
  .MuiFilledInput-root{
    border-radius: 3px;
    overflow: hidden;
    background: ${({ theme, background }) => theme.color[background]} !important;
  }
  
   &.Mui-error {
      color: #f8563f !important;
    }
  `
