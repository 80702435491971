import { createSlice, createAction } from "@reduxjs/toolkit"

const profile = createSlice({
  name: "profile",
  initialState: {
    name: "cdede",
  },
  reducers: {
    setProfile(state, action) {
      return action.payload
    },
    updateProfile(state, action) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateProfileOptions(state, action) {
      state.options = action.payload
    },
    addIntegration(state, action) {
      state.integrations = {
        ...state.integrations,
        ...action.payload,
      }
    },
    updateIntegration(state, action) {
      state.integrations = {
        ...state.integrations,
        ...action.payload,
      }
    },
    deleteIntegration(state, action) {
      state.integrations = {
        ...state.integrations,
        [action.payload]: {
          active: false,
        },
      }
    },
    setNotifications(state, action) {
      state.notifications = action.payload
    },
  },
})

export const {
  setProfile,
  updateProfile,
  updateProfileOptions,
  addIntegration,
  updateIntegration,
  deleteIntegration,
  setNotifications,
} = profile.actions

export default profile.reducer
