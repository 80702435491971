import { BRAND_ARRAY, MODEL_ARRAY } from "../theme/data"
import { TRANSLATE } from "./translator"

export const mentions = [
  {
    name: "Marka",
    slug: "brand",
    replaceValue: offer => {
      const brand = BRAND_ARRAY.find(brand => brand[1] === offer["1"]?.brand)
      if (brand) return brand[0]
      return ""
    },
  },
  {
    name: "Model",
    slug: "model",
    replaceValue: offer => {
      const model = MODEL_ARRAY[offer["1"]?.brand]?.find(model => model[1] === offer["1"]?.model)
      if (model) return model[0]
      return ""
    },
  },
  {
    name: "Rok produkcji",
    slug: "yaer",
    replaceValue: offer => offer["1"]?.year ?? "",
  },
  {
    name: "Pojemność silnika",
    slug: "engineCapacity",
    replaceValue: offer => (offer["1"]?.engineCapacity ? `${offer["1"]?.engineCapacity} cm3` : ""),
  },
  {
    name: "Moc slinika",
    slug: "enginePower",
    replaceValue: offer => (offer["1"]?.enginePower ? `${offer["1"]?.enginePower} KM` : ""),
  },
  {
    name: "Nadwozie",
    slug: "bodyType",
    replaceValue: offer => TRANSLATE[offer["1"]?.bodyType] ?? "",
  },
  {
    name: "Rodzaj paliwa",
    slug: "fuelType",
    replaceValue: offer => TRANSLATE[offer["1"]?.fuelType] ?? "",
  },
  {
    name: "Rodzaj skrzyni biegów",
    slug: "gearBoxType",
    replaceValue: offer => TRANSLATE[offer["1"]?.gearBox] ?? "",
  },
  {
    name: "Wersja",
    slug: "version",
    replaceValue: offer => (offer["1"]?.version ? `${offer["1"].version}` : ""),
  },
  {
    name: "Stan",
    slug: "state",
    replaceValue: offer => TRANSLATE[offer["2"]?.state] ?? "",
  },
  {
    name: "Przebieg",
    slug: "course",
    replaceValue: offer => offer["1"]?.course ?? "0",
  },
  {
    name: "Kolor",
    slug: "colorName",
    replaceValue: offer => TRANSLATE[offer["2"]?.color] ?? "",
  },
  {
    name: "Typ koloru",
    slug: "colorType",
    replaceValue: offer => TRANSLATE[offer["2"]?.colorType] ?? "",
  },
  {
    name: "VIN",
    slug: "vin",
    replaceValue: offer => offer["2"]?.vin ?? "",
  },
  {
    name: "Data odbioru",
    slug: "returnDate",
    replaceValue: offer => "DATA ODBIORU",
  },
  {
    name: "Kwota finansowania",
    slug: "price",
    replaceValue: offer => offer["3"]?.price ?? "",
  },
  {
    name: "Kwota pokazowa",
    slug: "showPrice",
    replaceValue: offer => offer["3"]?.showPrice ?? offer["3"]?.price ?? "",
  },
  {
    name: "Nazwa salonu",
    slug: "salonName",
    replaceValue: (_, profile) => profile?.name ?? "",
  },
  {
    name: "Adres salonu",
    slug: "salonAddress",
    replaceValue: (_, profile) =>
      `${profile?.address?.city}, ${profile?.address?.postCode} ${profile?.address?.city}`,
  },
  {
    name: "Telefon kontaktowy do salonu",
    slug: "salonPhone",
    replaceValue: (_, profile) => profile?.phone ?? "",
  },
]

export const replaceStringWithValues = (str, offer, profile) => {
  let returnStr = str
  mentions.map(mention => {
    console.log("returnStr", returnStr)
    returnStr = returnStr.replace(`@${mention.slug}`, mention.replaceValue(offer, profile))
  })

  return returnStr
}
