import React, { useCallback, useState } from "react"
import { faPen } from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ImageModal from "components/ImageModal"
import Image from "../../../../../components/atoms/Image"

const HoverEdit = ({ onClick }) => {
  return (
    <HoverWrapper onClick={onClick}>
      <FontAwesomeIcon icon={faPen} />
    </HoverWrapper>
  )
}

const ProfileImages = ({ images, setImages }) => {
  const [modal, setModal] = useState()

  const handleChangeBackground = useCallback(
    file => {
      setImages({
        ...images,
        background: file,
      })
    },
    [images],
  )

  const handleChangeProfile = useCallback(
    file => {
      setImages({
        ...images,
        profile: file,
      })
    },
    [images],
  )

  return (
    <>
      <Wrapper>
        <BackgroundWrapper>
          <ImageWrapper>
            {images.background && <Image src={images.background.src} width="1250" />}
          </ImageWrapper>
          <HoverEdit onClick={() => setModal("background")} />
        </BackgroundWrapper>
        <ProfileImageWrapper>
          <ProfileImageContent>
            <ImageWrapper objectFit="contain">
              {images.profile && <Image src={images.profile.src} width="250" />}
            </ImageWrapper>
            <HoverEdit onClick={() => setModal("profile")} />
          </ProfileImageContent>
        </ProfileImageWrapper>
      </Wrapper>
      <ImageModal
        title="Zdjęcie w tle"
        description="Dodaj nowe zdjęcie w tle"
        show={modal === "background"}
        setShow={setModal}
        onChange={handleChangeBackground}
      />
      <ImageModal
        title="Zdjęcie profilowe"
        description="Dodaj nowe zdjęcie profilowe"
        show={modal === "profile"}
        setShow={setModal}
        onChange={handleChangeProfile}
      />
    </>
  )
}

export default ProfileImages

const HoverWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: background 0.3s, opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;

  &:hover {
    opacity: 1;
    background: ${({ theme }) => theme.color.primaryBackground};
  }
`

const ProfileImageContent = styled.div`
  background: white;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
`

const ProfileImageWrapper = styled.div`
  margin: 0 auto;
  width: 150px;
  height: 150px;
  background: ${({ theme }) => theme.color.background};
  margin-top: -35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: ${({ objectFit }) => objectFit || "cover"};
  }
`

const BackgroundWrapper = styled.div`
  width: 100%;
  background: white;
  padding-bottom: 30%;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
`

const Wrapper = styled.div`
  position: relative;
`
