import { useDispatch } from "react-redux"
import { useIntegration } from "../../../../../../hooks/useIntegration"
import React, { useCallback, useState } from "react"
import { ProfileService } from "../../../../../../services"
import { Box } from "../../../../../../components/atoms"
import SuggestionsTextarea from "../../../../../../components/atoms/SuggestionsTextarea"
import { Title } from "../../../../../../components/atoms/Title"
import Button from "../../../../../../components/atoms/Button"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { updateIntegration } from "redux/reducers/profile"
import { AnalyticsService } from "../../../../../../services/AnalyticsService"

export const OtomotoEditModal = ({ show, setShow }) => {
  const dispatch = useDispatch()
  const { params: initialParams = {} } = useIntegration("otomoto")

  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({
    title: initialParams.title,
    description: initialParams.description,
  })

  const handleOnChange = useCallback(
    (name, value) => {
      setParams({
        ...params,
        [name]: value,
      })
    },
    [params],
  )

  const handleSave = useCallback(async () => {
    try {
      setLoading(true)
      const res = await ProfileService.updateIntegration("otomoto", {
        title: params.title,
        description: params.description,
      })
      dispatch(updateIntegration(res))
    } catch (err) {
      AnalyticsService.error("[ERROR updateIntegration API]", err)
    } finally {
      setLoading(false)
    }
  }, [params])

  return (
    <div>
      <Box marginBottom="20px">
        <SuggestionsTextarea
          marginBottom="10px"
          label="Domyślny tytuł ogłoszenia"
          value={params.title?.raw}
          onChange={value => handleOnChange("title", value)}
          multiline={false}
        />
        <SuggestionsTextarea
          label="Domyślny opis ogłoszenia"
          value={params.description?.raw}
          onChange={value => handleOnChange("description", value)}
        />
      </Box>
      <Button margin="25px 0 0" onClick={handleSave} loading={loading} icon={faSave}>
        Zapisz
      </Button>
    </div>
  )
}

export default OtomotoEditModal
