import { APIService } from "./APIService"

const getLeads = (page = 0) => APIService.get(`/admin/leads?page=${page}&size=20`)

const getLead = id => APIService.get(`/admin/leads/${id}`)

const addLeadComment = (id, body) => APIService.post(`/admin/leads/${id}/comments`, body)

export const AdminService = {
  getLead,
  getLeads,
  addLeadComment,
}
