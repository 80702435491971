import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faAddressBook,
  faCarAlt,
  faCoins,
  faPlug,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons"
import { Redirect, Route, Switch } from "react-router-dom"
import Button from "../../../../components/atoms/Button"
import Profile from "./Profile/Profile"
import Integrations from "./Integrations/Integrations"
import OffersSettings from "pages/auth/Panel/Settings/OffersSettings/OffersSettings"
import LeadsSettings from "./LeadsSettings/LeadsSettings"
import { useProfile } from "../../../../hooks/useProfile"

const Settings = () => {
  const { type } = useProfile()

  return (
    <>
      <Wrapper>
        <Navigation>
          {/*<Button to="/panel/settings/account" type="secondary" justifyContent="flex-start" margin="0 0 5px"><FontAwesomeIcon icon={faIdCard}/>Twóje konto</Button>*/}
          <Button
            to="/panel/settings/profile"
            type="secondary"
            justifyContent="flex-start"
            margin="0 0 5px"
          >
            <FontAwesomeIcon icon={faUserAlt} />
            Twój profil
          </Button>
          <Button
            to="/panel/settings/offers"
            type="secondary"
            justifyContent="flex-start"
            margin="0 0 5px"
          >
            <FontAwesomeIcon icon={faCarAlt} />
            Ustawienia ogłoszeń
          </Button>
          {type === "owner" && (
            <Button
              to="/panel/settings/leads"
              type="secondary"
              justifyContent="flex-start"
              margin="0 0 5px"
            >
              <FontAwesomeIcon icon={faAddressBook} />
              Ustawienia zapytań
            </Button>
          )}
          <Button
            to="/panel/settings/integrations"
            type="secondary"
            justifyContent="flex-start"
            margin="0 0 5px"
          >
            <FontAwesomeIcon icon={faPlug} />
            Integracje
          </Button>
          {/*<Button to="/panel/settings/developer" type="secondary" justifyContent="flex-start" margin="0 0 5px"><FontAwesomeIcon icon={faFileCode}/>Developer</Button>*/}
        </Navigation>
        <Content className="overflow-y">
          <Route path="/panel/settings/profile">
            <Profile />
          </Route>
          <Route path="/panel/settings/offers">
            <OffersSettings />
          </Route>
          {type === "owner" && (
            <Route path="/panel/settings/leads">
              <LeadsSettings />
            </Route>
          )}
          <Route path="/panel/settings/integrations">
            <Integrations />
          </Route>
        </Content>
      </Wrapper>
    </>
  )
}

export default Settings

const Content = styled.div`
  height: 100%;
  padding: 40px;
  padding-left: 60px;
`

const Navigation = styled.div`
  background: white;
  height: calc(100% - 40px);
  transform: translateY(20px) translateX(20px);
  border-radius: 3px;
  padding: 15px;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  height: 100%;
`
