import React, { useCallback, useState } from "react"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"
import { Box } from "components/atoms/_atoms"
import Spinner from "components/atoms/Spinner"
import { FileService } from "services/FileService"
import { Title } from "components/atoms/Title"
import Modal from "components/atoms/Modal"
import { maxSize } from "utils/fileHelper"
import { AnalyticsService } from "../services/AnalyticsService"
import { P } from "./atoms/P"

const ImageModal = ({ title, description, show, setShow, onChange }) => {
  const [loading, setLoading] = useState(false)

  const { acceptedFiles, getRootProps, getInputProps, isDragAccept } = useDropzone({
    accept: "image/jpeg, image/png, image/webp",
    maxFiles: 1,
    onDrop: async acceptedFiles => {
      const file = acceptedFiles[0]

      if (maxSize(file)) {
        alert("Rozmiar pliku nie może być większy niż 5MB")
        return
      }

      try {
        setLoading(true)
        const res = await FileService.uploadFile(file)
        onChange(res)
        setShow(false)
      } catch (e) {
        AnalyticsService.error("[ERROR uploadFile API]:", e)
      } finally {
        setLoading(false)
      }
    },
  })

  const handleClick = useCallback(() => {
    document.querySelector("#upload-input").click()
  }, [])

  return (
    <Modal title={title} text={description} maxWidth="500px" show={show} setShow={setShow}>
      <DropzoneWrapper
        {...getRootProps({
          onClick: event => event.stopPropagation(),
        })}
      >
        <input id="upload-input" {...getInputProps()} />
        <DropzoneContent onClick={handleClick}>
          {loading ? (
            <>
              <Box>
                <Spinner color="secondary" />
              </Box>
              <Title margin="15px 0 0" type="secondary">
                Trwa dodawanie pliku
              </Title>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faUpload} />
              <Box>
                <Title margin="15px 0 0" type="secondary" textAlign="center">
                  Upuść plik lub kliknij
                </Title>
                <P small>Akceptujemy pliki jpeg, png oraz webp</P>
              </Box>
            </>
          )}
        </DropzoneContent>
        {isDragAccept && (
          <DropzoneBackground>
            <FontAwesomeIcon icon={faUpload} />
            <Title margin="15px 0 0" type="secondary" color="white">
              Upuść pliki, aby go dodać
            </Title>
          </DropzoneBackground>
        )}
      </DropzoneWrapper>
    </Modal>
  )
}

export default ImageModal

const DropzoneContent = styled.div`
  height: 200px;
  background: ${({ theme }) => theme.color.background};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  svg {
    font-size: 20px;
  }
`

const DropzoneWrapper = styled.div``

const DropzoneBackground = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(106, 103, 212, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    font-size: 20px;
  }

  * {
    color: white;
  }
`
