import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Route } from "react-router-dom"
import {
  faArrowCircleDown,
  faGlobeEurope,
  faPen,
  faPlusCircle,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons"
import { Row as FlexRow } from "../../../../theme/styledComponents"
import Button from "../../../../components/atoms/Button"
import Table, { Row } from "../../../../components/Table"
import EmptyBlock from "../../../../components/blocks/EmptyBlock"
import AddOfferModal from "./OfferModal/AddOfferModal"
import { MOTORATA_URL } from "theme/constans"
import ActionModal from "components/ActionModal"
import Tag from "components/atoms/Tag"
import EditOfferModal from "pages/auth/Panel/Offers/OfferModal/EditOfferModal"
import { Title } from "components/atoms/Title"
import { OffersService } from "services"
import { Absolute, Box, Column } from "components/atoms"
import Icon from "components/atoms/Icon"
import { TRANSLATE } from "utils/translator"
import { useIntegration } from "hooks/useIntegration"
import Image from "../../../../components/atoms/Image"
import Switch from "../../../../components/atoms/Switch"
import { AnalyticsService } from "../../../../services/AnalyticsService"
import { translate, translateBrand, translateModel } from "@motorata/base-ui.data"

const OfferRow = ({ item, setItem, setEditItem, setDeactivateItem, setDeleteItem }) => {
  const { active } = useIntegration("otomoto")

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const handleIsAvailable = useCallback(
    async e => {
      const value = e.target.checked
      OffersService.updateIsAvailableOffer(item.id, value)
      AnalyticsService.track("Offer Availability Change", {
        IsAvailable: value,
      })
      setItem({
        ...item,
        isAvailable: value,
      })
    },
    [item, setItem],
  )

  const handleOpenWeb = useCallback(() => {
    AnalyticsService.track("Offer Web Visit")
  }, [])

  if (!active) {
    return (
      <Row
        key={`${item.id}-${item.isAvailable}`}
        data={[
          <Tag key={1} color={item.status}>
            {TRANSLATE[item.status]}
          </Tag>,
          <div key={`name-${item.id}`}>
            <StyledOfferName onMouseEnter={handleClickPopover} onMouseLeave={handleClosePopover}>
              <div className="bold">{`${translateBrand(item.brand)} ${translateModel(
                item.model,
              )}`}</div>
              <div className="small">{translate(item.type)}</div>
              {item.images[0] && (
                <Absolute
                  padding="5px"
                  top="calc(100% + 10px)"
                  background="white"
                  className="popover"
                  zIndex={999}
                  borderRadius="3px"
                >
                  <Box width="200px" borderRadius="3px" overflow="hidden">
                    <Image width="350" src={item.images[0].src} />
                  </Box>
                </Absolute>
              )}
            </StyledOfferName>
          </div>,
          <div className="tag" key={`name-${item.id}`}>
            {translate(item.state)}
          </div>,
          item.year,
          translate(item.bodyType),
          translate(item.fuelType),
          `${item.enginePower} KM`,
          `${item.engineCapacity} cm3`,
          `${item.course ? `${item.course} KM` : "0 KM"}`,
          // `${item.params?.vin !== "" ? item.params?.vin : "-"}`,
          <FlexRow key="switch">
            <Switch color="green" checked={true} onChange={handleIsAvailable} />
          </FlexRow>,
          <FlexRow key={2} jc="flex-end" width="max-content" marginLeft="auto">
            <Icon margin="0 10px" icon={faPen} onClick={() => setEditItem(item)} />
            <a href={`${MOTORATA_URL}/oferta/${item.id}`} target="_blank" rel="noreferrer">
              <Icon onClick={handleOpenWeb} margin="0 10px" icon={faGlobeEurope} />
            </a>
            <Icon
              margin="0 0 0 10px"
              color="red"
              icon={faTrash}
              onClick={() => setDeleteItem(item)}
            />
          </FlexRow>,
        ]}
      />
    )
  }

  return (
    <Row
      key={item.id}
      data={[
        <Tag key={1} color={item.status}>
          {TRANSLATE[item.status]}
        </Tag>,
        <div key={`name-${item.id}`}>
          <StyledOfferName onMouseEnter={handleClickPopover} onMouseLeave={handleClosePopover}>
            <div className="bold">{`${translateBrand(item.brand)} ${translateModel(
              item.model,
            )}`}</div>
            <div className="small">{translate(item.type)}</div>
            {item.images[0] && (
              <Absolute
                padding="5px"
                top="calc(100% + 10px)"
                background="white"
                className="popover"
                zIndex={999}
                borderRadius="3px"
              >
                <Box width="200px" borderRadius="3px" overflow="hidden">
                  <Image width="350" src={item.images[0].src} />
                </Box>
              </Absolute>
            )}
          </StyledOfferName>
        </div>,
        <div className="tag" key={`name-${item.id}`}>
          {translate(item.state)}
        </div>,
        item.year,
        translate(item.bodyType),
        translate(item.fuelType),
        `${item.enginePower} KM`,
        `${item.engineCapacity} cm3`,
        `${item.course ? `${item.course} KM` : "0 KM"}`,
        // `${item.params?.vin !== "" ? item.params?.vin : "-"}`,
        item.integrations?.otomoto?.id ? (
          <Icon margin="0 10px" color="green" icon={faArrowCircleDown} />
        ) : (
          <Icon margin="0 10px" color="red" icon={faTimesCircle} />
        ),
        <FlexRow key="switch">
          <Switch color="green" checked={item.isAvailable} onChange={handleIsAvailable} />
        </FlexRow>,
        <FlexRow key={2} jc="flex-end" width="max-content" marginLeft="auto">
          {!item.integrations?.otomoto?.id ? (
            <>
              <Icon margin="0 10px" icon={faPen} onClick={() => setEditItem(item)} />
              <a href={`${MOTORATA_URL}/oferta/${item.id}`} target="_blank" rel="noreferrer">
                <Icon margin="0 10px" icon={faGlobeEurope} />
              </a>
              <Icon
                margin="0 0 0 10px"
                color="red"
                icon={faTrash}
                onClick={() => setDeleteItem(item)}
              />
            </>
          ) : (
            <>
              <a href={`${MOTORATA_URL}/oferta/${item.id}`} target="_blank" rel="noreferrer">
                <Icon margin="0 10px" icon={faGlobeEurope} />
              </a>
              <Icon
                margin="0 0 0 10px"
                color="red"
                icon={faTrash}
                onClick={() => setDeactivateItem(item)}
              />
            </>
          )}
        </FlexRow>,
      ]}
    />
  )
}

const Offers = () => {
  const { active: otomotoActive } = useIntegration("otomoto")

  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)

  const [editedItem, setEditedItem] = useState()
  const [deleteItem, setDeleteItem] = useState()
  const [deactivateItem, setDeactivateItem] = useState()

  const renderItem = useCallback((offer, setOffer) => {
    return (
      <OfferRow
        key={`${offer.id}-${offer.isAvailable}`}
        item={offer}
        setItem={setOffer}
        setEditItem={setEditedItem}
        setDeactivateItem={setDeactivateItem}
        setDeleteItem={setDeleteItem}
      />
    )
  }, [])

  const fetchData = useCallback(page => {
    return OffersService.getOffers(page)
  })

  const handleRefresh = useCallback(() => {
    setRefresh(!refresh)
  }, [refresh])

  const handleOnDelete = useCallback(
    async page => {
      try {
        setLoading(true)
        await OffersService.deleteOffer(deleteItem.id)
        AnalyticsService.track("Offer Delete")
        setDeleteItem(false)
        handleRefresh()
      } catch (e) {
        AnalyticsService.error("[ERROR deleteOffer API]", e)
      } finally {
        setLoading(false)
      }
    },
    [deleteItem],
  )

  const handleOnDeactivate = useCallback(
    async page => {
      try {
        setLoading(true)
        await OffersService.deactivateOffer(deactivateItem.id)
        setDeactivateItem(false)
        handleRefresh()
      } catch (e) {
        AnalyticsService.error("[ERROR deactivateOffer API]:", e)
      } finally {
        setLoading(false)
      }
    },
    [deactivateItem],
  )

  const renderEmptyComponent = useCallback(() => {
    return (
      <Column padding="60px 30px" jc="center" ai="center">
        <EmptyBlock text="Dodaj swoje pierwsze ogłoszenie" />
        <Button margin="10px auto 0" width="max-content" to="/panel/offers/add" icon={faPlusCircle}>
          Dodaj ogłoszenie
        </Button>
      </Column>
    )
  }, [])

  return (
    <>
      <Wrapper>
        <Title margin="0 0 25px">Wszystkie ogłoszenia {JSON.sotomotoActive}</Title>
        <Table
          getData={fetchData}
          columns={
            !otomotoActive
              ? [
                  "Status",
                  "Marka i model",
                  "Stan",
                  "Rocznik",
                  "Nadwozie",
                  "Paliwo",
                  "Moc",
                  "Pojemność",
                  "Przebieg",
                  // "VIN",
                  "Dostępność",
                  "",
                ]
              : [
                  "Status",
                  "Marka i model",
                  "Stan",
                  "Rocznik",
                  "Nadwozie",
                  "Paliwo",
                  "Moc",
                  "Pojemność",
                  "Przebieg",
                  // "VIN",
                  "Otomoto",
                  "Dostępność",
                  "",
                ]
          }
          refresh={refresh}
          rows={renderItem}
          emptyComponent={renderEmptyComponent}
        />
      </Wrapper>
      <Route path="/panel/offers/add">
        <AddOfferModal refresh={handleRefresh} />
      </Route>
      {editedItem && (
        <EditOfferModal refresh={handleRefresh} item={editedItem} setItem={setEditedItem} />
      )}
      <ActionModal
        icon={faTrash}
        color="red"
        show={deleteItem}
        setShow={setDeleteItem}
        title="Czy na pewno chcesz usunąć ofertę?"
        text="Twoja oferta zostanie usunięta z naszego portalu."
        loading={loading && !!deleteItem}
        onAccept={handleOnDelete}
      />
      <ActionModal
        icon={faTrash}
        color="red"
        show={deactivateItem}
        setShow={setDeactivateItem}
        title="Czy na pewno chcesz dezaktywyować ofertę?"
        text="Twoja oferta pobrana z Otomoto nie będzie już widoczna w naszym serwisie."
        loading={loading && !!deactivateItem}
        onAccept={handleOnDeactivate}
      />
    </>
  )
}

export default Offers

const StyledOfferName = styled.div`
  position: relative;
  cursor: pointer;

  .popover {
    display: none;
    box-shadow: 0px 0px 30px 0px rgba(66, 68, 90, 0.2);

    img {
      display: block;
    }
  }

  &:hover {
    .popover {
      display: block;
    }
  }
`

const Wrapper = styled.div`
  padding: 40px;
`
