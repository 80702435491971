import { APIService } from "./APIService"

const getLeads = (filter, page = 0) =>
  APIService.get(`/profiles/:profileId/leads?filter=${filter}&page=${page}&size=20`)

const getLead = id => APIService.get(`/profiles/:profileId/leads/${id}`)

const addComment = (id, body) => APIService.post(`/profiles/:profileId/leads/${id}/comments`, body)

const updateStatus = (id, status) =>
  APIService.patch(`/profiles/:profileId/leads/${id}/status?status=${status}`)

export const LeadService = {
  getLead,
  getLeads,
  addComment,
  updateStatus,
}
