import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { getQuery } from "utils/queryHelper"
import Spinner from "components/atoms/Spinner"
import { OffersService, ProfileService } from "services"
import { AnalyticsService } from "services/AnalyticsService"
import { updateUser } from "redux/actions"
import { Box, Column, Row } from "theme/styledComponents"
import Icon from "components/atoms/Icon"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Title } from "components/atoms/Title"
import { P } from "components/atoms/P"
import { translateBrand, translateModel } from "theme/data"
import Button from "components/atoms/Button"
import { MOTORATA_URL } from "theme/constans"

const ExtendOffer = () => {
  const query = getQuery()

  const [loading, setLoading] = useState()
  const [data, setData] = useState(null)

  useEffect(() => {
    const extendOffer = async () => {
      try {
        const data = await OffersService.extendOffer(query.get("id"))
        setData(data)
        AnalyticsService.track("Extend Offer")
      } catch (e) {
        AnalyticsService.error("[ERROR OffersService.extendOffer API]:", e)
      } finally {
        setLoading(false)
      }
    }

    extendOffer()
  }, [])

  if (loading || !data) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Box margin="100px auto 0">
        <Column ai="center" jc="center" margin="0 auto 60px" maxWidth="700px">
          <Icon size="70px" margin="0 0 20px" color="green" icon={faCheckCircle} />
          <Title tx="center">Ogłoszenie zostało pomyślnie przedłużone</Title>
          <P ta="center">
            Twoje głoszenie {translateBrand(data?.brand)} {translateModel(data?.model)} {data?.year}{" "}
            zostało pomyślnie przedłużone i będzie dostępne dla użytkowników przez kolejne 30 dni.
          </P>
          <Row margin="30px 0 0">
            <a href={`${MOTORATA_URL}/oferta/${data?.id}`} target="_blank" rel="noreferrer">
              <Button width="max-content">Zobacz ogłoszenie</Button>
            </a>
          </Row>
        </Column>
      </Box>
    </Wrapper>
  )
}

export default ExtendOffer

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
