import { format } from "date-fns"

export const formatDate = el => {
  const date = new Date(el)
  date.setHours(0, 0, 0, 0)
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  if (date.getTime() === today.getTime()) {
    return format(new Date(el), "HH:mm")
  }

  if (
    date.getDay() - 1 === today.getDay() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return `Wczoraj ${format(new Date(el), "HH:mm")}`
  }

  if (date.getFullYear() === today.getFullYear()) {
    return format(new Date(el), "dd/MM HH:mm")
  }

  return format(new Date(el), "dd/MM/yyyy HH:mm")
}
