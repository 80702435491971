import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { faCoins } from "@fortawesome/free-solid-svg-icons"
import { Content, Wrapper } from "../AddOfferModal"
import RowTitleBlock from "components/blocks/RowTitleBlock"
import { Grid, Row } from "theme/styledComponents"
import Input from "components/atoms/Input"
import Switch from "components/atoms/Switch"
import Button from "components/atoms/Button"
import Input2 from "../../../../../../components/atoms/Input2"

const FinanceInformations = ({
  onSubmit,
  number,
  step,
  setStep,
  initState = {},
  onBack,
  disabled,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      price: initState.finance?.price ?? "",
      showPrice: initState.finance?.showPrice ?? "",
    },
  })

  const price = watch("price")
  const showPrice = watch("showPrice")

  const [state, setState] = useState({
    priceType: initState.finance?.type ?? "net",
    invoice: initState.finance?.invoice ?? "vat",
  })

  const onChangeState = useCallback(
    (name, value) => {
      setState({
        ...state,
        [name]: value,
      })
    },
    [state],
  )

  const handleChangePriceType = useCallback(
    e => {
      onChangeState("priceType", e.target.checked ? "gross" : "net")
    },
    [state],
  )

  const handleChangeInvoice = useCallback(
    e => {
      onChangeState("invoice", e.target.checked ? "vat_discount" : "vat")
    },
    [state],
  )

  const handleOnSubmit = useCallback(
    async el => {
      onSubmit(number, {
        ...el,
        ...state,
      })
    },
    [onSubmit, state],
  )

  return (
    <Wrapper>
      <RowTitleBlock
        icon={faCoins}
        disabled={disabled}
        onClick={!disabled ? () => setStep(number) : () => {}}
        title={`Informacje o finansowaniu`}
        text="Podaj dostępne opcje finansowania"
        step={3}
      />
      <Content active={step === 3} onSubmit={handleSubmit(handleOnSubmit)}>
        <Grid gtc="1fr max-content max-content" margin="0 0 20px">
          <Input2
            type="int"
            value={price}
            label="Kwota finansowania"
            endAdornment="zł"
            error={errors.price}
            margin="0"
            rhf={register("price", { required: true })}
          />
          {/*<Input*/}
          {/*  type="double"*/}
          {/*  value={showPrice}*/}
          {/*  label="Kwota pokazowa"*/}
          {/*  endAdornment="zł"*/}
          {/*  error={errors.showPrice}*/}
          {/*  margin="0"*/}
          {/*  rhf={register("showPrice", { required: false })}*/}
          {/*/>*/}
          <Switch
            labels={["Netto", "Brutto"]}
            checked={state?.priceType === "gross"}
            onChange={handleChangePriceType}
            margin="0 auto"
          />
          <Switch
            labels={["VAT", "VAT marża"]}
            checked={state?.invoice === "vat_discount"}
            onChange={handleChangeInvoice}
            margin="0 auto"
          />
        </Grid>
        <Row jc="flex-end">
          <Button type="third" margin="0 40px" width="max-content" onClick={onBack}>
            Wróć
          </Button>
          <Button margin="0" width="max-content" submit>
            Dalej
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default FinanceInformations
