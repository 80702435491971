import { useDispatch } from "react-redux"
import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import { ProfileService } from "../../../../../../services"
import { P } from "../../../../../../components/atoms/P"
import Alert from "../../../../../../components/atoms/Alert"
import Input from "../../../../../../components/atoms/Input"
import Button from "../../../../../../components/atoms/Button"
import { addIntegration } from "redux/reducers/profile"
import { Label } from "../../../../../../components/atoms/Label"

const CustomCMSConfigModal = ({ show, setShow }) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url: "",
    },
  })

  const onSubmit = useCallback(async state => {
    try {
      setLoading(true)
      const res = await ProfileService.addIntegration("customCMS", state)
      dispatch(addIntegration(res))
    } catch (e) {
      setLoading(false)
      setError(true)
    } finally {
      setLoading(false)
      setShow()
    }
  }, [])

  return (
    <>
      <P margin="0 0 20px">
        Skonfiguruj intergację z własnym CMS w celu pobierania zapytań klientów.
      </P>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          margin="0 0 10px"
          label="Adres endpointu"
          name="url"
          error={errors.url}
          rhf={register("url", { required: true })}
        />
        <Button margin="10px 0 0" submit loading={loading}>
          Zapisz
        </Button>
      </form>
    </>
  )
}

export default CustomCMSConfigModal
