import React from "react"
import styled from "styled-components"

const NotificationsCounter = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>
}

export default NotificationsCounter

const Wrapper = styled.div`
  position: absolute;
  width: 13px;
  height: 13px;
  font-size: 10px;
  left: ${({ left = "0" }) => left};
  top: ${({ top = "0" }) => top};
  background: ${({ theme }) => theme.color.red};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border-radius: 50%;
`
