export const theme = {
  color: {
    primary: "#6A67D4",
    secondary: "rgb(18,29,44)",
    third: "#828282",
    fourth: "rgb(235,235,235)",
    background: "rgb(243,243,243)",
    border: "rgb(243,243,243)",
    backgroundGreen: "#E7F8F1",
    white: "#fff",
    primaryBackground: "rgba(106, 103, 212, 0.9)",
    primaryBackgroundOpacity: "rgba(106, 103, 212, 0.3)",
    red: "#F8563F",
    orange: "#df9434",
    green: "#5EAF63",

    deleted: "#F8563F",
    deactivate: "#F8563F",
    pending: "#df9434",
    active: "#5EAF63",

    error: "#F8563F",
    warning: "#df9434",
    success: "#5EAF63",

    deletedBackground: "rgba(248, 86, 63, 0.15)",
    deactivateBackground: "rgba(248, 86, 63, 0.15)",
    warningBackground: "rgba(223, 149, 52, 0.2)",
    pendingBackground: "rgba(223, 149, 52, 0.2)",
    activeBackground: "rgba(94, 175, 99, 0.2)",
  },
  font: {
    XXS: "10px",
    XS: "12px",
    S: "14px",
    M: "16px",
    L: "18px",
    XL: "22px",
    XXL: "26px",

    light: 500,
    normal: 600,
    bold: 700,
  },
}
