import { APIService } from "./APIService"

const register = user => APIService.post(`/profiles/register`, user)

const getCompanyFromGUS = nip => APIService.get(`/gus?nip=${nip}`)

export const PublicService = {
  register,
  getCompanyFromGUS,
}
