import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import usePlacesAutocomplete from "use-places-autocomplete"

function AutocompleteAddressInput({ onChange, margin }) {
  const [open, setOpen] = useState(false)
  const {
    ready,
    value: PLValue,
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {
      country: "PL",
    },
  })

  const handleSearchOnChange = useCallback(
    e => {
      setValue(e.target.value)
    },
    [setValue],
  )

  useEffect(() => {
    setOpen(data.length > 0)
  }, [data])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  const handleOnChange = useCallback((event, newValue) => {
    onChange(newValue)
  }, [])

  return (
    <Autocomplete
      open={open}
      onClose={handleClose}
      getOptionLabel={option => option.description}
      onInputChange={handleSearchOnChange}
      options={data}
      onChange={handleOnChange}
      noOptionsText="Nie znaleziono adresu"
      renderInput={params => (
        <StyledTextField
          {...params}
          margin={margin}
          value={PLValue}
          label="Lokalizacja"
          variant="filled"
        />
      )}
    />
  )
}

AutocompleteAddressInput.propTypes = {}

export default AutocompleteAddressInput

// ${StyledInputBase}

const StyledTextField = styled(TextField)`
  .MuiFilledInput-input {
    padding: 0px !important;
  }

  .MuiFilledInput-root {
    padding: 24px 20px 7px !important;
  }
`
