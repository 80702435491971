import * as Sentry from "@sentry/react"

const identify = (id, properties) => {
  Sentry.configureScope(scope => {
    scope.setUser({
      id: id,
      ...properties,
    })
  })

  window.analytics.identify(id, {
    ...properties,
  })

  window.analytics.group(id, {
    ...properties,
  })
}

const page = name => window.analytics.page(name)

const track = (name, properties) =>
  window.analytics.track(name, {
    ...properties,
  })

const reset = () => window.analytics.reset()

export const error = (name, error) => {
  console.log(name, error)
  error.name = `${name} ${error.message}`
  Sentry.setExtra("data", JSON.stringify(error))
  Sentry.captureException(error)
}

export const AnalyticsService = {
  identify,
  page,
  track,
  reset,
  error,
}
