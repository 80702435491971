import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCog } from "@fortawesome/free-solid-svg-icons"
import { IntegrationBlock } from "../Integrations"
import Modal from "components/atoms/Modal"
import { Column, Row } from "theme/styledComponents"
import LOGO_OTOMOTO from "assets/images/otomoto.svg"
import { Title } from "components/atoms/Title"
import { Box } from "components/atoms"
import Avatar from "components/atoms/Avatar"
import { Label } from "components/atoms/Label"
import Button from "components/atoms/Button"
import OtomotoLoginModal from "./OtomotoLoginModal"
import OtomotoSuccessModal from "./OtomotoSuccessModal"
import OtomotoEditModal from "./OtomotoEditModal"
import OtomotoDeleteOffersModal from "./OtomotoDeleteOffersModal"
import styled from "styled-components";

export const OtomotoBlock = ({ onChange, setCurrent, ...rest }) => {
  const state = useSelector(store => store.profile.integrations.otomoto)

  const handleSwitch = useCallback(e => {
    onChange(e, "otomoto")
  }, [])

  const handleEdit = useCallback(() => {
    if (state?.params.step === "init-finance") {
      setCurrent("otomoto-init-finance")
    } else if (state?.params.step === "done") {
      setCurrent("otomoto-success")
    } else {
      setCurrent("otomoto-edit")
    }
  }, [state?.params?.step])

  return (
    <IntegrationBlock
      logo={LOGO_OTOMOTO}
      title="OTOMOTO"
      text="Pobieraj, dodawaj oraz aktualizuj swoje ogłsozenia."
      isWarning={state?.params?.isWarning}
      active={state?.active}
      onSwitch={handleSwitch}
      {...rest}
    >
      {state?.active && (
        <StyledBox background padding="10px 15px" marginTop="20px" borderRadius="3px">
          <Row jc="space-between">
            <Row>
              <Avatar background="white" margin="0 10px 0 0" />
              <Column ai="flex-start">
                <Label>Konto</Label>
                <Title type="third" margin="0">
                  {state.params.username}
                </Title>
              </Column>
            </Row>
            {/*<Button margin="0" type="third" width="max-content" onClick={handleEdit}>*/}
            {/*  <FontAwesomeIcon icon={faCog} />*/}
            {/*  {state?.params.step === "done" ? "Edytuj" : "Dokończ konfigurację"}*/}
            {/*</Button>*/}
          </Row>
        </StyledBox>
      )}
    </IntegrationBlock>
  )
}

export const OtomotoModals = ({ state, setState }) => {
  return (
    <>
      {state === "otomoto" && (
        <Modal maxWidth="400px" title="Zaloguj się do otomoto" show={state} setShow={setState}>
          <OtomotoLoginModal />
        </Modal>
      )}
      {state === "otomoto-success" && (
        <Modal
          maxWidth="400px"
          show={state}
          setShow={setState}
          activeClose={false}
        >
          <OtomotoSuccessModal />
        </Modal>
      )}
      {state === "otomoto-edit" && (
        <Modal
          maxWidth="800px"
          title="Edytuj integrację otomoto"
          text="Zmień szczegoły integracji z platformą otomoto"
          type="side"
          show={state}
          setShow={setState}
        >
          <OtomotoEditModal />
        </Modal>
      )}
      {state === "otomoto-delete-offers" && (
        <OtomotoDeleteOffersModal show={state} setShow={setState} />
      )}
    </>
  )
}


const StyledBox = styled(Box)`
  border: 1px solid ${({ theme }) => theme.color.third};
`
