import React from 'react';
import styled from 'styled-components';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CloseButton = ({ onClick, right = "10px", top = "10px" }) => {

  return (
    <Wrapper onClick={onClick} right={right} top={top}>
      <FontAwesomeIcon icon={faTimes} />
    </Wrapper>
  );
};

export default CloseButton;

const Wrapper = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: rgba(255,255,255,0.5);
  border-radius: 50%;
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  cursor: pointer;
  
  svg{
    height: 15px;
    color: ${({ theme }) => theme.color.secondary};
  }
  `;